var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            title: "推文预览",
            width: "430px",
            top: "5vh",
            "close-on-click-modal": false,
            "before-close": _vm.closeAction,
          },
        },
        [
          _c("div", { staticStyle: { height: "75vh", "overflow-y": "auto" } }, [
            _c(
              "section",
              { ref: "is_copy_el", attrs: { id: "is_copy" } },
              _vm._l(_vm.pages, function (item, index) {
                return _c(
                  "section",
                  { key: index + 1 },
                  [
                    item.name === "title"
                      ? _c("Title", {
                          attrs: {
                            facade: item.facade,
                            content: item.content,
                            index: index,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.name === "rubik_weChat"
                      ? _c("Rubik", {
                          attrs: {
                            facade: item.facade,
                            content: item.content,
                            index: index,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.name === "blank"
                      ? _c("Blank", {
                          attrs: {
                            facade: item.facade,
                            content: item.content,
                            index: index,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.name === "textView"
                      ? _c("textView", {
                          attrs: {
                            facade: item.facade,
                            content: item.content,
                            index: index,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.name === "auction_weChat"
                      ? _c("AuctionWeChat", {
                          attrs: {
                            facade: item.facade,
                            content: item.content,
                            index: index,
                            "mini-appid": _vm.miniAppid,
                            "mini-name": _vm.miniName,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.name === "auction_album_weChat"
                      ? _c("AuctionAlbumWeChat", {
                          attrs: {
                            facade: item.facade,
                            content: item.content,
                            index: index,
                            "mini-appid": _vm.miniAppid,
                            "mini-name": _vm.miniName,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btns mt10 item-flex-center" },
            [
              _c(
                "el-button",
                { staticClass: "btn", on: { click: _vm.copyAction } },
                [_vm._v("复制代码到公众号粘贴")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }