var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "拍品组件", is_tab: false } },
    [
      _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "banner-control",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "页面边距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.page_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "page_margin", $$v)
                        },
                        expression: "facade.page_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "上圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.top_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "top_radius", $$v)
                        },
                        expression: "facade.top_radius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "下圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.bottom_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bottom_radius", $$v)
                        },
                        expression: "facade.bottom_radius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_color", $$v)
                        },
                        expression: "facade.title_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.sub_title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "sub_title_color", $$v)
                        },
                        expression: "facade.sub_title_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "价格颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.price_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "price_color", $$v)
                        },
                        expression: "facade.price_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "出价数颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.bid_num_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bid_num_color", $$v)
                        },
                        expression: "facade.bid_num_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "item-align-center",
                        staticStyle: { height: "30px" },
                        model: {
                          value: _vm.facade.status_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "status_type", $$v)
                          },
                          expression: "facade.status_type",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "item-align-center",
                            attrs: { label: 1 },
                          },
                          [
                            _c("div", { staticClass: "status-type1" }, [
                              _vm._v("预展中"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            staticClass: "item-align-center",
                            attrs: { label: 2 },
                          },
                          [
                            _c("div", { staticClass: "status-type2" }, [
                              _vm._v("预展中"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            staticClass: "item-align-center",
                            attrs: { label: 3 },
                          },
                          [
                            _c("div", { staticClass: "status-type3" }, [
                              _vm._v("预展中"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.btn_bg_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "btn_bg_color", $$v)
                        },
                        expression: "facade.btn_bg_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.btn_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "btn_color", $$v)
                        },
                        expression: "facade.btn_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "按钮圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.btn_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "btn_radius", $$v)
                        },
                        expression: "facade.btn_radius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("图片展示方式"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center flex-wrap" },
                  _vm._l(_vm.imgStyleData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "change-type",
                        staticStyle: {
                          "margin-right": "20px",
                          "margin-top": "15px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            class: {
                              curr: item.value === _vm.facade.img_style,
                            },
                          },
                          [
                            _c("label", [
                              _c("img", { attrs: { src: item.src } }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.facade.img_style,
                                    expression: "facade.img_style",
                                  },
                                ],
                                staticClass: "choose-input",
                                attrs: { type: "radio", name: "choose" },
                                domProps: {
                                  value: item.value,
                                  checked: _vm._q(
                                    _vm.facade.img_style,
                                    item.value
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.facade,
                                      "img_style",
                                      item.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "name color-gray align-c ft12" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }