var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "视频", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.facade,
            "label-width": "80px",
            size: "mini",
            "label-position": "left",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.content.source == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "视频地址" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "复制视频地址到这里" },
                    model: {
                      value: _vm.content.video.url_online,
                      callback: function ($$v) {
                        _vm.$set(_vm.content.video, "url_online", $$v)
                      },
                      expression: "content.video.url_online",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "tips" }, [
                    _vm._v("支持腾讯视频平台的网络视频"),
                  ]),
                ],
                1
              )
            : _c("el-form-item", { attrs: { label: "视频上传" } }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      staticStyle: {
                        width: "280px",
                        height: "168px",
                        position: "relative",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.uploadIconAction(2)
                        },
                      },
                    },
                    [
                      _vm.content.video.url
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg(2)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.video.url
                        ? _c("video", {
                            staticStyle: {
                              width: "280px",
                              height: "168px",
                              margin: "0",
                            },
                            attrs: { src: _vm.content.video.url },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.statusText
                    ? _c(
                        "div",
                        { staticClass: "tips" },
                        [
                          _c("Progress", {
                            attrs: {
                              percent: _vm.authProgress,
                              status: "active",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "tips" }, [
                  _vm._v(
                    "视频大小不超过 100 MB，建议时长在5分钟以内，宽高比 16:9"
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "视频封面" } }, [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  staticStyle: { width: "280px", height: "168px" },
                  on: {
                    click: function ($event) {
                      return _vm.uploadIconAction(1)
                    },
                  },
                },
                [
                  _vm.content.cover
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg(1)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.cover
                    ? _c("img", {
                        staticStyle: {
                          width: "280px",
                          height: "168px",
                          margin: "0",
                        },
                        attrs: { src: _vm.content.cover + "!16b9" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "tips" }, [
              _vm._v("大小不超过3M，建议宽高比 16:9"),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "视频尺寸:" } }, [
            _c(
              "div",
              { staticClass: "item-align-center" },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "80px" },
                    attrs: { type: "text", placeholder: "宽" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                      },
                    },
                    model: {
                      value: _vm.content.view_width,
                      callback: function ($$v) {
                        _vm.$set(_vm.content, "view_width", $$v)
                      },
                      expression: "content.view_width",
                    },
                  },
                  [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                  2
                ),
                _vm._v(" "),
                _c("img", {
                  staticStyle: { margin: "0 6px" },
                  attrs: {
                    src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "80px" },
                    attrs: { type: "text", placeholder: "高" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                      },
                    },
                    model: {
                      value: _vm.content.view_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.content, "view_height", $$v)
                      },
                      expression: "content.view_height",
                    },
                  },
                  [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "tips" }, [
              _vm._v("宽度最大设置750，如小于750 页面自动居中展示"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "自动播放" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.content.autoplay,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "autoplay", $$v)
                  },
                  expression: "content.autoplay",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "循环播放" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.content.loop,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "loop", $$v)
                  },
                  expression: "content.loop",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }