"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
var _default = {
  props: {
    src: {
      type: [String, Array, Object]
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    error: function error(event) {}
  }
};
exports.default = _default;