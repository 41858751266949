var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index === 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c("div", { staticClass: "avabox" }, [
                  _c("div", { staticClass: "avar" }, [
                    _c("img", { attrs: { src: item.avatorimg, alt: "" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "avac" }, [
                    _c("div", { staticClass: "avact" }, [
                      _c("div", { staticClass: "avaname" }, [
                        _vm._v(_vm._s(item.avator)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avacb" }, [_vm._v("2021-10-24")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text color-black" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  item.image
                    ? _c("img", { attrs: { src: item.image, alt: "" } })
                    : _vm._e(),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2 && _vm.content.img_style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          [
            _c(
              "waterfall",
              {
                attrs: {
                  col: 2,
                  gutterWidth: _vm.facade.item_padding / 2,
                  lazyDistance: 200,
                  data: _vm.list,
                },
              },
              [
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: { mt0: index < 2 },
                      style: {
                        marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                        borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                        borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                        width:
                          (750 -
                            _vm.facade.page_padding -
                            _vm.facade.page_padding -
                            _vm.facade.item_padding) /
                            4 +
                          "px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "two-list",
                          style: {
                            width:
                              (750 -
                                _vm.facade.page_padding -
                                _vm.facade.page_padding -
                                _vm.facade.item_padding) /
                                4 +
                              "px",
                            background: _vm.facade.status_background,
                            borderTopRightRadius:
                              _vm.facade.img_radius / 2 + "px",
                            borderTopLeftRadius:
                              _vm.facade.img_radius / 2 + "px",
                            borderBottomRightRadius:
                              _vm.facade.status_radius / 2 + "px",
                            borderBottomLeftRadius:
                              _vm.facade.status_radius / 2 + "px",
                            marginLeft:
                              index % 2 == 0
                                ? ""
                                : _vm.facade.item_padding / 4 + "px",
                          },
                        },
                        [
                          _c("div", {}, [
                            _c("img", { attrs: { src: item.image, alt: "" } }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.name) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "avabox" }, [
                            _c("div", { staticClass: "avar" }, [
                              _c("img", {
                                attrs: { src: item.avatorimg, alt: "" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "avac" }, [
                              _c("div", { staticClass: "avact" }, [
                                _c("div", { staticClass: "avaname" }, [
                                  _vm._v(_vm._s(item.avator)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }