"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    stype: {
      type: [Number, String],
      default: 1 // 1 商品 2 拍品 3 网课
    },

    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      showChild: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0
    };
  },
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.loadProduct();
      this.showSelectAuction = true;
    },
    searchGoods: function searchGoods() {
      this.queryParams.name = this.searchName;
      this.queryParams.page = 1;
      this.loadProduct();
    },
    loadProduct: function loadProduct(fun) {
      var _this = this;
      var params = {
        page: 1,
        size: 1000,
        type: 1
      };
      var msg = this.$message.loading({
        render: function render(h) {
          return h('span', ['加载中...']);
        },
        duration: 0
      });
      console.log(this.type);
      var url = '/GoodsCategory_list';
      if (this.stype == 1) {
        url = '/GoodsCategory_list';
      } else if (this.stype == 2) {
        url = '/pm_cata_list';
      } else if (this.stype == 3) {
        url = '/CourseCategory_list';
      }
      this.$http.get(url, {
        params: params
      }).then(function (res) {
        msg();
        if (res.code === 200) {
          _this.resultData = res.data;
          _this.$nextTick(function () {
            // 赋值后马上更新
            if (fun) fun();
          });
        }
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    showChildAction: function showChildAction(id) {
      if (this.showChild.indexOf(id) !== -1) {
        var index = this.showChild.indexOf(id);
        this.showChild.splice(index, 1);
      } else {
        this.showChild = this.showChild.concat([id]);
      }
    },
    auctionSelectedAction: function auctionSelectedAction(e, item, level) {
      var m = item;
      var data = {
        image: item.imgurl ? item.imgurl + '!120a' : '',
        stype: this.stype,
        name: m.category_title,
        id: m.id,
        tags: [],
        price: '',
        level: level
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'category',
        data: this.selectedAuction
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadProduct();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadProduct();
    }
  }
};
exports.default = _default2;