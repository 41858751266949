var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "colour" },
    [
      _c("el-color-picker", {
        attrs: { predefine: _vm.predefineColors, "show-alpha": !_vm.noAlpha },
        model: {
          value: _vm.select,
          callback: function ($$v) {
            _vm.select = $$v
          },
          expression: "select",
        },
      }),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "colour-input",
        attrs: { type: "text", readonly: "" },
        model: {
          value: _vm.select,
          callback: function ($$v) {
            _vm.select = $$v
          },
          expression: "select",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "colour-clear",
          attrs: { type: "text" },
          on: { click: _vm.onClear },
        },
        [_vm._v("透明")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "colour-reset",
          attrs: { type: "text" },
          on: { click: _vm.onReset },
        },
        [_vm._v("重置")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }