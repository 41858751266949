"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
var _selectCategory = _interopRequireDefault(require("@/components/customChildren/selectCategory.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 注册draggable组件
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    choose: _choose.default,
    selectCategory: _selectCategory.default
  },
  props: {
    value: Object
  },
  data: function data() {
    return {
      styleData: [{
        value: 1,
        src: 'https://artimg2.artart.cn/image/20211022/32abc372d1cbb9361157ad5b8c20d2bd.png',
        active: true
      }, {
        value: 2,
        src: 'https://artimg2.artart.cn/image/20211022/1c1ee691c9a2bd906b4f01b9c0d81da0.png',
        active: true
      }]
    };
  },
  /**
     * 计算属性
     * @type {Object}
     */
  computed: {
    pageInfo: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  created: function created() {
    console.log(this.pageInfo);
  },
  methods: {
    /**
       * 删除元素
       * @return {[type]} [description]
       */
    handleDelete: function handleDelete(index) {
      this.pageInfo.footerSetting.content.splice(index, 1);
    },
    /**
       * 添加元素
       * @return {[type]} [description]
       */
    handleAdditem: function handleAdditem() {
      if (this.pageInfo.footerSetting.content.length < 6) {
        this.pageInfo.footerSetting.content.push({
          url: '',
          title: '导航文字',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        });
      } else {
        this.$message({
          message: '最多添加6个底部导航',
          type: 'warning'
        });
      }
    },
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.pageInfo.footerSetting.content.splice(index - 1, 0, this.pageInfo.footerSetting.content[index]);
      this.pageInfo.footerSetting.content.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.pageInfo.footerSetting.content - 1) {
        return;
      }
      this.pageInfo.footerSetting.content.splice(index + 2, 0, this.pageInfo.footerSetting.content[index]);
      this.pageInfo.footerSetting.content.splice(index, 1);
    },
    uploadIconAction: function uploadIconAction(index, key) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pageInfo.footerSetting.content[index][key] = img[0];
      }, 1);
    },
    delImg: function delImg(index, key) {
      this.pageInfo.footerSetting.content[index][key] = '';
    },
    setCategory: function setCategory(index) {
      this.setCategoryIndex = index;
      this.$refs.setCategoryView.changeshow(this.pageInfo.footerSetting.content[index].link);
    },
    selectMessage: function selectMessage(data) {
      this.pageInfo.footerSetting.content[this.setCategoryIndex].link = data.data;
    }
  }
};
exports.default = _default;