var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notice item-align-center",
      style: {
        backgroundColor: _vm.facade.background_color,
        paddingLeft: _vm.facade.page_padding / 2 + "px",
        margin: "0 " + (_vm.facade.page_margin || 0) / 2 + "px",
        boxShadow: _vm.facade.item_shadow
          ? "0 0 " +
            (_vm.facade.item_shadow_size || 0) / 2 +
            "px " +
            _vm.facade.item_shadow
          : "",
        borderRadius: (_vm.facade.radius || 0) / 2 + "px",
      },
    },
    [
      _c("div", { staticClass: "notice-icon" }, [
        _c("img", { attrs: { src: _vm.content.icon } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "notice-title",
          class: { "item-align-center": _vm.facade.roll_type == 1 },
          style: {
            color: _vm.facade.title_color,
          },
        },
        _vm._l(_vm.content.data, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: { line1: _vm.facade.roll_type != 1 },
              style: { paddingLeft: _vm.facade.item_padding / 2 + "px" },
            },
            [_vm._v(_vm._s(item.title))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.content.right_text
        ? _c(
            "div",
            {
              staticClass: "notice-right",
              style:
                "padding-right: " +
                (_vm.facade.right_padding || 0) / 2 +
                "px;color: " +
                _vm.facade.right_title_color +
                ";",
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.content.right_text))]),
              _vm.content.right_icon
                ? _c("img", { attrs: { src: _vm.content.right_icon, alt: "" } })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }