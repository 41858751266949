"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import empty from './empty.vue'
var _default = {
  props: {
    type: {
      type: [String, Number],
      default: ''
    },
    status: {
      type: [String, Number],
      default: '1'
    },
    sty: {
      type: String,
      default: ''
    },
    tagData: {
      type: [String, Object],
      default: ''
    },
    big: {
      type: String,
      default: ''
    }
  },
  computed: {
    styleData: function styleData() {
      if (this.tagData) {
        var d = {
          topLeftRadius: this.tagData.topLeftRadius,
          topRightRadius: this.tagData.topRightRadius,
          bottomLeftRadius: this.tagData.bottomLeftRadius,
          bottomRightRadius: this.tagData.bottomRightRadius
        };
        switch (this.type) {
          case 0:
            d.btnBg = this.tagData.previewBtnBg;
            d.btnColor = this.tagData.previewBtnColor;
            d.btnBorder = this.tagData.previewBorder;
            d.btnBorderColor = this.tagData.previewBorderColor;
            d.activeColor = this.tagData.previewActiveColor;
            break;
          case 1:
            d.btnBg = this.tagData.finishBtnBg;
            d.btnColor = this.tagData.finishBtnColor;
            d.btnBorder = this.tagData.finishBorder;
            d.btnBorderColor = this.tagData.finishBorderColor;
            d.activeColor = this.tagData.finishActiveColor;
            break;
          case 2:
            d.btnBg = this.tagData.discussBtnBg;
            d.btnColor = this.tagData.discussBtnColor;
            d.btnBorder = this.tagData.discussBorder;
            d.btnBorderColor = this.tagData.discussBorderColor;
            d.activeColor = this.tagData.discussActiveColor;
            break;
          default:
            d.btnBg = this.tagData.goingBtnBg;
            d.btnColor = this.tagData.goingBtnColor;
            d.btnBorder = this.tagData.goingBorder;
            d.btnBorderColor = this.tagData.goingBorderColor;
            d.activeColor = this.tagData.goingActiveColor;
            break;
        }
        var sty = {};
        switch (this.status) {
          case 1:
            sty = {
              background: "linear-gradient(135deg, ".concat(d.btnBg, "aa, ").concat(d.btnBg, ")"),
              color: d.btnColor
            };
            break;
          case 2:
            sty = {
              border: "1.5px solid ".concat(d.btnBorder),
              color: d.btnBorderColor
            };
            break;
          case 3:
            sty = {
              color: d.activeColor,
              textAlign: 'left',
              fontSize: '12px'
            };
            break;
        }
        sty.borderRadius = "".concat(d.topLeftRadius / 2, "px ").concat(d.topRightRadius / 2, "px ").concat(d.bottomRightRadius / 2, "px ").concat(d.bottomLeftRadius / 2, "px");
        return sty;
      } else {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  }
};
exports.default = _default;