"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  mer_id: function mer_id(state) {
    return state.user.mer_id;
  },
  merchant_admin_id: function merchant_admin_id(state) {
    return state.user.merchant_admin_id;
  },
  domain_url: function domain_url(state) {
    return state.user.domain_url;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  menuList: function menuList(state) {
    return state.user.menuList;
  },
  mer_name: function mer_name(state) {
    return state.user.mer_name;
  },
  mer_logo: function mer_logo(state) {
    return state.user.mer_logo;
  },
  attribute: function attribute(state) {
    var index = state.finish.index;
    if (state.finish.pages[index]) {
      return state.finish.pages[index].name;
    } else {
      return '';
    }
  },
  content: function content(state) {
    var index = state.finish.index;
    if (state.finish.pages[index]) {
      return state.finish.pages[index].content;
    }
  },
  facade: function facade(state) {
    var index = state.finish.index;
    if (state.finish.pages[index]) {
      return state.finish.pages[index].facade;
    }
  }
};
var _default = getters;
exports.default = _default;