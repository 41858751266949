"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.function.name");
var _modules = _interopRequireDefault(require("./modules"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AuctionAlbum: _modules.default.auctionAlbum.widget,
    Auction: _modules.default.auction.widget,
    AlbumDetail: _modules.default.albumDetail.widget,
    AuctionDetail: _modules.default.auctionDetail.widget,
    Product: _modules.default.product.widget,
    ProductDetail: _modules.default.productDetail.widget,
    NftCollection: _modules.default.nftCollection.widget,
    NftDetail: _modules.default.nftDetail.widget,
    Compose: _modules.default.compose.widget,
    Blind: _modules.default.blind.widget,
    BlindDetail: _modules.default.blindDetail.widget
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || '',
      type: this.$route.query.type || 'album_component'
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragIndex', value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragPosition', value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit('finish/setPagesData', value);
      }
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  watch: {
    $route: function $route(val) {
      if (val.name === 'basicsDiy' && val.query.type) {
        this.type = val.query.type;
        this.pages = [];
        this.$store.commit('finish/setPageInfo', {
          show: false
        });
        this.loadPageInfo();
      }
    }
  },
  mounted: function mounted() {
    this.loadPageInfo();
  },
  methods: {
    pageInfoNew: function pageInfoNew(index) {
      return Object.assign({}, this.pageInfo, this.pageInfo.type_setting[this.pages[index].content.style]);
    },
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this = this;
      (0, _system.diypageDetail)({
        type: this.type
      }).then(function (res) {
        // if (this.type == "blind_page") {
        //   this.pages = [];
        //   this.$store.commit("finish/setPageInfo", {
        //     show: true,
        //     title: "盲盒首页",
        //     background: "#1e1e1e",
        //     bg_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653119826546204424.png",
        //     bg_image_mode: 5,

        //     audio_play_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/165312470761077166.png",
        //     audio_pause_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653124701489100967.png",
        //     contact_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022517/image/1652786408545638390.png",
        //     coupon_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022517/image/1652786408303444165.png",

        //     free_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131184902269421.png",
        //     open_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131183805954611.png",
        //     my_box_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131184584370017.png",

        //     point_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653111771731254210.png",
        //     refause_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653109349705506624.png",
        //     tricks_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022525/image/1653468724774995218.png",
        //     price_bg_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022525/image/1653468725307137699.png",
        //     left_image:
        //       "https://artimg2.artart.cn/merchant/23/202265/image/1654414132124368310.png",
        //     right_image:
        //       "https://artimg2.artart.cn/merchant/23/202265/image/1654414131488666436.png",
        //     item_width: 190,
        //     item_color: "#E300FF",
        //   });
        // }else
        if (_this.type == 'blind_detail_page') {
          _this.pages = [{
            name: 'blindDetail',
            title: '盲盒图片',
            content: {
              type: 'playimage'
            },
            facade: {
              marginTop: 0
            }
          },
          // {
          //   name: "blindDetail",
          //   title: "价格",
          //   content: {
          //     type: 'bindPrice',
          //   },
          //   facade: {
          //     marginTop: 0
          //   }
          // },
          // {
          //   name: "blindDetail",
          //   title: "减免价格",
          //   content: {
          //     type: 'reducePrice',
          //   },
          //   facade: {
          //     marginTop: 0
          //   }
          // },
          //  {
          //   name: "blindDetail",
          //   title: "概率",
          //   content: {
          //     type: 'chanceBox',
          //   },
          //   facade: {
          //     marginTop: 0
          //   }
          // },
          //  {
          //   name: "blindDetail",
          //   title: "开箱提示标题",
          //   content: {
          //     type: 'openBoxTitle',
          //   },
          //   facade: {
          //     marginTop: 0,
          //     color: '#ffffff'
          //   }
          // },
          {
            name: 'blindDetail',
            title: '商品列表',
            content: {
              type: 'productmeta'
            },
            facade: {
              marginTop: 32,
              metaColor: '#FFFFFF',
              haveColor: '#999999',
              needColor: '#00EEFF',
              priceColor: '#ff6600',
              borderRadius: 16,
              resultRadius: 40
            }
          }
          // {
          //   name: "blindDetail",
          //   title: "弹幕",
          //   content: {
          //     type: 'playTip',
          //   },
          //   facade: {
          //     color: '#ffffff',
          //     background: '#000000',
          //     borderRadius: 16,
          //   }
          // }
          ];

          _this.$store.commit('finish/setPageInfo', {
            show: true,
            title: '盲盒详情页',
            background: '#5a3d9f',
            bg_image: 'https://artimg2.artart.cn/merchant/23/202265/image/1654417831120284583.png',
            bg_image_mode: 4,
            share_image: 'https://artimg2.artart.cn/merchant/23/2022616/image/1655358292502449408.png',
            open_image: 'https://artimg2.artart.cn/merchant/333425/2022521/image/1653131183805954611.png',
            mode_image: 'https://artimg2.artart.cn/merchant/23/202266/image/1654497205464530092.png',
            bottom_background: '#000000',
            txtColor: '#ffffff'
          });
        }
        if (_this.type === 'nft_component' && res.data.config_data.type_setting && !res.data.config_data.type_setting[3]) {
          res.data.config_data.type_setting[3] = {
            status_btn_bg_color: '#000000',
            status_btn_color: '#00eeff',
            status_font_size: 24,
            sub_font_size: 24,
            sub_color: '#999999'
          };
        }
        if (_this.type === 'nft_component' && res.data.content && !res.data.content[2]) {
          res.data.content.push({
            name: 'nftCollection',
            icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_album_icon.png',
            title: 'NFT藏品',
            hasContent: true,
            content: {
              style: 3,
              data: []
            }
          });
        }
        if (_this.type === 'product_detail_page') {
          var noCoupon = true;
          var _iterator = (0, _createForOfIteratorHelper2.default)(res.data.content),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var m = _step.value;
              if (m.content.type && m.content.type === 'coupon') {
                noCoupon = false;
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          if (noCoupon) {
            res.data.content.splice(4, 0, {
              content: {
                type: 'coupon'
              },
              facade: {
                marginTop: 34,
                background: '#ffffff',
                color: '#FF692B',
                fontSize: 24,
                fontWeight: 500,
                couponImg: 'https://artimg2.artart.cn/image/20220806/00689905d25efc63e7b01cb00e27bd55.png',
                receiveColor: '#FF692B',
                receiveImg: 'https://artimg2.artart.cn/image/20220806/1e4ce81258a802799bbf969a3cbb2848.png'
              },
              name: 'productDetail',
              title: '优惠券'
            });
          }
        } else if (_this.type === 'nft_detail_page') {
          var _noCoupon = true;
          var _iterator2 = (0, _createForOfIteratorHelper2.default)(res.data.content),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _m = _step2.value;
              if (_m.content.type && _m.content.type === 'coupon') {
                _noCoupon = false;
                break;
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
          if (_noCoupon) {
            res.data.content.splice(4, 0, {
              content: {
                type: 'coupon'
              },
              facade: {
                marginTop: 32,
                color: '#00EEFF',
                fontSize: 24,
                fontWeight: 500,
                couponImg: 'https://artimg2.artart.cn/image/20220808/2dd582d3967e207a2c5cfb728e23ffa2.png',
                receiveColor: '#00EEFF',
                receiveImg: 'https://artimg2.artart.cn/image/20220808/4bc1d2060dafc6acf359115fd0f72f90.png'
              },
              name: 'nftDetail',
              title: '优惠券'
            });
          }
        }
        if (res.data.content) {
          _this.pages = res.data.content;
        }
        if (res.data.config_data) {
          _this.$store.commit('finish/setPageInfo', res.data.config_data);
        }
      }).catch(function (message) {
        _this.$message.error(message);
      });

      // this.pages = [];
      //       this.$store.commit("finish/setPageInfo", {
      //         show: true,
      //         title: "盲盒首页",
      //         background: "#1e1e1e",
      //         bg_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653119826546204424.png",
      //         bg_image_mode: 5,

      //         audio_play_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/165312470761077166.png",
      //         audio_pause_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653124701489100967.png",
      //         contact_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022517/image/1652786408545638390.png",
      //         coupon_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022517/image/1652786408303444165.png",

      //         free_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131184902269421.png",
      //         open_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131183805954611.png",
      //         my_box_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131184584370017.png",

      //         point_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653111771731254210.png",
      //         refause_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022521/image/1653109349705506624.png",
      //         tricks_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022525/image/1653468724774995218.png",
      //         price_bg_image:
      //           "https://artimg2.artart.cn/merchant/333425/2022525/image/1653468725307137699.png",
      //         left_image:
      //           "https://artimg2.artart.cn/merchant/23/202265/image/1654414132124368310.png",
      //         right_image:
      //           "https://artimg2.artart.cn/merchant/23/202265/image/1654414131488666436.png",
      //         item_width: 190,
      //         item_color: "#E300FF",
      //       });

      //   this.pages = [
      //     {
      //       name: "nftDetail",
      //       title: "轮播图",
      //       content: {
      //         type: 'playimage',
      //       },
      //       facade: {
      //         marginTop: 0
      //       }
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "玩法标签",
      //       content: {
      //         type: 'playtag'
      //       },
      //       facade: {
      //         marginTop: 34,
      //         marginRight: 16,
      //         borderColor: '#444444',
      //         borderRadius: 16,
      //         color: '#999999',
      //         bgcolor: '#333333',
      //         selectColor: '#000000',
      //         selectBgcolor: '#00EEFF',
      //         fontSize: 26,
      //       }
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "玩法说明",
      //       content: { type: "playinfo" },
      //       facade: {
      //         marginTop: 32,
      //         fontSize: 28,
      //         color: "#999999",
      //         lineHeight: 55,
      //         fontWeight: 400,
      //         title: "玩法说明",
      //         title_image: "",
      //       },
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "合成材料",
      //       content: { type: "composemeta" },
      //       facade: {
      //         marginTop: 32,
      //         metaColor: "#FFFFFF",
      //         haveColor: "#999999",
      //         needColor: "#00EEFF",
      //         borderRadius: 16,
      //         addImage: 'https://artimg2.artart.cn/merchant/23/2022611/image/1654906604572789446.png',
      //         resultImage: 'https://artimg2.artart.cn/merchant/23/2022611/image/165490660978131255.png',
      //         resultRadius: 40
      //       }
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "合成须知",
      //       content: { type: "notice" },
      //       facade: {
      //         marginTop: 32,
      //         fontSize: 28,
      //         color: "#999999",
      //         lineHeight: 55,
      //         fontWeight: 400,
      //         title: "合成须知",
      //         title_image: "",
      //       },
      //     },
      //   ];
      //   this.$store.commit("finish/setPageInfo", {
      //     show: true,
      //     title: "合成活动详情",
      //     background: "#1e1e1e",
      //     bg_image: "",
      //     bg_image_mode: 4,
      //     page_margin: 30,
      //     titleColor: "#000000",
      //     titleFontSize: 32,
      //     marginLeft: 22,
      //     borderRadius: 18,
      //     item_background: "#FFFFFF",
      //     border: "#444444",
      //     item_padding: 29,
      //     bottom_background: "#000000",
      //     priceColor: "#E6CB95",
      //     btnBgColor: "",
      //     btnColor: "",
      //     txtColor: "#00CED1",
      //   });

      // this.pages = [
      //   {
      //     name: 'nftCollection',
      //     icon:
      //       'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_album_icon.png',
      //     title: 'NFT藏品',
      //     hasContent: true,
      //     content: {
      //       style: 1,
      //       data: []
      //     }
      //   },
      //   {
      //     name: 'nftCollection',
      //     icon:
      //       'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_album_icon.png',
      //     title: 'NFT藏品',
      //     hasContent: true,
      //     content: {
      //       style: 2,
      //       data: []
      //     }
      //   }
      // ];
      // this.$store.commit('finish/setPageInfo', {
      //   title: 'NFT藏品组件',
      //   show: true,
      //   background_color: '#1E1E1E',
      //   page_margin: 30,
      //   img_radius: 8,
      //   status_radius: 8,
      //   item_margin: 30,
      //   status_background: '#333333',
      //   status_bg_image: '',
      //   font_weight: 400,
      //   title_color: '#ffffff',
      //   title_font_size: 32,
      //   title_font_weight: 500,
      //   stock_color: '#00eeff',
      //   stock_font_size: 24,
      //   price_font_size: 48,
      //   price_color: '#e6cb95',
      //   img_style: 2,

      //   type_setting: {
      //     1: {
      //       status_btn_bg_color: '#000000',
      //       status_btn_color: '#00eeff',
      //       status_font_size: 24,
      //       btn_font_size: 22,
      //       btn_radius: 2,
      //       main_btn_bg_color: '#00eeff',
      //       main_btn_color: '#000000',
      //       sub_btn_bg_color: '#5f5f5f',
      //       sub_btn_color: '#ffffff',
      //       sub_font_size: 24,
      //       sub_color: '#999999'
      //     },
      //     2: {
      //       month_color: '#FFFFFF',
      //       month_font_size: 28,
      //       month_font_weight: 400,
      //       time_color: '#999999',
      //       time_font_size: 24,
      //       time_font_weight: 400,
      //       dian_color: '#00eeff',
      //       xian_color: '#333'
      //     }
      //   }
      // });

      // this.pages = [
      //   {
      //     name: 'nftDetail',
      //     title: '轮播图',
      //     content: {
      //       type: 'banner'
      //     },
      //     facade: {
      //       marginTop: 180
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '名称区域',
      //     content: {
      //       type: 'name'
      //     },
      //     facade: {
      //       marginTop: 200,
      //       color: '#FFFFFF',
      //       fontSize: 36,
      //       fontWeight: '500'
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '标签',
      //     content: {
      //       type: 'tag'
      //     },
      //     facade: {
      //       marginTop: 6,
      //       btn_font_size: 22,
      //       btn_radius: 2,
      //       main_btn_bg_color: '#00eeff',
      //       main_btn_color: '#000000',
      //       sub_btn_bg_color: '#5f5f5f',
      //       sub_btn_color: '#ffffff',
      //       stock_color: '#00eeff',
      //       stock_font_size: 24
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '作品信息',
      //     content: {
      //       type: 'desc'
      //     },
      //     facade: {
      //       marginTop: 60,
      //       fontSize: 24,
      //       color: '#999999',
      //       otherColor: '#ffffff',
      //       lineHeight: 48,
      //       fontWeight: 400,
      //       title: '作品信息',
      //       title_image: ''
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '作品详情',
      //     content: {
      //       type: 'message'
      //     },
      //     facade: {
      //       marginTop: 32,
      //       fontSize: 24,
      //       color: '#999999',
      //       lineHeight: 48,
      //       fontWeight: 400,
      //       title: '作品故事',
      //       title_image: ''
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '购买须知',
      //     content: {
      //       type: 'notice'
      //     },
      //     facade: {
      //       marginTop: 32,
      //       fontSize: 24,
      //       color: '#999999',
      //       lineHeight: 48,
      //       fontWeight: 400,
      //       title: '购买须知',
      //       title_image: ''
      //     }
      //   }
      // ];
      // this.pages = [
      //   {
      //     name: 'product',
      //     title: '商品',
      //     content: {
      //       style: 5
      //     }
      //   },
      //   {
      //     name: 'product',
      //     title: '商品',
      //     content: {
      //       style: 4
      //     }
      //   },
      //   {
      //     name: 'product',
      //     title: '商品',
      //     content: {
      //       style: 1
      //     }
      //   },
      //   {
      //     name: 'product',
      //     title: '商品',
      //     content: {
      //       style: 2
      //     }
      //   }
      // ];
      // this.$store.commit('finish/setPageInfo', {
      //   title: '商品组件',
      //   show: true,
      //   show_sale: true,
      //   page_margin: 30,
      //   top_radius: 8,
      //   bottom_radius: 8,
      //   title_color: '#000000',
      //   sub_title_color: '#999999',
      //   price_color: '#347FF4',
      //   sales_volumes_color: '#999999',
      //   img_style: 2,
      //   category_column: 3,
      //   category_area: 1,
      //   listShow: 4,

      //   type_setting: {
      //     1: {
      //       top_margin: 20,
      //       left_margin: 20,
      //       text_margin: 20,
      //       item_background: '#FFFFFF',
      //       item_shadow: '#DEDEDE',
      //       item_shadow_size: 10,
      //       title_size: 28,
      //       title_weight: 500,
      //       price_size: 28,
      //       price_weight: 500,
      //       sales_volumes_size: 24,
      //       sales_volumes_weight: 400
      //     },
      //     2: {
      //       top_margin: 20,
      //       left_margin: 20,
      //       text_margin: 20,
      //       item_background: '#FFFFFF',
      //       item_shadow: '#DEDEDE',
      //       item_shadow_size: 10,
      //       title_size: 28,
      //       title_weight: 500,
      //       price_size: 28,
      //       price_weight: 500,
      //       sales_volumes_size: 24,
      //       sales_volumes_weight: 400
      //     },
      //     4: {
      //       top_margin: 20,
      //       left_margin: 20,
      //       text_margin: 20,
      //       item_background: '#FFFFFF',
      //       item_shadow: '#DEDEDE',
      //       item_shadow_size: 10,
      //       title_size: 28,
      //       title_weight: 500,
      //       price_size: 28,
      //       price_weight: 500,
      //       sales_volumes_size: 24,
      //       sales_volumes_weight: 400,
      //       img_width: 190,
      //       img_height: 190
      //     },
      //     5: {
      //       top_margin: 20,
      //       left_margin: 20,
      //       text_margin: 20,
      //       item_background: '#FFFFFF',
      //       item_shadow: '#DEDEDE',
      //       item_shadow_size: 10,
      //       title_size: 28,
      //       title_weight: 500,
      //       price_size: 28,
      //       price_weight: 500,
      //       sales_volumes_size: 24,
      //       sales_volumes_weight: 400,
      //       img_width: 300,
      //       img_height: 300
      //     }
      //   }
      // });

      // this.pages = [
      //   {
      //     name: 'productDetail',
      //     title: '商品轮播图',
      //     content: {
      //       type: 'banner'
      //     },
      //     facade: {
      //       marginTop: 0,
      //       background: '#FFFFFF',
      //       view_width: '750',
      //       view_height: '750',
      //       img_width: '750',
      //       img_height: '750',
      //       img_top_radius: 0,
      //       img_bottom_radius: 0,
      //       indicator_style: 3,
      //       indicator_align: 'right',
      //       indicator_color: '#CD0000'
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品价格',
      //     content: {
      //       type: 'price'
      //     },
      //     facade: {
      //       height: 120,
      //       background: '#FFFFFF',
      //       fontSize: 24,
      //       marginTop: 17,
      //       color: '#666',
      //       priceFontSize: 48,
      //       oldPriceColor: '#999',
      //       priceColor: '#FF3535'
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品名称',
      //     content: {
      //       type: 'name'
      //     },
      //     facade: {
      //       fontSize: 32,
      //       color: '#000000',
      //       fontWeight: 500,
      //       marginTop: 0,
      //       background: '#FFFFFF',
      //       status_type: 1
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '一句话介绍',
      //     content: {
      //       type: 'desc'
      //     },
      //     facade: {
      //       background: '#FFFFFF',
      //       color: '#FF3535',
      //       fold: false,
      //       fontSize: 24,
      //       fontWeight: 400,
      //       marginTop: 20
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '规则介绍',
      //     content: {
      //       type: 'detail'
      //     },
      //     facade: {
      //       background: '#FFFFFF',
      //       color: '#666666',
      //       fontSize: 24,
      //       lineHeight: 71,
      //       marginTop: 26,
      //       otherColor: '#000000',
      //       fontWeight: 400
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品详情',
      //     content: {
      //       type: 'message'
      //     },
      //     facade: {
      //       fontSize: 28,
      //       lineHeight: 55,
      //       color: '#666666',
      //       fontWeight: 400,
      //       background: '#FFFFFF',
      //       marginTop: 30,
      //       tabFontSize: 28,
      //       tabLineHeight: 88,
      //       tabCurrColor: '#D5303E',
      //       tabColor: '#000000',
      //       tabFontWeight: 500
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品列表',
      //     content: {
      //       type: 'list',
      //       style: 2
      //     },
      //     facade: {
      //       background: '',
      //       lineHeight: 110,
      //       marginTop: 22,
      //       page_margin: 12, // 页面间距
      //       top_radius: 8, // 顶部圆角
      //       bottom_radius: 8, // 底部圆角
      //       title_color: '#000000', // 标题颜色
      //       sub_title_color: '#999999', // 副标题颜色
      //       price_color: '#347FF4', // 价格颜色
      //       sales_volumes_color: '#999999', // 销量颜色
      //       img_style: 2, // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
      //       top_margin: 20, // 内容上间距
      //       left_margin: 20, // 内容左间距
      //       text_margin: 20, // 文件撑开距离
      //       item_background: '#FFFFFF', // 拍品底色
      //       item_shadow: '#DEDEDE', // 拍品阴影颜色
      //       item_shadow_size: 10, // 拍品阴影大小
      //       title_size: 28, // 标题颜色
      //       title_weight: 500, // 标题文字类型 300 加细  400正常  500 加粗
      //       price_size: 28, // 标题价格字号
      //       price_weight: 500, // 标题价格文字类型 300 加细  400正常  500 加粗
      //       sales_volumes_size: 24, // 销量次数字号
      //       sales_volumes_weight: 400, // 销量次数 300 加细  400正常  500 加粗
      //       img_width: 120,
      //       img_height: 120,
      //       show_sale: true
      //     }
      //   }
      // ];
      // this.$store.commit('finish/setPageInfo', {
      //   show: true,
      //   title: '商品详情',
      //   background: '#F7F7F7',
      //   bg_image: '',
      //   bg_image_mode: 1,
      //   page_margin: 30,
      //   show_header_view: true,
      //   header_background: '#FFFFFF',
      //   header_color: 'black',
      //   header_text_size: 32,

      //   tLineHeight: 100,
      //   tFontSize: 28,
      //   tColor: '#000000',
      //   tFontWeight: 500
      // });
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === 'dragover' && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === 'widget') {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = 'top';
            } else {
              this.position = 'down';
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = 'down';
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch('finish/pushPagesComs');
    },
    draggableStart: function draggableStart() {
      this.$store.commit('finish/setLock', true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit('finish/setLock', false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};
exports.default = _default;