"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.airdropCreateApi = airdropCreateApi;
exports.airdropDeleteApi = airdropDeleteApi;
exports.airdropDetailApi = airdropDetailApi;
exports.airdropListApi = airdropListApi;
exports.airdropUpdateApi = airdropUpdateApi;
exports.airdropUserListApi = airdropUserListApi;
exports.assistCreateProApi = assistCreateProApi;
exports.assistDeleteApi = assistDeleteApi;
exports.assistDetailApi = assistDetailApi;
exports.assistListApi = assistListApi;
exports.assistProDetailApi = assistProDetailApi;
exports.assistProListApi = assistProListApi;
exports.assistStatusApi = assistStatusApi;
exports.assistUpdateProApi = assistUpdateProApi;
exports.attrCreatApi = attrCreatApi;
exports.attrCreateApi = attrCreateApi;
exports.attrDeleteApi = attrDeleteApi;
exports.attrEdittApi = attrEdittApi;
exports.attrListApi = attrListApi;
exports.attrUpdateApi = attrUpdateApi;
exports.batchDeleteApi = batchDeleteApi;
exports.batchstatusApi = batchstatusApi;
exports.blindboxCreateApi = blindboxCreateApi;
exports.blindboxDeleteApi = blindboxDeleteApi;
exports.blindboxDetailApi = blindboxDetailApi;
exports.blindboxGoodsListApi = blindboxGoodsListApi;
exports.blindboxLevelCreateApi = blindboxLevelCreateApi;
exports.blindboxLevelListApi = blindboxLevelListApi;
exports.blindboxLevelUpdateApi = blindboxLevelUpdateApi;
exports.blindboxListApi = blindboxListApi;
exports.blindboxOrderCountApi = blindboxOrderCountApi;
exports.blindboxOrderListApi = blindboxOrderListApi;
exports.blindboxSeriesCreateApi = blindboxSeriesCreateApi;
exports.blindboxSeriesDeleteApi = blindboxSeriesDeleteApi;
exports.blindboxSeriesDetailApi = blindboxSeriesDetailApi;
exports.blindboxSeriesListApi = blindboxSeriesListApi;
exports.blindboxSeriesUpdateApi = blindboxSeriesUpdateApi;
exports.blindboxStatusApi = blindboxStatusApi;
exports.blindboxUpdateApi = blindboxUpdateApi;
exports.brandCategoryCreateApi = brandCategoryCreateApi;
exports.brandCategoryDeleteApi = brandCategoryDeleteApi;
exports.brandCategoryListApi = brandCategoryListApi;
exports.brandCategoryStatusApi = brandCategoryStatusApi;
exports.brandCategoryUpdateApi = brandCategoryUpdateApi;
exports.brandCreateApi = brandCreateApi;
exports.brandDeleteApi = brandDeleteApi;
exports.brandListApi = brandListApi;
exports.brandStatusApi = brandStatusApi;
exports.brandUpdateApi = brandUpdateApi;
exports.categoryBrandListApi = categoryBrandListApi;
exports.categoryListApi = categoryListApi;
exports.categorySelectApi = categorySelectApi;
exports.categorySelectTreeApi = categorySelectTreeApi;
exports.checkDitri = checkDitri;
exports.crawlFromApi = crawlFromApi;
exports.createStore = createStore;
exports.delStore = delStore;
exports.destoryApi = destoryApi;
exports.discountCreateApi = discountCreateApi;
exports.discountDelApi = discountDelApi;
exports.discountDetailApi = discountDetailApi;
exports.discountEditApi = discountEditApi;
exports.discountListApi = discountListApi;
exports.discountStopApi = discountStopApi;
exports.downStore = downStore;
exports.editStore = editStore;
exports.editStoreStatus = editStoreStatus;
exports.getCategoryCommision = getCategoryCommision;
exports.getStoreList = getStoreList;
exports.getcity = getcity;
exports.goodLstApi = goodLstApi;
exports.lstFilterApi = lstFilterApi;
exports.newpriorityCreateApi = newpriorityCreateApi;
exports.newpriorityDeleteApi = newpriorityDeleteApi;
exports.newpriorityListApi = newpriorityListApi;
exports.newuserProductAddApi = newuserProductAddApi;
exports.newuserProductLstApi = newuserProductLstApi;
exports.newuserProductUpdateApi = newuserProductUpdateApi;
exports.newuserlstFilterApi = newuserlstFilterApi;
exports.preSaleCreateProApi = preSaleCreateProApi;
exports.preSaleProDetailApi = preSaleProDetailApi;
exports.preSaleProListApi = preSaleProListApi;
exports.preSaleProUnmApi = preSaleProUnmApi;
exports.preSaleUpdateProApi = preSaleUpdateProApi;
exports.preSellDeleteApi = preSellDeleteApi;
exports.presellStatusApi = presellStatusApi;
exports.priorityCreateApi = priorityCreateApi;
exports.priorityDeleteApi = priorityDeleteApi;
exports.priorityDetailApi = priorityDetailApi;
exports.priorityListApi = priorityListApi;
exports.priorityUpdateApi = priorityUpdateApi;
exports.productConfigApi = productConfigApi;
exports.productCopyApi = productCopyApi;
exports.productCopyCountApi = productCopyCountApi;
exports.productCopyRecordApi = productCopyRecordApi;
exports.productCreateApi = productCreateApi;
exports.productDeleteApi = productDeleteApi;
exports.productDetailApi = productDetailApi;
exports.productGetCommission = productGetCommission;
exports.productGetTempKeysApi = productGetTempKeysApi;
exports.productLstApi = productLstApi;
exports.productUpdateApi = productUpdateApi;
exports.restoreApi = restoreApi;
exports.reviewLstApi = reviewLstApi;
exports.reviewReplyApi = reviewReplyApi;
exports.seckillProTimeApi = seckillProTimeApi;
exports.seckillProductCreateApi = seckillProductCreateApi;
exports.seckillProductDetailApi = seckillProductDetailApi;
exports.seckillProductLstApi = seckillProductLstApi;
exports.seckillProductUpdateApi = seckillProductUpdateApi;
exports.shippingListApi = shippingListApi;
exports.snapshotCreateApi = snapshotCreateApi;
exports.snapshotDeleteApi = snapshotDeleteApi;
exports.snapshotDetailApi = snapshotDetailApi;
exports.snapshotListApi = snapshotListApi;
exports.snapshotUpdateApi = snapshotUpdateApi;
exports.snapshotUserListApi = snapshotUserListApi;
exports.spikeDestoryApi = spikeDestoryApi;
exports.spikeProductDeleteApi = spikeProductDeleteApi;
exports.spikeRestoreApi = spikeRestoreApi;
exports.spikeStatusApi = spikeStatusApi;
exports.spikelstFilterApi = spikelstFilterApi;
exports.statusApi = statusApi;
exports.storeCategoryCreateApi = storeCategoryCreateApi;
exports.storeCategoryDeleteApi = storeCategoryDeleteApi;
exports.storeCategoryListApi = storeCategoryListApi;
exports.storeCategoryStatusApi = storeCategoryStatusApi;
exports.storeCategoryUpdateApi = storeCategoryUpdateApi;
exports.templateListApi = templateListApi;
exports.templateLsitApi = templateLsitApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 商品分类 -- 列表
 */
function storeCategoryListApi() {
  return _request.default.get('store/category/lst');
}
/**
 * @description 商品分类 -- 新增表单
 */
function storeCategoryCreateApi() {
  return _request.default.get('store/category/create/form');
}
/**
 * @description 商品分类 -- 编辑表单
 */
function storeCategoryUpdateApi(id) {
  return _request.default.get("store/category/update/form/".concat(id));
}
/**
 * @description 商品分类 -- 删除
 */
function storeCategoryDeleteApi(id) {
  return _request.default.post("store/category/delete/".concat(id));
}
/**
 * @description 商品分类 -- 修改状态
 */
function storeCategoryStatusApi(id, status) {
  return _request.default.post("store/category/status/".concat(id), {
    status: status
  });
}
//  获取商品分类分销比例
function getCategoryCommision(id) {
  return _request.default.get('store/category/getCateDistriRate', {
    id: id
  });
}
/**
 * @description 属性规则 -- 列表
 */
function templateListApi(data) {
  return _request.default.get('store/attr/template/lst', data);
}
/**
 * @description 属性规则 -- 添加
 */
function attrCreatApi(data) {
  return _request.default.post('store/attr/template/create', data);
}
/**
 * @description 属性规则 -- 编辑
 */
function attrEdittApi(id, data) {
  return _request.default.post("store/attr/template/".concat(id), data);
}
/**
 * @description 属性规则 -- 删除
 */
function attrDeleteApi(id) {
  return _request.default.delete("store/attr/template/".concat(id));
}
/**
 * @description 商品添加 -- 属性规则
 */
function templateLsitApi() {
  return _request.default.get("/store/attr/template/list");
}
/**
 * @description 商品列表 -- 列表
 */
function productLstApi(data) {
  return _request.default.get("store/product/lst", data);
}
/**
 * @description 商品列表 -- 删除
 */
function productDeleteApi(id) {
  return _request.default.post("store/product/delete/".concat(id));
}
/**
 * @description 秒杀商品列表 -- 删除
 */
function spikeProductDeleteApi(id) {
  return _request.default.post("store/seckill_product/delete/".concat(id));
}
/**
 * @description 商品列表 -- 添加
 */
function productCreateApi(data) {
  return _request.default.post("store/product/create", data);
}
/**
 * @description 商品列表 -- 复制
 */
function productCopyApi(data) {
  return _request.default.post("store/productcopy/save", data);
}
/**
 * @description 秒杀品列表 -- 添加
 */
function seckillProductCreateApi(data) {
  return _request.default.post("store/seckill_product/create", data);
}
/**
 * @description 商品列表 -- 编辑
 */
function productUpdateApi(id, data) {
  return _request.default.post("store/product/update/".concat(id), data);
}
/**
 * @description 商品列表 -- 详情
 */
function productDetailApi(id) {
  return _request.default.get("store/product/detail/".concat(id));
}
/**
 * @description 秒杀商品 -- 详情
 */
function seckillProductDetailApi(id) {
  return _request.default.get("store/seckill_product/detail/".concat(id));
}
/**
 * @description 商品列表 -- 商户分类
 */
function categorySelectApi() {
  return _request.default.get("store/category/lst");
}
/**
 * @description 商品列表 -- 商户分类
 */
function categorySelectTreeApi() {
  return _request.default.get("store/category/select");
}
/**
 * @description 商品列表 -- 平台分类
 */
function categoryListApi() {
  return _request.default.get("store/category/list");
}
/**
 * @description 商品列表 -- 品牌分类
 */
function categoryBrandListApi() {
  return _request.default.get("store/category/brandlist");
}
/**
 * @description 商品列表 -- 运费模板筛选
 */
function shippingListApi() {
  return _request.default.get("store/shipping/list");
}
/**
 * @description 商品列表 -- 列表表头
 */
function lstFilterApi() {
  return _request.default.get("store/product/lst_filter");
}
/**
 * @description 秒杀商品列表 -- 列表表头
 */
function spikelstFilterApi() {
  return _request.default.get("store/seckill_product/lst_filter");
}
/**
 * @description 新人专享商品列表 -- 列表表头
 */
function newuserlstFilterApi() {
  return _request.default.get("store/newperson/lst_filter");
}
/**
 * @description 商品列表 -- 上下架
 */
function statusApi(data) {
  return _request.default.post("store/product/status", data);
}
/**
 * @description 商品列表 -- 上下架
 */
function batchstatusApi(data) {
  return _request.default.post("store/product/status", data);
}
/**
 * @description 商品列表 -- 删除
 */
function batchDeleteApi(data) {
  return _request.default.post("store/nftCollection/batchDel", data);
}
/**
 * @description 秒杀商品列表 -- 上下架
 */
function spikeStatusApi(id, status) {
  return _request.default.post("store/seckill_product/status/".concat(id), {
    status: status
  });
}
/**
 * @description 组件商品列表 -- 列表
 */
function goodLstApi(data) {
  return _request.default.get("store/product/list", data);
}
/**
 * @description 配置状态
 */
function productConfigApi() {
  return _request.default.get("store/product/config");
}
/**
 * @description 商品列表 -- 评价列表
 */
function reviewLstApi(data) {
  return _request.default.get("store/reply/lst", data);
}
/**
 * @description 商品列表 -- 评价回复
 */
function reviewReplyApi(id) {
  return _request.default.get("store/reply/form/".concat(id));
}
/**
 * @description 商品列表 -- 评价回复
 */
function destoryApi(id) {
  return _request.default.post("store/product/destory/".concat(id));
}
/**
 * @description 秒杀商品列表 -- 加入回收站
 */
function spikeDestoryApi(id) {
  return _request.default.post("store/seckill_product/destory/".concat(id));
}
/**
 * @description 商品列表 -- 恢复
 */
function restoreApi(id) {
  return _request.default.post("store/product/restore/".concat(id));
}
/**
 * @description 秒杀商品列表 -- 恢复
 */
function spikeRestoreApi(id) {
  return _request.default.post("store/seckill_product/restore/".concat(id));
}
/**
 * @description 商品列表 -- 复制商品
 */
function crawlFromApi(data) {
  return _request.default.get("store/productcopy/get", data);
}
/**
 * @description 秒杀商品列表 -- 列表
 */
function seckillProductLstApi(data) {
  return _request.default.get("store/seckill_product/lst", data);
}
/**
 * @description 秒杀商品 -- 可选时间表
 */
function seckillProTimeApi() {
  return _request.default.get("store/seckill_product/lst_time");
}
/**
 * @description 秒杀商品列表 -- 编辑
 */
function seckillProductUpdateApi(id, data) {
  return _request.default.post("store/seckill_product/update/".concat(id), data);
}
/**
 * @description 新人专享商品列表 -- 列表
 */
function newuserProductLstApi(data) {
  return _request.default.get("store/newperson/lst", data);
}
/**
 * @description 新人专享商品添加
 */
function newuserProductAddApi(data) {
  return _request.default.post("store/newperson/create", data);
}
/**
 * @description 新人专享商品修改
 */
function newuserProductUpdateApi(id, data) {
  return _request.default.post("store/newperson/update/".concat(id), data);
}
/**
 * @description 复制商品 -- 剩余次数
 */
function productCopyCountApi() {
  return _request.default.get("store/productcopy/count");
}
/**
 * @description 复制商品 -- 复制记录
 */
function productCopyRecordApi(data) {
  return _request.default.get("store/productcopy/lst", data);
}
/**
 * @description 预售 -- 添加预售商品
 */
function preSaleCreateProApi(data) {
  return _request.default.post("store/product/presell/create", data);
}
/**
 * @description 预售 -- 编辑预售商品
 */
function preSaleUpdateProApi(id, data) {
  return _request.default.post("store/product/presell/update/".concat(id), data);
}
/**
 * @description 预售 -- 列表
 */
function preSaleProListApi(data) {
  return _request.default.get("store/product/presell/lst", data);
}
/**
 * @description 预售商品 -- 详情
 */
function preSaleProDetailApi(id) {
  return _request.default.get("store/product/presell/detail/".concat(id));
}
/**
 * @description 预售商品 -- 头部数量
 */
function preSaleProUnmApi() {
  return _request.default.get("store/product/presell/number");
}
/**
 * @description 预售商品列表 -- 显示状态（上下架）
 */
function presellStatusApi(id, status) {
  return _request.default.post("store/product/presell/status/".concat(id), {
    status: status
  });
}
/**
 * @description 预售商品列表 -- 删除
 */
function preSellDeleteApi(id) {
  return _request.default.post("store/product/presell/delete/".concat(id));
}
/**
 * @description 助力 -- 添加助力商品
 */
function assistCreateProApi(data) {
  return _request.default.post("store/product/assist/create", data);
}
/**
 * @description 助力 -- 编辑助力商品
 */
function assistUpdateProApi(id, data) {
  return _request.default.post("store/product/assist/update/".concat(id), data);
}
/**
 * @description 助力 -- 列表
 */
function assistProListApi(data) {
  return _request.default.get("store/product/assist/lst", data);
}
/**
 * @description 助力商品 -- 详情
 */
function assistProDetailApi(id) {
  return _request.default.get("store/product/assist/detail/".concat(id));
}
/**
 * @description 助力商品列表 -- 删除
 */
function assistDeleteApi(id) {
  return _request.default.post("store/product/assist/delete/".concat(id));
}
/**
 * @description 助力活动 -- 列表
 */
function assistListApi(data) {
  return _request.default.get("store/product/assist_set/lst", data);
}
/**
 * @description 助力商品列表 -- 显示状态（上下架）
 */
function assistStatusApi(id, status) {
  return _request.default.post("store/product/assist/status/".concat(id), {
    status: status
  });
}
/**
 * @description 助力活动列表 -- 查看详情
 */
function assistDetailApi(id, data) {
  return _request.default.get("store/product/assist_set/detail/".concat(id), data);
}
/**
 * @description 助力活动列表 -- 查看详情
 */
function productGetTempKeysApi() {
  return _request.default.get("store/product/temp_key");
}
/**
 * @description 助力活动列表 -- 查看详情
 */
function productGetCommission() {
  return _request.default.get("store/product/distribution_proportion");
}
/**
 * @description 
 */
function checkDitri() {
  return _request.default.get("info");
}
// 获取省市区
function getcity() {
  return _request.default.get('../api/system/city/lst');
}
// 获取仓库列表
function getStoreList(data) {
  return _request.default.get('store/product/warehouse/list', data);
}
// 创建仓库
function createStore(data) {
  return _request.default.post('store/product/warehouse/create', data);
}
// 修改仓库
function editStore(data) {
  return _request.default.post('store/product/warehouse/update', data);
}
// 删除仓库
function delStore(data) {
  return _request.default.post('store/product/warehouse/del', data);
}
// 修改仓库状态
function editStoreStatus(data) {
  return _request.default.post('store/product/warehouse/editStatus', data);
}
// 下架仓库中的所有商品
function downStore(data) {
  return _request.default.post('store/product/warehouse/offShelvesAllProduct', data);
}
/**
 * @description 品牌分类 -- 列表
 */
function brandCategoryListApi(data) {
  return _request.default.get('store/brand/category/lst', data);
}
/**
 * @description 品牌分类 -- 新增表单
 */
function brandCategoryCreateApi() {
  return _request.default.get('store/brand/category/create/form');
}
/**
 * @description 品牌分类 -- 编辑表单
 */
function brandCategoryUpdateApi(id) {
  return _request.default.get("store/brand/category/update/form/".concat(id));
}
/**
 * @description 品牌分类 -- 删除
 */
function brandCategoryDeleteApi(id) {
  return _request.default.post("store/brand/category/delete/".concat(id));
}
/**
 * @description 品牌分类 -- 修改状态
 */
function brandCategoryStatusApi(id, status) {
  return _request.default.post("store/brand/category/status/".concat(id), {
    status: status
  });
}
/**
 * @description 品牌 -- 列表
 */
function brandListApi(data) {
  return _request.default.get('store/brand/lst', data);
}
/**
 * @description 品牌 -- 新增表单
 */
function brandCreateApi() {
  return _request.default.get('store/brand/create/form');
}
/**
 * @description 品牌 -- 编辑表单
 */
function brandUpdateApi(id) {
  return _request.default.get("store/brand/update/form/".concat(id));
}
/**
 * @description 品牌 -- 删除
 */
function brandDeleteApi(id) {
  return _request.default.post("store/brand/delete/".concat(id));
}
/**
 * @description 品牌列表 -- 修改状态
 */
function brandStatusApi(id, status) {
  return _request.default.post("store/brand/status/".concat(id), {
    status: status
  });
}
/**
 * @description 属性 -- 新增
 */
function attrCreateApi(data) {
  return _request.default.post('store/attr/create', data);
}
/**
 * @description 属性 -- 编辑
 */
function attrUpdateApi(id, data) {
  return _request.default.post("store/attr/update/".concat(id), data);
}
/**
 * @description 属性 -- 列表
 */
function attrListApi(data) {
  return _request.default.get('store/attr/lst', data);
}
/**
 * @description 优先购 -- 列表
 */
function priorityListApi(data) {
  return _request.default.get('marketing/purchase/lst', data);
}
/**
 * @description 优先购 -- 新增表单
 * @param {Object} data
 */
function priorityCreateApi(data) {
  return _request.default.post('marketing/purchase/create', data);
}
/**
 * @description 优先购新 -- 列表
 */
function newpriorityListApi(data) {
  return _request.default.post('activity/priorityBuy/getList', data);
}
/**
 * @description 优先购新 -- 新增表单
 * @param {Object} data
 */
function newpriorityCreateApi(data) {
  return _request.default.post('activity/priorityBuy/create', data);
}
/**
 * @description 优先购 -- 详情
 * @param {Object} data
 */
function priorityDetailApi(id) {
  return _request.default.get("marketing/purchase/detail/".concat(id));
}
/**
 * @description 优先购 -- 编辑表单
 * @param {Object} data
 */
function priorityUpdateApi(id, data) {
  return _request.default.post("marketing/purchase/update/".concat(id), data);
}
/**
 * @description 优先购 -- 删除
 * @param {Object} data
 */
function priorityDeleteApi(id) {
  return _request.default.delete("marketing/purchase/delete/".concat(id));
}
/**
 * @description 优先购新 -- 删除
 * @param {Object} data
 */
function newpriorityDeleteApi(data) {
  return _request.default.post("activity/priorityBuy/delete", data);
}
/**
 * @description 快照 -- 列表
 * @param {Object} data
 */
function snapshotListApi(data) {
  return _request.default.get('marketing/snapshot/lst', data);
}
/**
 * @description 快照 -- 详情
 * @param {Object} data
 */
function snapshotDetailApi(id) {
  return _request.default.get("marketing/snapshot/detail/".concat(id));
}
/**
 * @description 快照 -- 创建
 * @param {Object} data
 */
function snapshotCreateApi(data, opts) {
  return _request.default.post('marketing/snapshot/create', data, opts);
}
/**
 * @description 快照 -- 编辑
 * @param {Object} data
 */
function snapshotUpdateApi(id, data) {
  return _request.default.post("marketing/snapshot/update/".concat(id), data);
}
/**
 * @description 快照 -- 删除
 * @param {Object} data
 */
function snapshotDeleteApi(id) {
  return _request.default.delete("marketing/snapshot/delete/".concat(id));
}
/**
 * @description 快照 -- 查看用户
 * @param {Object} data
 */
function snapshotUserListApi(data) {
  return _request.default.get("marketing/snapshot/user/lst", data);
}
/**
 * @description 空投 -- 列表
 * @param {Object} data
 */
function airdropListApi(data) {
  return _request.default.get('marketing/airdrop/lst', data);
}
/**
 * @description 空投 -- 详情
 * @param {Object} data
 */
function airdropDetailApi(id) {
  return _request.default.get("marketing/airdrop/detail/".concat(id));
}
/**
 * @description 空投 -- 创建
 * @param {Object} data
 */
function airdropCreateApi(data, opts) {
  return _request.default.post('marketing/airdrop/create', data, opts);
}
/**
 * @description 空投 -- 编辑
 * @param {Object} data
 */
function airdropUpdateApi(id, data) {
  return _request.default.post("marketing/airdrop/update/".concat(id), data);
}
/**
 * @description 空投 -- 删除
 * @param {Object} data
 */
function airdropDeleteApi(id) {
  return _request.default.delete("marketing/airdrop/delete/".concat(id));
}
/**
 * @description 空投 -- 查看用户
 * @param {Object} data
 */
function airdropUserListApi(data) {
  return _request.default.get("marketing/airdrop/getUserList", data);
}

/**
 * @description 盲盒 -- 列表
 * @param {Object} data
 */
function blindboxListApi(data) {
  return _request.default.get('activity/blindBox/lst', data);
}
/**
 * @description 盲盒 -- 详情
 * @param {Object} data
 */
function blindboxDetailApi(id) {
  return _request.default.get("activity/blindBox/detail/".concat(id));
}
/**
 * @description 盲盒 -- 创建
 * @param {Object} data
 */
function blindboxCreateApi(data) {
  return _request.default.post('activity/blindBox/create', data);
}
/**
 * @description 盲盒 -- 修改
 * @param {Object} data
 */
function blindboxUpdateApi(id, data) {
  return _request.default.post("activity/blindBox/update/".concat(id), data);
}
/**
 * @description 盲盒 -- 修改状态
 * @param {Object} data
 */
function blindboxStatusApi(id, data) {
  return _request.default.post("activity/blindBox/switchStatus/".concat(id), data);
}
/**
 * @description 盲盒 -- 删除
 * @param {Object} data
 */
function blindboxDeleteApi(id) {
  return _request.default.delete("activity/blindBox/delete/".concat(id));
}
/**
 * @description 盲盒 -- 关联商品
 * @param {Object} data
 */
function blindboxGoodsListApi(data) {
  return _request.default.get("activity/blindBox/product/lst", data);
}
/**
 * @description 盲盒 -- 级别列表
 * @param {Object} data
 */
function blindboxLevelListApi(data) {
  return _request.default.get("activity/blindBox/level/lst", data);
}
/**
 * @description 盲盒 -- 添加等级
 * @param {Object} data
 */
function blindboxLevelCreateApi(data) {
  return _request.default.post('activity/blindBox/level/create', data);
}
/**
 * @description 盲盒 -- 编辑等级
 * @param {Object} data
 */
function blindboxLevelUpdateApi(id, data) {
  return _request.default.post("activity/blindBox/level/update/".concat(id), data);
}
/**
 * @description 盲盒 -- 下单记录
 * @param {Object} data
 */
function blindboxOrderListApi(data) {
  return _request.default.get("activity/blindBox/user/lst", data);
}
/**
 * @description 盲盒 -- 下单统计数字
 * @param {Object} data
 */
function blindboxOrderCountApi(data) {
  return _request.default.get("activity/blindBox/user/number", data);
}

/**
 * @description 盲盒 -- 盲盒系列列表
 * @param {Object} data
 */
function blindboxSeriesListApi(data) {
  return _request.default.get("activity/blindBox/album/lst", data);
}

/**
 * @description 盲盒 -- 盲盒系列添加
 * @param {Object} data
 */
function blindboxSeriesCreateApi(data) {
  return _request.default.post('activity/blindBox/album/create', data);
}

/**
 * @description 盲盒 -- 盲盒系列编辑
 * @param {Object} data
 */
function blindboxSeriesUpdateApi(id, data) {
  return _request.default.post("activity/blindBox/album/update/".concat(id), data);
}

/**
 * @description 盲盒 -- 盲盒系列删除
 * @param {Object} data
 */
function blindboxSeriesDeleteApi(id) {
  return _request.default.delete("activity/blindBox/album/delete/".concat(id));
}

/**
 * @description 盲盒 -- 查看盲盒详情
 * @param {Object} data
 */
function blindboxSeriesDetailApi(id) {
  return _request.default.get("activity/blindBox/album/detail/".concat(id));
}

/**
 * @description 折扣活动列表
 * @param {Object} data
 */
function discountListApi(data) {
  return _request.default.post("activity/discountEvent/getList", data);
}

/**
 * @description 折扣活动创建
 * @param {Object} data
 */
function discountCreateApi(data) {
  return _request.default.post("activity/discountEvent/create", data);
}
/**
 * @description 折扣活动修改
 * @param {Object} data
 */
function discountEditApi(data) {
  return _request.default.post("activity/discountEvent/setInfo", data);
}
/**
 * @description 折扣活动详情
 * @param {Object} data
 */
function discountDetailApi(data) {
  return _request.default.post("activity/discountEvent/getInfo", data);
}
/**
 * @description 折扣活动删除
 * @param {Object} data
 */
function discountDelApi(data) {
  return _request.default.post("activity/discountEvent/delete", data);
}
/**
 * @description 折扣活动停止
 * @param {Object} data
 */
function discountStopApi(data) {
  return _request.default.post("activity/discountEvent/stop", data);
}