"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountCreateOrderApi = accountCreateOrderApi;
exports.accountHistoryListApi = accountHistoryListApi;
exports.accountInfoApi = accountInfoApi;
exports.accountOrderListApi = accountOrderListApi;
exports.createRechargeOrderApi = createRechargeOrderApi;
exports.getSpaceInfoApi = getSpaceInfoApi;
exports.rechargeOrderListApi = rechargeOrderListApi;
exports.spaceCreateInfoApi = spaceCreateInfoApi;
exports.spaceSetInfoApi = spaceSetInfoApi;
exports.templateInfoApi = templateInfoApi;
exports.templateListApi = templateListApi;
exports.templateMarketListApi = templateMarketListApi;
exports.templateSetInfoApi = templateSetInfoApi;
exports.templateSwitchApi = templateSwitchApi;
exports.userSpaceApi = userSpaceApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 模型列表
 */
function templateListApi(data) {
  return _request.default.post('exhibitionMerchant/getList', data);
}

/**
 * @description 模型修改状态
 */
function templateSwitchApi(data) {
  return _request.default.post('/exhibitionMerchant/switch', data);
}

/**
 * @description 模型详情
 */
function templateInfoApi(data) {
  return _request.default.post('/exhibitionMerchant/getInfo', data);
}

/**
 * @description 模型修改设置
 */
function templateSetInfoApi(data) {
  return _request.default.post('/exhibitionMerchant/setInfo', data);
}

/**
 * @description 用户空间
 */
function userSpaceApi(data) {
  return _request.default.post('/exhibitionUser/getList', data);
}

/**
 * @description 模型市场列表
 */
function templateMarketListApi(data) {
  return _request.default.post('/exhibition/market', data);
}
/**
 * @description 模型市场列表 - 获取支付二维码
 */
function createRechargeOrderApi(data) {
  return _request.default.post('/exhibition/createRechargeOrder', data);
}

/**
 * @description meta账户详情
 */
function accountInfoApi(data) {
  return _request.default.get('/account/accountInfo', data);
}

/**
 * @description meta账户明细
 */
function accountHistoryListApi(data) {
  return _request.default.get('/account/accountHistoryList', data);
}

/**
 * @description meta订单充值记录
 */
function accountOrderListApi(data) {
  return _request.default.get('/account/orderList', data);
}

/**
 * @description meta充值
 */
function accountCreateOrderApi(data) {
  return _request.default.post('/account/createOrder', data);
}

/**
 * @description 我的订单列表
 */
function rechargeOrderListApi(data) {
  return _request.default.post('/exhibition/rechargeOrderList', data);
}

/**
 * @description 空间设置详情
 */
function getSpaceInfoApi(data) {
  return _request.default.post('/meta/space/getInfo', data);
}

/**
 * @description 空间设置
 */
function spaceCreateInfoApi(data) {
  return _request.default.post('/meta/space/create', data);
}

/**
 * @description 空间修改设置
 */
function spaceSetInfoApi(data) {
  return _request.default.post('/meta/space/setInfo', data);
}