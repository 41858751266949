"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data: function data() {
    return {
      // menuList: []
      auctionMenu: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes', 'sidebar', 'menuList'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    defaultOpen: function defaultOpen() {
      var list = this.menuList.map(function (m) {
        return m.route;
      });
      return list;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      if (this.sidebar.opened) {
        this.setOpen();
      } else {
        if (this.timeout) clearTimeout(this.timeout);
      }
      return !this.sidebar.opened;
    }
  }),
  mounted: function mounted() {
    this.getMenus();
  },
  methods: {
    getMenus: function getMenus() {
      this.$store.dispatch('user/getMenus');
    },
    closeMenuAction: function closeMenuAction(index) {
      this.$refs.menuEL.open(index);
    },
    setOpen: function setOpen() {
      var _this = this;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var list = _this.menuList.map(function (m) {
          return m.route;
        });
        list.forEach(function (m) {
          _this.$refs.menuEL.open(m);
        });
      }, 100);
    }
  }
};
exports.default = _default;