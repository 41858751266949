var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品图片", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "banner-control",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("区域尺寸")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.view_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "view_width", $$v)
                                  },
                                  expression: "facade.view_width",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { margin: "0 6px" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.view_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "view_height", $$v)
                                  },
                                  expression: "facade.view_height",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("图片尺寸")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_width", $$v)
                                  },
                                  expression: "facade.img_width",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { margin: "0 6px" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_height", $$v)
                                  },
                                  expression: "facade.img_height",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图上圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.img_top_radius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "img_top_radius", $$v)
                            },
                            expression: "facade.img_top_radius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图下圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.img_bottom_radius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "img_bottom_radius", $$v)
                            },
                            expression: "facade.img_bottom_radius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("choose", {
                      staticClass: "mt20",
                      attrs: {
                        data: _vm.pointerData,
                        title: "指示器类型",
                        margin: "0 10px 0 0",
                        width: "75px",
                        height: "30px",
                      },
                      model: {
                        value: _vm.facade.indicator_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_style", $$v)
                        },
                        expression: "facade.indicator_style",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.indicator_align,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "indicator_align", $$v)
                              },
                              expression: "facade.indicator_align",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "center" } }, [
                              _vm._v("中间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "left" } }, [
                              _vm._v("左"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "right" } }, [
                              _vm._v("右"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "选中颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.indicator_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "indicator_color", $$v)
                            },
                            expression: "facade.indicator_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("图片展示方式"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center flex-wrap" },
                      _vm._l(_vm.imgStyleData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "change-type",
                            staticStyle: {
                              "margin-right": "20px",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img",
                                class: {
                                  curr: item.value === _vm.facade.img_style,
                                },
                              },
                              [
                                _c("label", [
                                  _c("img", { attrs: { src: item.src } }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.facade.img_style,
                                        expression: "facade.img_style",
                                      },
                                    ],
                                    staticClass: "choose-input",
                                    attrs: { type: "radio", name: "choose" },
                                    domProps: {
                                      value: item.value,
                                      checked: _vm._q(
                                        _vm.facade.img_style,
                                        item.value
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.facade,
                                          "img_style",
                                          item.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "name color-gray align-c ft12" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "control",
              { attrs: { title: "状态区域", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "区域高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "height", $$v)
                            },
                            expression: "facade.height",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "状态样式" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "item-align-center",
                            staticStyle: { height: "30px" },
                            model: {
                              value: _vm.facade.status_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "status_type", $$v)
                              },
                              expression: "facade.status_type",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                staticClass: "item-align-center",
                                attrs: { label: 1 },
                              },
                              [
                                _c("div", { staticClass: "status-type1" }, [
                                  _vm._v("预展中"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                staticClass: "item-align-center",
                                attrs: { label: 2 },
                              },
                              [
                                _c("div", { staticClass: "status-type2" }, [
                                  _vm._v("预展中"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                staticClass: "item-align-center",
                                attrs: { label: 3 },
                              },
                              [
                                _c("div", { staticClass: "status-type3" }, [
                                  _vm._v("预展中"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "时间风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.time_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "time_type", $$v)
                              },
                              expression: "facade.time_type",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { label: 1 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "time1 flex align-items-c ft12",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              "font-weight": "500",
                                              "margin-right": "5px",
                                            },
                                          },
                                          [_vm._v("结拍")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("1"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("天")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("12"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("时")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("23"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("分")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("20"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("秒")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { label: 2 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "time2 flex align-items-c ft12",
                                        style: { color: _vm.facade.color },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              "font-weight": "500",
                                              "margin-right": "5px",
                                            },
                                          },
                                          [_vm._v("结拍")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("1"),
                                          _c("span", [_vm._v("天")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("12"),
                                          _c("span", [_vm._v("时")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("23"),
                                          _c("span", [_vm._v("分")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("20"),
                                          _c("span", [_vm._v("秒")]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "开拍提醒" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "时间颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.timeColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "timeColor", $$v)
                            },
                            expression: "facade.timeColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.facade.time_type === 1
                      ? [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "突出背景" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#000000" },
                                model: {
                                  value: _vm.facade.bgBtnColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "bgBtnColor", $$v)
                                  },
                                  expression: "facade.bgBtnColor",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "突出文字" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#000000" },
                                model: {
                                  value: _vm.facade.bgColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "bgColor", $$v)
                                  },
                                  expression: "facade.bgColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm.facade.time_type === 2
                      ? [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "突出时间" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#000000" },
                                model: {
                                  value: _vm.facade.activeColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "activeColor", $$v)
                                  },
                                  expression: "facade.activeColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "price"
        ? [
            _c(
              "control",
              { attrs: { title: "价格", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.priceColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceColor", $$v)
                              },
                              expression: "facade.priceColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.priceFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceFontSize", $$v)
                              },
                              expression: "facade.priceFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "desc"
        ? [
            _c(
              "control",
              { attrs: { title: "一句话介绍", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("只展示一行")]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "flex-one",
                            staticStyle: { "font-size": "12px", color: "#999" },
                          }),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.fold,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fold", $$v)
                              },
                              expression: "facade.fold",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "control",
              { attrs: { title: "本场规则", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.otherColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "otherColor", $$v)
                            },
                            expression: "facade.otherColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 50, max: 300 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "bid_record"
        ? [
            _c(
              "control",
              { attrs: { title: "竞价记录", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "价格颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.priceColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "priceColor", $$v)
                            },
                            expression: "facade.priceColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "价格字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.priceFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "priceFontSize", $$v)
                            },
                            expression: "facade.priceFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "价格风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.priceFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceFontWeight", $$v)
                              },
                              expression: "facade.priceFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("属性介绍"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.attrColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrColor", $$v)
                            },
                            expression: "facade.attrColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.attrOtherColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrOtherColor", $$v)
                            },
                            expression: "facade.attrOtherColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.attrFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrFontSize", $$v)
                            },
                            expression: "facade.attrFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.attrFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "attrFontWeight", $$v)
                              },
                              expression: "facade.attrFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.attrLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrLineHeight", $$v)
                            },
                            expression: "facade.attrLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("详情介绍"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c("control", { attrs: { title: "拍品列表", is_tab: false } }, [
              _c("div", { staticClass: "ft14" }, [
                _vm._v("请在左侧拍品组件里设置"),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }