"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3d = require("@/api/open3d");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      list: [],
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 10,
        date: '',
        nickname: '',
        phone: '',
        template_name: ''
      },
      total: 0,
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _open3d.rechargeOrderListApi)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.list = response.data.list;
        _this.listLoading = false;
      }).catch(function (message) {
        _this.listLoading = false;
        _this.list = [];
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    // 选择时间
    selectChangeData: function selectChangeData(tab) {
      this.queryParams.date = tab;
      this.timeVal = [];
      this.pageChange(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.queryParams.date = e ? this.timeVal.join('-') : '';
      this.pageChange(1);
    }
  }
};
exports.default = _default;