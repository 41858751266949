"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SMSInfo = SMSInfo;
exports.accountHistoryListApi = accountHistoryListApi;
exports.cancelBankTransferApi = cancelBankTransferApi;
exports.capitalFlowExportApi = capitalFlowExportApi;
exports.capitalFlowLstApi = capitalFlowLstApi;
exports.confirmBankTransfer = confirmBankTransfer;
exports.confirmReconciliationApi = confirmReconciliationApi;
exports.createEntrustExtractApi = createEntrustExtractApi;
exports.createNftRechargeOrderApi = createNftRechargeOrderApi;
exports.createPayOrder = createPayOrder;
exports.createRechargeOrderApi = createRechargeOrderApi;
exports.createSMSRechargeOrderApi = createSMSRechargeOrderApi;
exports.createYeepayMerchantApi = createYeepayMerchantApi;
exports.extractRechargeRefund = extractRechargeRefund;
exports.extractRefundApi = extractRefundApi;
exports.financeConfirmBankTransfer = financeConfirmBankTransfer;
exports.firstPayApi = firstPayApi;
exports.getCurrentReconciliationInfoApi = getCurrentReconciliationInfoApi;
exports.getNftRechargeOrderApi = getNftRechargeOrderApi;
exports.getReconciliationPayOrderRecordApi = getReconciliationPayOrderRecordApi;
exports.getSMSRechargeOrder = getSMSRechargeOrder;
exports.getSMSSendRecordApi = getSMSSendRecordApi;
exports.getSendConfigApi = getSendConfigApi;
exports.getSmsPacketList = getSmsPacketList;
exports.getStreamPacketListApi = getStreamPacketListApi;
exports.getStreamRechargeOrderApi = getStreamRechargeOrderApi;
exports.getStreamUseRecordApi = getStreamUseRecordApi;
exports.getYeepayMerchantInfoApi = getYeepayMerchantInfoApi;
exports.getYeepayMerchantStatusApi = getYeepayMerchantStatusApi;
exports.hfTransferMoney = hfTransferMoney;
exports.llTransferMoney = llTransferMoney;
exports.nftAccountInfoApi = nftAccountInfoApi;
exports.payAccountCreateApi = payAccountCreateApi;
exports.payAccountDelApi = payAccountDelApi;
exports.payAccountEditApi = payAccountEditApi;
exports.payAccountListApi = payAccountListApi;
exports.paymentList = paymentList;
exports.reconciliationListApi = reconciliationListApi;
exports.reconciliationMarkApi = reconciliationMarkApi;
exports.reconciliationOrderApi = reconciliationOrderApi;
exports.reconciliationRefundApi = reconciliationRefundApi;
exports.reconciliationStatusApi = reconciliationStatusApi;
exports.refundOrderList = refundOrderList;
exports.refuseExtractApi = refuseExtractApi;
exports.sandtransferPay = sandtransferPay;
exports.setSendConfigApi = setSendConfigApi;
exports.streamInfo = streamInfo;
exports.tipsNumber = tipsNumber;
exports.unFrozenBondApi = unFrozenBondApi;
exports.uploadYeepayMerchantFileApi = uploadYeepayMerchantFileApi;
exports.userExtractList = userExtractList;
exports.userRechargeList = userRechargeList;
exports.userWalletExportApi = userWalletExportApi;
exports.userWalletFrozenList = userWalletFrozenList;
exports.userWalletHistoryList = userWalletHistoryList;
exports.userWalletList = userWalletList;
exports.walletReconciliationListApi = walletReconciliationListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 财务对账 -- 对账单列表
 */
function reconciliationListApi(data) {
  return _request.default.get("store/order/reconciliation/lst", data);
}

/**
 * @description 财务对账 -- 当前对账信息
 */
function getCurrentReconciliationInfoApi(data) {
  return _request.default.get("wallet/getCurrentReconciliationInfo", data);
}

/**
 * @description 财务对账 -- 对账单
 */
function walletReconciliationListApi(data) {
  return _request.default.get("wallet/reconciliationList", data);
}

/**
 * @description 财务对账 -- 确认对账
 */
function confirmReconciliationApi(data) {
  return _request.default.post("wallet/confirmReconciliation", data);
}

/**
 * @description 财务对账 -- 收支明细
 */
function getReconciliationPayOrderRecordApi(data) {
  return _request.default.get("wallet/getReconciliationPayOrderRecord", data);
}

/**
 * @description 财务对账 -- 确认打款
 */
function reconciliationStatusApi(id, data) {
  return _request.default.post("store/order/reconciliation/status/".concat(id), data);
}

/**
 * @description 财务对账 -- 查看订单
 */
function reconciliationOrderApi(id, data) {
  return _request.default.get("store/order/reconciliation/".concat(id, "/order"), data);
}

/**
 * @description 财务对账 -- 退款订单
 */
function reconciliationRefundApi(id, data) {
  return _request.default.get("store/order/reconciliation/".concat(id, "/refund"), data);
}

/**
 * @description 财务对账 -- 备注
 */
function reconciliationMarkApi(id) {
  return _request.default.get("store/order/reconciliation/mark/".concat(id, "/form"));
}
/**
 * @description 资金流水 -- 列表
 */
function capitalFlowLstApi(data) {
  return _request.default.get("financial_record/list", data);
}
/**
 * @description 资金流水 -- 导出
 */
function capitalFlowExportApi() {
  return _request.default.get("financial_record/export");
}

// 用户钱包
function userWalletList(data) {
  return _request.default.get('wallet/userWalletList', data);
}

// 用户钱包导出
function userWalletExportApi(data) {
  return _request.default.post('wallet/userWalletExport', data);
}

// 用户冻结金额列表
function userWalletFrozenList(data) {
  return _request.default.get('wallet/userWalletFrozenList', data);
}

// 用户退款列表
function userExtractList(data) {
  return _request.default.get('wallet/userExtractList', data);
}

// 用户充值列表
function userRechargeList(data) {
  return _request.default.get('wallet/userRechargeList', data);
}

// 余额页面数字提示
function tipsNumber() {
  return _request.default.get('wallet/tipsNumber');
}

// 收支明细
function userWalletHistoryList(data) {
  return _request.default.get('wallet/userWalletHistoryList', data);
}

// 确认线下支付
function confirmBankTransfer(data) {
  return _request.default.post('wallet/confirmBankTransfer', data);
}

// 取消线下支付
function cancelBankTransferApi(data) {
  return _request.default.post('finance/cancelBankTransfer', data);
}

// 收款单
function paymentList(data) {
  return _request.default.get('finance/paymentList', data);
}

// 付款单
function refundOrderList(data) {
  return _request.default.get('finance/refundOrderList', data);
}

// 确认收款单线下支付
function financeConfirmBankTransfer(data) {
  return _request.default.post('finance/confirmBankTransfer', data);
}

// 确认退款
function extractRechargeRefund(data) {
  return _request.default.post('wallet/extractRechargeRefund', data);
}

// 创建收款单
function createPayOrder(data) {
  return _request.default.post('finance/createPayOrder', data);
}

// 委托方提现退款
function extractRefundApi(data) {
  return _request.default.post('wallet/extractRefund', data);
}

// 委托方创建提现退款
function createEntrustExtractApi(data) {
  return _request.default.post('wallet/createEntrustExtract', data);
}

// 流量使用记录
function getStreamUseRecordApi(data) {
  return _request.default.get('live/stream/getStreamUseRecord', data);
}

// 流量充值记录
function getStreamRechargeOrderApi(data) {
  return _request.default.get('live/stream/getStreamRechargeOrder', data);
}

// 创建流量充值订单
function createRechargeOrderApi(data) {
  return _request.default.post('live/stream/createRechargeOrder', data);
}

// 流量充值包列表
function getStreamPacketListApi(data) {
  return _request.default.get('live/stream/getStreamPacketList', data);
}

// 流量详情
function streamInfo(data) {
  return _request.default.get('live/stream/info', data);
}

// 获取短信发送列表
function getSMSSendRecordApi(data) {
  return _request.default.get('system/sms/getSendRecord', data);
}

// 短信充值记录
function getSMSRechargeOrder(data) {
  return _request.default.get('system/sms/getRechargeOrder', data);
}

// 创建短信充值订单
function createSMSRechargeOrderApi(data) {
  return _request.default.post('system/sms/createRechargeOrder', data);
}

// 短信充值包列表
function getSmsPacketList(data) {
  return _request.default.get('system/sms/getSmsPacketList', data);
}

// 短信详情
function SMSInfo(data) {
  return _request.default.get('system/sms/accountInfo', data);
}

// 获取短信发送配置
function getSendConfigApi() {
  return _request.default.get('system/sms/getSendConfig');
}

// 设置短信配置
function setSendConfigApi(data) {
  return _request.default.post('system/sms/setSendConfig', data);
}

// 账户列表详情
function payAccountListApi(data) {
  return _request.default.get('finance/payAccount/list');
}

// 添加账户
function payAccountCreateApi(id, data) {
  return _request.default.post('finance/payAccount/create', data);
}

// 修改账户
function payAccountEditApi(id, data) {
  return _request.default.post("finance/payAccount/update/".concat(id), data);
}

// 删除账户
function payAccountDelApi(id) {
  return _request.default.post("finance/payAccount/del/".concat(id));
}

// nft使用详情
function nftAccountInfoApi(data) {
  return _request.default.get('nft/account/info', data);
}

// nft使用明细
function accountHistoryListApi(data) {
  return _request.default.get('nft/account/accountHistoryList', data);
}

// nft充值记录
function getNftRechargeOrderApi(data) {
  return _request.default.get('nft/account/getRechargeOrder', data);
}

// nft充值
function createNftRechargeOrderApi(data) {
  return _request.default.post('nft/account/createRechargeOrder', data);
}

// 解冻保证金
function unFrozenBondApi(data) {
  return _request.default.post('wallet/unFrozenBond', data);
}

// 首信易代付
function firstPayApi(data) {
  return _request.default.post('wallet/payEaseExtract', data);
}

// 拒绝提现
function refuseExtractApi(data) {
  return _request.default.post('wallet/extractRefused', data);
}

// 易宝特约商户入网
function createYeepayMerchantApi(data) {
  return _request.default.post('yeepay/registerSaasMerchantV2', data);
}

// 易宝特约商户文件上传
function uploadYeepayMerchantFileApi(data) {
  return _request.default.post('yeepay/merchantQualUpload', data);
}

// 易宝特约商户审核进度查询
function getYeepayMerchantStatusApi(data) {
  return _request.default.get('yeepay/registerQueryV2', data);
}
// 易宝特约商户提交信息查询
function getYeepayMerchantInfoApi(data) {
  return _request.default.get('yeepay/registerInfo', data);
}
// 杉德付款
function sandtransferPay(data) {
  return _request.default.post('sandpay/ceasElecTransCorpTransfer', data);
}
// 连连内部代发
function llTransferMoney(data) {
  return _request.default.post('llpay/mer/transferMoney', data);
}
// 汇付内部代发
function hfTransferMoney(data) {
  return _request.default.post('hfpay/platformAccountToWallet', data);
}