"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = _interopRequireWildcard(require("@/settings"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var title = _settings.default.title;
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      title: title,
      logo: JSON.parse(_jsCookie.default.get('MerInfo')).menu_logo,
      slogo: JSON.parse(_jsCookie.default.get('MerInfo')).menu_slogo
    };
  }
};
exports.default = _default;