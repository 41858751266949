"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    hasMoreData: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      auctionName: '',
      venueLoading: false,
      venue_id: '',
      album_id: '',
      venueList: [],
      albumList: []
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadAuction();
      }
      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      this.queryParams.venue_id = this.venue_id;
      this.queryParams.album_id = this.album_id;
      this.queryParams.keyword = this.auctionName;
      this.queryParams.page = 1;
      this.loadAuction();
    },
    loadAuction: function loadAuction(fun) {
      var _this = this;
      (0, _auction.auctionList)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.resultData = response.data.list;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.product.image + '!300',
        name: info.product.store_name,
        id: info.product_id
      };
      if (this.hasMoreData) {
        data.image = info.product.image + '!m640';
        data.attr_info = info.attr_info;
        data.store_info = info.product.store_info;
        data.content = info.content && info.content.content ? info.content.content : '';
        data.desc = '';
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'auction',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    searchVenueAction: function searchVenueAction(data) {
      var _this2 = this;
      var params = {
        keyword: data,
        venue_type: '',
        page: 1,
        limit: 10
      };
      (0, _auction.meetingList)(params).then(function (res) {
        _this2.venueList = res.data.list;
      });
    },
    searchAlbumAction: function searchAlbumAction(data) {
      var _this3 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this3.albumList = res.data.list;
      });
    },
    changeMeetingAction: function changeMeetingAction(e, noLoad) {
      var _this4 = this;
      this.album_id = '';
      var params = {
        venue_id: this.venue_id,
        page: 1,
        limit: 100
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this4.albumList = res.data.list;
      });
      if (!noLoad) {
        this.searchAAction();
      }
    }
  }
};
exports.default = _default2;