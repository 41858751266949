"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['currentData', 'content', 'facade'],
  data: function data() {
    return {
      list: [],
      lists: [{
        imgs: ['https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png', 'https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png', 'https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png', 'https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png'],
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tag: '酬金',
        time: '2021-01-02',
        price: '￥525.00',
        sign: '已解答',
        click_num: '5'
      }, {
        imgs: ['https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png', 'https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png', 'https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png', 'https://artimg2.artart.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png'],
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tag: '酬金',
        time: '2021-01-02',
        price: '￥525.00',
        sign: '已解答',
        click_num: '5'
      }]
    };
  },
  watch: {
    currentData: function currentData(val) {
      this.changeData(val);
    }
  },
  created: function created() {
    this.changeData(this.currentData);
  },
  methods: {
    changeData: function changeData(val) {
      if (val && val.length) {
        this.list = val;
      } else {
        this.list = this.lists;
      }
    },
    error: function error(event) {}
  }
};
exports.default = _default;