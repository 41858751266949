"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0,
      userName: ''
    };
  },
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.uid;
      });
      if (this.resultData.length === 0) {
        this.load();
      }
      this.showSelectAuction = true;
    },
    searchCAction: function searchCAction() {
      this.queryParams.uname = this.userName;
      this.queryParams.page = 1;
      this.load();
    },
    load: function load(fun) {
      var _this = this;
      var params = this.queryParams;
      var msg = this.$message.loading({
        render: function render(h) {
          return h('span', ['加载中...']);
        },
        duration: 0
      });
      this.$http.get('/artiest_list', {
        params: params
      }).then(function (res) {
        msg();
        if (res.code === 200) {
          _this.total = res.data.total;
          _this.resultData = res.data.data;
          _this.$nextTick(function () {
            // 赋值后马上更新
            if (fun) fun();
          });
        }
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.uid;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.avatar,
        name: info.uname,
        id: info.uid,
        uid: info.uid,
        tags: [],
        price: '',
        sign: ''
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.uid;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'artiest',
        data: this.selectedAuction
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.load();
    }
  }
};
exports.default = _default2;