var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "rubik" }, [
    _vm.size < 7
      ? _c("section", { staticClass: "advertising" }, [
          _vm.size == 1
            ? _c("section", { staticClass: "advertising-item-1" }, [
                _vm.adverArray[0].url
                  ? _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: _vm.adverArray[0].url, fit: "cover" },
                    })
                  : _c("img", {
                      staticStyle: { width: "750px", height: "750px" },
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                        fit: "cover",
                      },
                    }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 2
            ? _c("section", { staticClass: "advertising-item-2" }, [
                _vm.adverArray[0]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[0].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[0].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: { width: "375px", height: "375px" },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[1].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[1].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "375px",
                              height: "375px",
                              "border-left": "1px solid #fff",
                            },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 3
            ? _c("section", { staticClass: "advertising-item-3" }, [
                _vm.adverArray[0]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[0].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[0].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: { width: "250px", height: "250px" },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[1].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[1].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "250px",
                              height: "250px",
                              "border-left": "1px solid #fff",
                            },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[2].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[2].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "250px",
                              height: "250px",
                              "border-left": "1px solid #fff",
                            },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 4
            ? _c("section", { staticClass: "advertising-item-4" }, [
                _vm.adverArray[0]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "375px",
                          top: 0,
                          left: 0,
                        },
                      },
                      [
                        _vm.adverArray[0].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[0].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: {
                                width: "100%",
                                "border-right": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: 0,
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[1].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[1].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: "188px",
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[2].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[2].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: {
                                width: "100%",
                                "border-top": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 5
            ? _c("section", { staticClass: "advertising-item-5" }, [
                _vm.adverArray[0]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[0].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[0].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: { width: "100%", height: "188px" },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[1].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[1].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "188px",
                              "border-top": "1px solid #fff",
                              "border-left": "1px solid #fff",
                            },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[2].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[2].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: { width: "100%", height: "188px" },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[3]
                  ? _c("section", { staticClass: "advertising-item-image" }, [
                      _vm.adverArray[3].url
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.adverArray[3].url, fit: "cover" },
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "188px",
                              "border-top": "1px solid #fff",
                              "border-left": "1px solid #fff",
                            },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                              fit: "cover",
                            },
                          }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 6
            ? _c("section", { staticClass: "advertising-item-4" }, [
                _vm.adverArray[0]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: 0,
                          left: 0,
                        },
                      },
                      [
                        _vm.adverArray[0].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[0].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: "188px",
                          left: 0,
                        },
                      },
                      [
                        _vm.adverArray[1].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[1].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: 0,
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[2].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[2].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[3]
                  ? _c(
                      "section",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: "188px",
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[3].url
                          ? _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                src: _vm.adverArray[3].url,
                                fit: "cover",
                              },
                            })
                          : _c("img", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 7
      ? _c(
          "section",
          { staticClass: "rubik-cube" },
          [
            _vm._l(_vm.adverArray, function (item, index) {
              return [
                _c(
                  "section",
                  {
                    key: index,
                    staticClass: "rubik-cube__item",
                    style: {
                      width: _vm.getWidth(item) + "px",
                      height: _vm.getHeight(item) + "px",
                      top: _vm.getTop(item) + "px",
                      left: _vm.getLeft(item) + "px",
                    },
                  },
                  [
                    item.url
                      ? _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: { src: item.url, fit: "cover" },
                        })
                      : _c("img", {
                          staticStyle: {
                            width: "100%",
                            "border-top": "1px solid #fff",
                            "border-right": "1px solid #fff",
                          },
                          attrs: {
                            src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                            fit: "cover",
                          },
                        }),
                  ]
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }