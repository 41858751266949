"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalCoupon;
function modalCoupon(couponData, handle, couponId, keyNum, callback) {
  var _this = this;
  var h = this.$createElement;
  return new Promise(function (resolve, reject) {
    _this.$msgbox({
      title: '优惠券列表',
      customClass: 'upload-form-coupon',
      closeOnClickModal: false,
      showClose: false,
      message: h('div', {
        class: 'common-form-upload'
      }, [h('couponList', {
        props: {
          couponData: couponData,
          handle: handle,
          couponId: couponId,
          keyNum: keyNum
        },
        on: {
          getCouponId: function getCouponId(id) {
            callback(id);
          }
        }
      })]),
      showCancelButton: false,
      showConfirmButton: false
    }).then(function () {
      resolve();
    }).catch(function () {
      reject();
      _this.$message({
        type: 'info',
        message: '已取消'
      });
    });
  });
}