"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _open3d = require("@/api/open3d");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
      },
      btnLoading: false,
      showPay: false,
      payTitle: '',
      payImg: '',
      payPrice: ''
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _open3d.templateMarketListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    closePayAction: function closePayAction() {
      this.showPay = false;
      this.payTitle = '';
      this.payImg = '';
      this.payPrice = '';
    },
    buyEndAction: function buyEndAction() {
      this.showPay = false;
      this.payTitle = '';
      this.payImg = '';
      this.payPrice = '';
      this.getList();
    },
    payAction: function payAction(m) {
      var _this2 = this;
      if (this.btnLoading) return false;
      this.btnLoading = true;
      (0, _open3d.createRechargeOrderApi)({
        exhibition_template_id: m.exhibition_template_id
      }).then(function (res) {
        _this2.btnLoading = false;
        _this2.payImg = res.data.image;
        _this2.payPrice = m.price;
        _this2.payTitle = m.exhibition_title;
        _this2.showPay = true;
      }).catch(function (message) {
        _this2.btnLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};
exports.default = _default;