var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "拍品" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("手动选择拍品"),
                ]),
              ]),
              _vm._v(" "),
              _vm.content.data && _vm.content.data.length > 0
                ? _c(
                    "div",
                    { staticClass: "pt10" },
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center mt10",
                        },
                        [
                          _c("div", { staticClass: "flex-one ft12" }, [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticStyle: { "font-size": "12px" },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt10" },
                              [
                                _c("el-input", {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { type: "textarea", rows: "4" },
                                  model: {
                                    value: item.desc,
                                    callback: function ($$v) {
                                      _vm.$set(item, "desc", $$v)
                                    },
                                    expression: "item.desc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-down-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDown(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-up-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUp(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://artimg2.artart.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAdditem },
                  },
                  [_vm._v("选择拍品")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "排版" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "item-align-center",
                      staticStyle: { height: "30px" },
                      model: {
                        value: _vm.facade.img_position,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "img_position", $$v)
                        },
                        expression: "facade.img_position",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "center" },
                        },
                        [_c("div", [_vm._v("默认")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "right" },
                        },
                        [_c("div", [_vm._v("图片居右")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "left" },
                        },
                        [_c("div", [_vm._v("图片居左")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "主题颜色" } },
                [
                  _c("div", { staticClass: "flex flex-wrap" }, [
                    _c("div", {
                      staticClass: "color",
                      class: { curr: _vm.facade.theme == 1 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(1)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color1",
                      class: { curr: _vm.facade.theme == 2 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(2)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color2",
                      class: { curr: _vm.facade.theme == 3 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(3)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color3",
                      class: { curr: _vm.facade.theme == 4 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(4)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color4",
                      class: { curr: _vm.facade.theme == 5 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(5)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color5",
                      class: { curr: _vm.facade.theme == 6 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(6)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color6",
                      class: { curr: _vm.facade.theme == 7 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(7)
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_margin", $$v)
                      },
                      expression: "facade.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_margin", $$v)
                      },
                      expression: "facade.item_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.item_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_background", $$v)
                      },
                      expression: "facade.item_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_padding", $$v)
                      },
                      expression: "facade.item_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "radius", $$v)
                      },
                      expression: "facade.radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_margin", $$v)
                      },
                      expression: "facade.title_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_color", $$v)
                      },
                      expression: "facade.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_size", $$v)
                      },
                      expression: "facade.title_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_weight", $$v)
                        },
                        expression: "facade.title_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_text_align", $$v)
                        },
                        expression: "facade.title_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_margin", $$v)
                      },
                      expression: "facade.desc_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.desc_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_color", $$v)
                      },
                      expression: "facade.desc_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_size", $$v)
                      },
                      expression: "facade.desc_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_line_height", $$v)
                      },
                      expression: "facade.desc_line_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.desc_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "desc_weight", $$v)
                        },
                        expression: "facade.desc_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.desc_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "desc_text_align", $$v)
                        },
                        expression: "facade.desc_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "has-more-data": "1", "current-data": _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }