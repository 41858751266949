"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _default = {
  namespaced: true,
  state: {
    store_setting: {},
    basic_setting: {},
    goods_setting: {},
    trade_setting: {},
    store_storage: {},
    user_setting: {},
    copyright_information: {},
    version: null,
    auth: false,
    isAuth: false
  },
  mutations: {
    setStore: function setStore(state, data) {
      state.store_setting = data;
    },
    setStorage: function setStorage(state, data) {
      state.store_storage = data;
    },
    setBasic: function setBasic(state, data) {
      state.basic_setting = data;
    },
    setGoods: function setGoods(state, data) {
      state.goods_setting = data;
    },
    setUser: function setUser(state, data) {
      state.user_setting = data;
    },
    setTrade: function setTrade(state, data) {
      state.trade_setting = data;
    },
    setCopyright: function setCopyright(state, data) {
      state.copyright_information = data;
    },
    setIsAuth: function setIsAuth(state, data) {
      state.isAuth = data;
    }
  },
  getters: {
    getStore: function getStore(state) {
      return state.store_setting;
    },
    getBasic: function getBasic(state) {
      return state.basic_setting;
    },
    getStorage: function getStorage(state) {
      return state.store_storage;
    },
    getGoods: function getGoods(state) {
      return state.goods_setting;
    },
    getUser: function getUser(state) {
      return state.user_setting;
    },
    getTrade: function getTrade(state) {
      return state.trade_setting;
    },
    getVersion: function getVersion(state) {
      return state.version;
    },
    getAuth: function getAuth(state) {
      return state.auth;
    },
    getCopyright: function getCopyright(state) {
      return state.copyright_information;
    }
  },
  actions: {
    getSettingInfo: function getSettingInfo(_ref) {
      var commit = _ref.commit;
      var $heshop = this._vm.$heshop;
      new Promise(function (resolve, reject) {
        $heshop.search('post', {
          include: 'setting'
        }, {
          keyword: 'setting_collection'
        }).then(function (res) {
          var _res$content = res.content,
            basic_setting = _res$content.basic_setting,
            goods_setting = _res$content.goods_setting,
            store_setting = _res$content.store_setting,
            trade_setting = _res$content.trade_setting,
            user_setting = _res$content.user_setting;
          if (!store_setting.logo) {
            store_setting.logo = 'https://qmxq.oss-cn-hangzhou.aliyuncs.com/home.png';
          }
          commit('setStore', store_setting);
          commit('setBasic', basic_setting);
          commit('setGoods', goods_setting);
          commit('setUser', user_setting);
          commit('setTrade', trade_setting);
        });
      });
      new Promise(function (resolve, reject) {
        $heshop.search('post', {
          include: 'setting'
        }, {
          keyword: 'storage_limit'
        }).then(function (res) {
          if (res) {
            commit('setStorage', res.content);
          }
        });
      });
      new Promise(function (resolve, reject) {
        $heshop.search('post', {
          include: 'setting'
        }, {
          keyword: 'copyright_information'
        }).then(function (res) {
          if (res) {
            commit('setCopyright', res.content);
          }
        });
      });
    },
    getVersion: function getVersion(_ref2) {
      var state = _ref2.state,
        commit = _ref2.commit;
      var $heshop = this._vm.$heshop;
      $heshop.cloud('get').then(function (res) {
        state.version = res.version.next_version;
        state.auth = res.auth;
        commit('setIsAuth', true);
      }).catch(function (err) {
        console.error(err);
      });
    }
  }
};
exports.default = _default;