var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "div",
              {
                staticClass: "banner-img",
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  background: _vm.facade.background,
                  width: _vm.facade.view_width / 2 + "px",
                  height: _vm.facade.view_height / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img",
                    style: {
                      width: _vm.facade.img_width / 2 + "px",
                      height: _vm.facade.img_height / 2 + "px",
                      borderRadius:
                        _vm.facade.img_top_radius / 2 +
                        "px " +
                        _vm.facade.img_top_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px;",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        display: "block",
                      },
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                        fit: _vm.facade.img_style === 2 ? "contain" : "cover",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pointer", {
                  attrs: {
                    color: _vm.facade.indicator_color,
                    type: _vm.facade.indicator_style,
                    align: _vm.facade.indicator_align,
                    "v-align": _vm.facade.indicator_vertical_align,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c justify-b",
                style: {
                  height: _vm.facade.height / 2 + "px",
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  fontSize: _vm.facade.fontSize / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "time1 flex align-items-c ft14",
                    style: { color: _vm.facade.timeColor },
                  },
                  [
                    _c("auctionStatus", {
                      attrs: {
                        status: _vm.facade.status_type,
                        type: 0,
                        sty: "display: inline-block;position: relative; top: 0;margin-right: 5px;",
                      },
                    }),
                    _vm._v(" "),
                    _vm.facade.time_type == 1
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "bg",
                              style: {
                                background: _vm.facade.bgBtnColor,
                                color: _vm.facade.bgColor,
                                fontWeight: _vm.facade.fontWeight,
                              },
                            },
                            [_vm._v("12")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v("时")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bg",
                              style: {
                                background: _vm.facade.bgBtnColor,
                                color: _vm.facade.bgColor,
                                fontWeight: _vm.facade.fontWeight,
                              },
                            },
                            [_vm._v("23")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v("分")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bg",
                              style: {
                                background: _vm.facade.bgBtnColor,
                                color: _vm.facade.bgColor,
                                fontWeight: _vm.facade.fontWeight,
                              },
                            },
                            [_vm._v("20")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml5",
                              style: { fontWeight: _vm.facade.fontWeight },
                            },
                            [_vm._v("开拍")]
                          ),
                        ]
                      : _vm.facade.time_type == 2
                      ? [
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.facade.activeColor,
                                  fontWeight: _vm.facade.fontWeight,
                                },
                              },
                              [_vm._v("12")]
                            ),
                            _vm._v("时"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.facade.activeColor,
                                  fontWeight: _vm.facade.fontWeight,
                                },
                              },
                              [_vm._v("23")]
                            ),
                            _vm._v("分"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.facade.activeColor,
                                  fontWeight: _vm.facade.fontWeight,
                                },
                              },
                              [_vm._v("20")]
                            ),
                            _vm._v("秒"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml5",
                              style: { fontWeight: _vm.facade.fontWeight },
                            },
                            [_vm._v("开拍")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "r-jd-btn flex align-items-c justify-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20220319/89e60d9d4628815443da91be244552b3.png",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("开拍提醒")]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        如北京故宫博物院以2200万元人民国内众多的博物馆（院）和实力雄厚的财团\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "div",
                  {
                    class: { line1: _vm.facade.fold },
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        国内众多的博物馆（院）和实力雄厚的财团，通过拍卖国内众多的博物馆（院）和实力雄厚的财团，通过拍卖\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "price"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 20px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c("div", { staticClass: "flex align-items-c" }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        fontWeight: _vm.facade.fontWeight,
                      },
                    },
                    [
                      _c("span", [_vm._v("当前价")]),
                      _c(
                        "span",
                        {
                          staticClass: "price-family ml5 mr5",
                          style: {
                            fontSize: _vm.facade.priceFontSize / 2 + "px",
                            color: _vm.facade.priceColor,
                            fontWeight: 500,
                          },
                        },
                        [_vm._v("9000")]
                      ),
                      _c("span", [_vm._v("元")]),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      lineHeight: _vm.pageInfo.tLineHeight / 2 + "px",
                      fontSize: _vm.pageInfo.tFontSize / 2 + "px",
                      color: _vm.pageInfo.tColor,
                      fontWeight: _vm.pageInfo.tFontWeight,
                    },
                  },
                  [_vm._v("本场规则")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _c(
                    "div",
                    {
                      staticClass: "content",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        lineHeight: _vm.facade.lineHeight / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "params flex align-items-c" }, [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.fontWeight },
                          },
                          [_vm._v("保证金")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "r flex align-items-c justify-b flex-one",
                            style: { color: _vm.facade.otherColor },
                          },
                          [
                            _c("div", [_vm._v("1525元")]),
                            _vm._v(" "),
                            _vm._m(0),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "params flex align-items-c" }, [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.fontWeight },
                          },
                          [_vm._v("佣金")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r",
                            style: { color: _vm.facade.otherColor },
                          },
                          [_vm._v("13%（2021年12月14日以后15%）")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "border-bottom": "1px solid #EAECED" },
                    style: {
                      lineHeight: _vm.pageInfo.tLineHeight / 2 + "px",
                      fontSize: _vm.pageInfo.tFontSize / 2 + "px",
                      color: _vm.pageInfo.tColor,
                      fontWeight: _vm.pageInfo.tFontWeight,
                    },
                  },
                  [_vm._v("拍品详情")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      fontSize: _vm.facade.attrFontSize / 2 + "px",
                      color: _vm.facade.attrColor,
                      lineHeight: _vm.facade.attrLineHeight / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "params flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l",
                          style: { fontWeight: _vm.facade.attrFontWeight },
                        },
                        [_vm._v("拍卖专场")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r",
                          style: { color: _vm.facade.attrOtherColor },
                        },
                        [_vm._v("共23件 艺天下文化专场")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "params flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l",
                          style: { fontWeight: _vm.facade.attrFontWeight },
                        },
                        [_vm._v("本场保证金")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r",
                          style: { color: _vm.facade.attrOtherColor },
                        },
                        [_vm._v("1:10（充值1元可出价10元）")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "params flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l",
                          style: { fontWeight: _vm.facade.attrFontWeight },
                        },
                        [_vm._v("加价幅度")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r",
                          style: { color: _vm.facade.attrOtherColor },
                        },
                        [_vm._v("1,000元")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "params flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l",
                          style: { fontWeight: _vm.facade.attrFontWeight },
                        },
                        [_vm._v("预展时间")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r",
                          style: { color: _vm.facade.attrOtherColor },
                        },
                        [_vm._v("2022年3月18日 至 20日")]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      lineHeight: _vm.facade.lineHeight / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ft14 fWeight500 color-black",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [_vm._v("详情介绍")]
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v("年代：清")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("尺寸：26cm×6cm")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("材质：寿山石")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "描述：1022g。寿山石材质国内众多的博物馆（院）和实力雄厚的财团，通过拍卖公司从国内外市场上购回了许多以前通过各种形式、各种途径流失出去的古玩珍品，如北京故宫博物院以2200万元人民币在拍卖。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(" ")]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "寿山石材质国内众多的博物馆（院）和实力雄厚的财团，通过拍卖公司从国内外市场上购回了许多以前通过各种形式、各种途径流失出去的古玩珍品，如北京故宫博物院以2200万元人民币在拍卖行购。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(" ")]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "bid_record"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      lineHeight: _vm.pageInfo.tLineHeight / 2 + "px",
                      fontSize: _vm.pageInfo.tFontSize / 2 + "px",
                      color: _vm.pageInfo.tColor,
                      fontWeight: _vm.pageInfo.tFontWeight,
                    },
                  },
                  [_vm._v("竞拍记录（12）")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bid-record" }, [
                  _c("div", { staticClass: "item flex align-items-c" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "c flex-one" }, [
                      _c(
                        "div",
                        {
                          staticClass: "name line1",
                          style: {
                            fontSize: _vm.facade.fontSize / 2 + "px",
                            color: _vm.facade.color,
                            fontWeight: _vm.facade.fontWeight,
                          },
                        },
                        [_vm._v("1088号")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "time color-gray ft12" }, [
                        _vm._v("202-02-03 13:12:13"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price ft12 ml10",
                        style: { color: _vm.facade.priceColor },
                      },
                      [
                        _vm._v("￥"),
                        _c(
                          "span",
                          {
                            style: {
                              fontSize: _vm.facade.priceFontSize / 2 + "px",
                              fontWeight: _vm.facade.priceFontWeight,
                            },
                          },
                          [_vm._v("2522")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(3),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item flex align-items-c" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "c flex-one" }, [
                      _c(
                        "div",
                        {
                          staticClass: "name line1",
                          style: {
                            fontSize: _vm.facade.fontSize / 2 + "px",
                            color: _vm.facade.color,
                            fontWeight: _vm.facade.fontWeight,
                          },
                        },
                        [_vm._v("比基尼小熊")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "time color-gray ft12" }, [
                        _vm._v("202-02-03 13:12:00"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price ft12 ml10",
                        style: { color: _vm.facade.priceColor },
                      },
                      [
                        _vm._v("￥"),
                        _c(
                          "span",
                          {
                            style: {
                              fontSize: _vm.facade.priceFontSize / 2 + "px",
                              fontWeight: _vm.facade.priceFontWeight,
                            },
                          },
                          [_vm._v("1522")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(5),
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      lineHeight: _vm.pageInfo.tLineHeight / 2 + "px",
                      fontSize: _vm.pageInfo.tFontSize / 2 + "px",
                      color: _vm.pageInfo.tColor,
                      fontWeight: _vm.pageInfo.tFontWeight,
                    },
                  },
                  [_vm._v("相关拍品")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list",
                    style: {
                      margin: "0 -" + _vm.facade.left_margin / 2 + "px",
                    },
                  },
                  [
                    _c("auctionList", {
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { facade: _vm.facade, content: _vm.content },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "link" }, [_vm._v("竞价阶梯")]),
      _vm._v(" "),
      _c("span", { staticClass: "link" }, [_vm._v("参拍规则")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: {
          src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "l" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src: "https://artimg2.artart.cn/image/20210617/a26bbd8f9f78c7f5ce40aee0e00d7a28.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "r" }, [
      _c("img", {
        attrs: {
          src: "https://artimg2.artart.cn/image/20220215/2aa247554d36813b8bbac4c9c7314ad7.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "l" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src: "https://img.artart.cn/wechat_head_avatar/359dbd6c473a055aff3b217cb203769461384009d1e00132.jpg",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "r" }, [
      _c("img", {
        attrs: {
          src: "https://artimg2.artart.cn/image/20220215/6291a1763511f2413545cab11b90def0.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "show flex align-items-c justify-c ft12" },
      [
        _c("div", [_vm._v("查看全部出价")]),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: "https://artimg2.artart.cn/image/20220109/997f63f87c898a4bdd3ab75043ab3d2d.png",
            alt: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }