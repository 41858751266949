"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    facade: {
      type: [Object, Array]
    },
    content: {
      type: [Object, Array]
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    cover: function cover() {
      if (this.content.cover) {
        return this.content.cover;
      }
      if (this.content.video && this.content.video.cover) {
        return this.content.video.cover;
      }
      return '';
    }
  }
};
exports.default = _default;