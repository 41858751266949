"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _open3d = require("@/api/open3d");
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    colour: _colour.default
  },
  data: function data() {
    return {
      ruleForm: {
        space_name: "",
        street_name: "",
        street_num: 100,
        heat: {
          scan: 1,
          like: 1,
          share: 1
        },
        load_type: 1,
        load_img_vertical: "",
        load_img_horizontal: "",
        word_color: "",
        progress_bar_color: "",
        land_background: "#000",
        land_icon1: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617881130990192.png",
        land_icon2: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617880730368811.png",
        land_icon3: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617881366953454.png",
        land_icon4: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617881646885609.png",
        land_img1: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617881916792136.png",
        land_img2: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617882455945948.png",
        land_img3: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617882703451522.png",
        land_img4: "https://artimg2.artart.cn/merchant/333425/2023217/image/1676617882184431999.png"
      },
      showAddNum: false,
      addNum: ""
    };
  },
  created: function created() {
    var _this = this;
    (0, _open3d.getSpaceInfoApi)().then(function (res) {
      if (res.data.id) {
        _this.ruleForm = res.data;
      }
    });
  },
  methods: {
    showAddNumAction: function showAddNumAction() {
      this.addNum = "";
      this.showAddNum = true;
    },
    saveAddNumAction: function saveAddNumAction() {
      if (!this.addNum || isNaN(this.addNum)) return false;
      this.showAddNum = false;
      this.ruleForm.street_num += this.addNum;
    },
    changeImg: function changeImg(key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        console.log(img);
        _this2.ruleForm[key] = img[0];
      }, 1, "1");
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (!this.ruleForm.space_name) {
        this.$message.error("请输入空间名称");
        return false;
      }
      if (!this.ruleForm.street_name) {
        this.$message.error("请输入街区名称");
        return false;
      }
      if (!this.ruleForm.heat.scan) {
        this.ruleForm.heat.scan = 1;
      }
      if (!this.ruleForm.heat.like) {
        this.ruleForm.heat.like = 1;
      }
      if (!this.ruleForm.heat.share) {
        this.ruleForm.heat.share = 1;
      }
      if (this.lock) return false;
      this.lock = false;
      var data = JSON.parse(JSON.stringify(this.ruleForm));
      var action = _open3d.spaceCreateInfoApi;
      if (data.id) {
        action = _open3d.spaceSetInfoApi;
      }
      action(data).then(function (res) {
        _this3.$message.success("操作成功");
      }).catch(function () {});
    },
    downloadTemplate: function downloadTemplate() {
      window.open("https://art2.oss-cn-shanghai.aliyuncs.com/download/block_image.zip");
    }
  }
};
exports.default = _default;