"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  }
};
exports.default = _default;