"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = {
  basics: {
    title: '通用组件',
    children: [{
      name: 'nav',
      // 标签name
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/navigation_icon.png',
      title: '图文导航',
      // 标签名称
      content: {
        style: 1,
        // 风格类型
        jump_type: 1,
        // 跳转方式
        data: [
        // 数据
        {
          url: '',
          title: '导航一',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '导航二',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '导航三',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '导航四',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }]
      },
      // 外观
      facade: {
        nav_style: 1,
        // 展示方式 1 居左 居中
        fixed_top: false,
        // 页面跟随  false 不居顶  true 居顶
        background_color: '',
        // 背景颜色
        text_color: '#000000',
        // 文字颜色
        text_style: 400,
        // 文字风格 400常规  500  加粗
        text_size: 28,
        // 字号
        page_padding: 40,
        // 页面边距
        item_padding: 40,
        // 容器边距
        img_width: 120,
        // 图片宽度
        img_height: 120,
        // 图片高度
        is_hover: false,
        // 是否开启选中模式
        hover_index: 1,
        // 开启选中模式后   选中的下标索引
        hover_color: '#003D7C' // 开启选中模式后  选中的颜色
      }
    }, {
      name: 'tag',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/tab_icon.png',
      title: '选项卡',
      content: {
        data: [{
          name: '',
          title: '文字',
          link: {
            title: ''
          }
        }, {
          name: '',
          title: '文字',
          link: {
            title: ''
          }
        }, {
          name: '',
          title: '文字',
          link: {
            title: ''
          }
        }],
        active: 0,
        loadMore: false // 无限加载
      },

      // 外观
      facade: {
        nav_style: 1,
        // 展示方式 1 居左 居中
        fixed_top: false,
        // 页面跟随  false 不居顶  true 居顶
        background_color: '',
        // 背景颜色
        text_color: '#000000',
        // 文字颜色
        text_active_color: '#003D7c',
        // 文字选中颜色
        text_style: 400,
        // 文字风格 1常规  2  加粗
        text_size: 28,
        // 字号
        page_padding: 40,
        // 页面边距
        item_padding: 40,
        // 容器边距
        content_margin: 20,
        // 页面与内容距离
        nav_height: 96,
        // 选项卡高度
        text_margin_top: 20,
        // 文字距上距离
        lineHeight: 40,
        active_text_style: 500,
        active_text_size: 28
      }
    }, {
      name: 'banner',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/banner_icon.png',
      title: '轮播图',
      content: {
        style: 1,
        // 风格模板选择  1  单张滑动  2 左右露出  3 右侧露出
        view_width: 750,
        // 轮播图宽
        view_height: 750,
        // 轮播图高
        data: [{
          url: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }]
      },
      // 外观
      facade: {
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        background_color: '',
        // 背景颜色
        img_radius: 0,
        // 图上圆角
        img_bottom_radius: 0,
        // 图下圆角
        page_padding: 0,
        // 页面边距
        item_padding: 0,
        // 容器边距
        chamfer_style: 1,
        // 倒角样式
        indicator_style: 2,
        // 指示器样式  1 远点  2 长条   3 数字
        indicator_vertical_align: 'top',
        // 指示器上下位置
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#dd0000' // 指示器颜色
      }
    }, {
      name: 'title',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/mokuai_icon.png',
      title: '标题栏',
      content: {
        style: 1,
        // 标题栏模板id
        title: '标题文字',
        // 标题栏主文字
        subtitle: '二级标题',
        // 标题栏附文字
        is_more: true,
        // 是否展示更多
        moretitle: '更多',
        // 更多的文字
        morelink: {
          link: '',
          name: '',
          name1: ''
        },
        // 更多的链接
        title_img: '' // 标题图片
      },

      // 外观
      facade: {
        title_color: '#000000',
        // 文字颜色
        title_font_size: 40,
        // 文字字号
        subtitle_color: '#999999',
        // 副标题文字颜色
        subtitle_font_size: 24,
        // 副标题字号
        more_color: '#000000',
        // 更多文字颜色
        more_font_size: 24,
        // 更多文字字号
        more_img: 1,
        // 更多箭头图标 1 黑色 2 白色
        background_color: '',
        // 背景颜色
        page_padding: 40 // 页面边距
      }
    }, {
      name: 'search',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/search_icon.png',
      title: '搜索框',
      content: {
        style: 1,
        // 搜索栏风格模板id
        text: '搜索',
        front_icon: '',
        // 前图标
        front_link: {
          link: '',
          name: '',
          name1: ''
        },
        // 前图标跳转链接
        after_icon: '',
        // 后图标
        after_link: {
          link: '',
          name: '',
          name1: ''
        },
        // 后图标跳转链接
        data: [{
          url: '',
          title: '文字链接',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '文字链接',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '文字链接',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }] // 文字链接
      },

      // 外观
      facade: {
        page_padding: 16,
        // 页面边距
        background_color: '',
        // 背景颜色
        border_color: '#f5f5f5',
        // 输入框颜色
        text_color: '#999999',
        // 文字颜色
        text_style: 400,
        // 文字类型 300 加细  400正常  500 加粗
        text_size: 24,
        // 文字字号
        icon_align: 'right',
        // 搜索图标位置
        icon_color: '#999999',
        // icon颜色
        border_radius: 32,
        // 圆角角度
        high_style: 64,
        // 搜索框高度
        width_style: 560,
        // 搜索框宽度
        text_link_color: '#999999',
        // 文字链 文字颜色
        text_link_size: 24,
        // 文字字号
        text_link_style: 400 // 文字类型 300 加细  400正常  500 加粗
      }
    }, {
      name: 'blank',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/kongge_icon.png',
      title: '分割',
      content: {
        has_line: false // 是否开启线条模式
      },

      // 外观
      facade: {
        height: 20,
        // 分割高度
        background_color: '',
        // 分割的背景颜色
        line_width: 375,
        // 线的宽度
        line_height: 1,
        // 线的高度
        line_color: '#DDDDDD' // 线的颜色
      }
    }, {
      name: 'rubik',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/image_icon.png',
      title: '图片',
      content: {
        style: 1,
        // 风格 1  2  3  4  5  6
        density: 4,
        // 魔方密度
        data: [{
          url: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }]
      },
      facade: {
        page_margin: 0 // 页面边距
      }
    }, {
      name: 'video',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/video_icon.png',
      title: '视频',
      content: {
        video: {},
        cover: '',
        autoplay: false,
        loop: true,
        source: 0,
        address: '',
        view_width: 750,
        view_height: 422
      }
    }, {
      name: 'notice',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/gonggao_icon.png',
      title: '公告',
      content: {
        title: '',
        icon: 'https://artimg2.artart.cn/image/20211022/28c84b92d651186d4bf97a62d8cd0400.png',
        data: [],
        right_link: {
          link: '',
          name: '',
          name1: ''
        },
        right_text: '',
        right_icon: ''
      },
      // 外观
      facade: {
        title_color: '#d19974',
        // 文字颜色
        speed: 80,
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        background_color: '#fff8f0',
        // 背景颜色
        roll_type: 1,
        // 滚动方式  1横向  2竖向
        page_margin: 0,
        radius: 0,
        item_shadow: '',
        // 阴影颜色
        item_shadow_size: 0,
        // 阴影大小
        right_title_color: '',
        right_padding: 0
      }
    }, {
      name: 'textView',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/mokuai_icon.png',
      title: '文字',
      content: {
        title: '标题文字',
        subtitle: '内容文字'
      },
      // 外观
      facade: {
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 30,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 30,
        // 内间距
        radius: 8,
        // 内容圆角
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        title_margin: 20,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 28,
        title_text_align: 'left',
        desc_margin: 30,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 24,
        desc_line_height: 40,
        desc_text_align: 'left'
      }
    }]
  },
  advanced: {
    title: '内容组件',
    children: [{
      name: 'auctionMeeting',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_meeting_icon.png',
      title: '拍卖会',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        // 背景颜色
        column_title_font_size: 32,
        // 标题文字字号
        item_margin: 30,
        // 专场间距
        item_background: '',
        // 专场背景颜色
        name_wrap: false,
        // 专场名字是否换行   不换行就是超出一行显示...
        indicator_style: 2,
        // 滑动指示器样式  1 圆点  2 横条  3 数字
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#003D7C',
        // 指示器颜色
        page_margin: 30,
        // 页面间距
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#000000',
        // 副标题颜色
        detail_color: '#999999',
        // 详细介绍颜色
        status_bar_bg: '#003D7C',
        // 状态栏背景色
        status_bar_color: 'white',
        // 状态栏文字颜色   white  black 只有这俩
        status_bar_height: 60,
        // 状态栏高度
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        top_radius: 8,
        // 专场上圆角
        bottom_radius: 8,
        // 转下下圆角
        meeting_padding: 16,
        // 拍卖会间距  content.style 1 使用的
        meeting_padding1: 48,
        // 拍卖会间距  content.style 2 使用的
        price_color: '#003D7C',
        // 价格颜色
        name_color: '#000000',
        // 名称颜色
        name_font_size: 32,
        // 名称字号
        meeting_title_padding: 30 // 拍卖会右下左挤开距离
      }
    }, {
      name: 'auctionAlbum',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_album_icon.png',
      title: '专场',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        open_live_type: false,
        // 直播筛选  true 开启直播筛选  false 不开启直播筛选
        live_type: 1,
        // 1 只展示直播专场  2 不展示直播专场
        album_type: [1],
        // 专场类型  1限时拍  3同步拍  2 社群拍
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        column_title_font_size: 32,
        // 标题文字字号
        item_margin: 30,
        // 专场间距
        item_background: '',
        // 专场背景颜色
        name_wrap: false,
        // 专场名字是否换行   不换行就是超出一行显示...
        indicator_style: 2,
        // 滑动指示器样式  1 圆点  2 横条  3 数字
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#003D7C',
        // 指示器颜色
        page_margin: 30,
        // 页面间距
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#000000',
        // 副标题颜色
        detail_color: '#999999',
        // 详细介绍颜色
        status_bar_bg: '#003D7C',
        // 状态栏背景色
        status_bar_color: 'white',
        // 状态栏文字颜色   white  black 只有这俩
        status_bar_height: 60,
        // 状态栏高度
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        top_radius: 8,
        // 专场上圆角
        bottom_radius: 8 // 转下下圆角
      }
    }, {
      name: 'auction',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_icon.png',
      title: '拍品',
      hasContent: true,
      content: {
        background_color: '',
        style: 2,
        // 风格模板选择 1 单列展示 2 双列展示  3三列模式   4列表模式  5左右滑动
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        open_live_type: false,
        // 直播筛选  true 开启直播筛选  false 不开启直播筛选
        live_type: 1,
        // 1 只展示直播专场  2 不展示直播专场
        album_type: [1],
        // 专场类型  1限时拍  2同步拍  3 社群拍
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        page_margin: 30,
        // 页面间距
        top_radius: 8,
        // 顶部圆角
        bottom_radius: 8,
        // 底部圆角
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#999999',
        // 副标题颜色
        price_color: '#347FF4',
        // 价格颜色
        bid_num_color: '#000000',
        // 出价次数颜色
        status_type: 1,
        // 状态样式
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        btn_bg_color: '#003D7C',
        // 按钮背景
        btn_color: '#FFFFFF',
        // 按钮文字颜色
        btn_radius: 8,
        // 按钮圆角
        top_margin: 20,
        // 内容上间距
        left_margin: 20,
        // 内容左间距
        text_margin: 20,
        // 文件撑开距离
        item_background: '#FFFFFF',
        // 拍品底色
        item_shadow: '#DEDEDE',
        // 拍品阴影颜色
        item_shadow_size: 10,
        // 拍品阴影大小
        title_size: 28,
        // 标题颜色
        title_weight: 500,
        // 标题文字类型 300 加细  400正常  500 加粗
        price_size: 28,
        // 标题价格字号
        price_weight: 500,
        // 标题价格文字类型 300 加细  400正常  500 加粗
        bid_num_size: 24,
        // 标题出价次数字号
        bid_num_weight: 400 // 标题出价次数 300 加细  400正常  500 加粗
      }
    }, {
      name: 'word',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/article_icon.png',
      title: '文章',
      hasContent: true,
      content: {
        style: 2,
        // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动 6 多图模式
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        view_radius: 8,
        // 顶部圆角 多图模式
        img_radius: 8,
        // 图片圆角
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        status_background: '#FFFFFF',
        status_high: 60,
        status_radius: 8,
        item_bottom_padding: 30,
        price_color: '#003D9C',
        no_show_img: false,
        name_color: '#000000',
        time_color: '#999999',
        img_width: 120,
        img_height: 120
      }
    }, {
      name: 'nftCollection',
      icon: 'https://artimg2.artart.cn/image/20220529/a996c1310ffc35bd8fee696fd56cfce8.png',
      title: '数字藏品',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333',
        showStock: false
      }
    }, {
      name: 'nftCollectionMarket',
      icon: 'https://artimg2.artart.cn/image/20220529/a996c1310ffc35bd8fee696fd56cfce8.png',
      title: '藏品寄卖',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333',
        showStock: false
      }
    }, {
      name: 'nftCollectionAlbum',
      icon: 'https://artimg2.artart.cn/image/20220529/a996c1310ffc35bd8fee696fd56cfce8.png',
      title: '藏品系列',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333'
      }
    }, {
      name: 'nftBlindBox',
      icon: 'https://artimg2.artart.cn/image/20220529/5a730f1f1a514ba6dda3df46b61a9db1.png',
      title: '数字盲盒',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333',
        showStock: false
      }
    }, {
      name: 'blindBox',
      icon: 'https://artimg2.artart.cn/image/20220529/5a730f1f1a514ba6dda3df46b61a9db1.png',
      title: '艺术盲盒',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333'
      }
    }, {
      name: 'product',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/product_icon.png',
      title: '商品',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        img_style: 1,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        open_live_type: false,
        // 直播筛选  true 开启直播筛选  false 不开启直播筛选
        live_type: 1,
        // 1 只展示直播专场  2 不展示直播专场
        album_type: [1],
        // 专场类型  1限时拍  2同步拍  3 社群拍
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        page_margin: 30,
        // 页面间距
        top_radius: 8,
        // 顶部圆角
        bottom_radius: 8,
        // 底部圆角
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#999999',
        // 副标题颜色
        price_color: '#347FF4',
        // 价格颜色
        sales_volumes_color: '#999999',
        // 销量颜色
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        top_margin: 20,
        // 内容上间距
        left_margin: 20,
        // 内容左间距
        text_margin: 20,
        // 文件撑开距离
        item_background: '#FFFFFF',
        // 拍品底色
        item_shadow: '#DEDEDE',
        // 拍品阴影颜色
        item_shadow_size: 10,
        // 拍品阴影大小
        title_size: 28,
        // 标题颜色
        title_weight: 500,
        // 标题文字类型 300 加细  400正常  500 加粗
        price_size: 28,
        // 标题价格字号
        price_weight: 500,
        // 标题价格文字类型 300 加细  400正常  500 加粗
        sales_volumes_size: 24,
        // 销量次数字号
        sales_volumes_weight: 400,
        // 销量次数 300 加细  400正常  500 加粗
        img_width: 120,
        img_height: 120,
        show_sale: true
      }
    }, {
      name: 'live',
      icon: 'https://artimg2.artart.cn/image/20220529/02eb703a869e5fdd6f0d401841b0cd65.png',
      title: '直播',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 直播中+直播预告  2 直播中+直播预告+已结束
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        column_title_font_size: 32,
        // 标题文字字号
        item_margin: 30,
        // 专场间距
        item_background: '',
        // 专场背景颜色
        name_wrap: false,
        // 专场名字是否换行   不换行就是超出一行显示...
        indicator_style: 2,
        // 滑动指示器样式  1 圆点  2 横条  3 数字
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#003D7C',
        // 指示器颜色
        page_margin: 30,
        // 页面间距
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#ffffff',
        // 副标题颜色
        detail_color: '#999999',
        // 详细介绍颜色
        status_bar_bg: '#003D7C',
        // 状态栏背景色
        status_bar_color: 'white',
        // 状态栏文字颜色   white  black 只有这俩
        status_bar_height: 60,
        // 状态栏高度
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        top_radius: 8,
        // 专场上圆角
        bottom_radius: 8,
        // 转下下圆角
        img_style: 2,
        text_margin: 24,
        img_width: 240,
        img_height: 240,
        line_num: 'line3',
        title_size: 30,
        title_weight: 500
      }
    }]
  },
  marketing: {
    title: '推广组件',
    children: [{
      name: 'wechat',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/gongzhonghao_icon.png',
      title: '公众号',
      content: {
        show_name: '公众号',
        link: '',
        show_title: true,
        title_icon: 'https://artimg2.artart.cn/image/20211028/c78de7ae6e7fd23b5f3eff309762e2ae.png',
        title_icon2: 'https://artimg2.artart.cn/image/20211028/1f97313f730870b48f51355ed430303c.png',
        title_icon_right: 'https://artimg2.artart.cn/image/20211028/a63488780e53f04ac78ffcc986193b1f.png',
        title_txt: '关注公众号',
        logo: '',
        name: '公众号名称',
        desc: '专业玉石拍卖鉴定',
        button_txt: '关注'
      },
      // 外观
      facade: {
        type: 1,
        // 1 模块展示   2 悬浮展示
        page_padding: 32,
        page_radius: 8,
        background_color: '#FFFFFF',
        border_color: '#48C265',
        title_color: '#48C265',
        title_margin_left: 32,
        title_margin_top: 32,
        title_size: 28,
        title_style: 500,
        img_width: 80,
        img_height: 80,
        img_radius: 40,
        img_margin_left: 32,
        img_margin_top: 24,
        name_color: '#000000',
        name_margin_left: 24,
        name_margin_top: 26,
        name_size: 28,
        name_style: 500,
        desc_color: '#999999',
        desc_margin_top: 0,
        desc_size: 24,
        desc_style: 400,
        button_background_color: '#48C265',
        button_color: '#FFFFFF',
        button_margin_left: 190,
        button_margin_top: 34,
        button_size: 28,
        button_desc_style: 500,
        button_radius: 8,
        position: 1,
        bottom_margin: 200,
        bottom_radius: 40,
        bottom_background: '#48C265',
        bottom_border: '#48C265',
        bottom_color: '#FFFFFF',
        bottom_size: 24,
        bottom_style: 400
      }
    }, {
      name: 'wechat',
      icon: 'https://artimg2.artart.cn/image/20211028/a99ba3faad751c7400e389e3d652fbd3.png',
      title: '视频号',
      content: {
        show_name: '视频号',
        link: '',
        show_title: true,
        title_icon: 'https://artimg2.artart.cn/image/20211028/b41faf31598c0769cee59edb2182a9ad.png',
        title_icon2: 'https://artimg2.artart.cn/image/20211028/15b694c01a0a2334ac4c1a7b5873e315.png',
        title_icon_right: 'https://artimg2.artart.cn/image/20211028/a63488780e53f04ac78ffcc986193b1f.png',
        title_txt: '关注视频号',
        logo: '',
        name: '视频号名称',
        desc: '专业玉石拍卖鉴定',
        button_txt: '关注'
      },
      // 外观
      facade: {
        type: 1,
        // 1 模块展示   2 悬浮展示
        page_padding: 32,
        page_radius: 8,
        background_color: '#F5EEE8',
        border_color: '#B4804E',
        title_color: '#B4804E',
        title_margin_left: 32,
        title_margin_top: 32,
        title_size: 28,
        title_style: 500,
        img_width: 80,
        img_height: 80,
        img_radius: 40,
        img_margin_left: 32,
        img_margin_top: 24,
        name_color: '#000000',
        name_margin_left: 24,
        name_margin_top: 26,
        name_size: 28,
        name_style: 500,
        desc_color: '#999999',
        desc_margin_top: 0,
        desc_size: 24,
        desc_style: 400,
        button_background_color: '#B4804E',
        button_color: '#FFFFFF',
        button_margin_left: 190,
        button_margin_top: 34,
        button_size: 28,
        button_desc_style: 500,
        button_radius: 8,
        position: 1,
        bottom_margin: 200,
        bottom_radius: 40,
        bottom_background: '#B4804E',
        bottom_border: '#B4804E',
        bottom_color: '#FFFFFF',
        bottom_size: 24,
        bottom_style: 400
      }
    }]
  }
};
var _default = panels;
exports.default = _default;