"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _settings = require("@/settings");
var _layout = _interopRequireDefault(require("@/layout"));
var _open3d = _interopRequireDefault(require("./modules/open3d"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [_open3d.default, {
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: _settings.roterPre,
  component: _layout.default,
  children: [{
    path: "".concat(_settings.roterPre, "/dashboard"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/open3d/template/list"),
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/',
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/open3d/template/list"),
  children: [{
    path: "".concat(_settings.roterPre, "/dashboard"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: "".concat(_settings.roterPre, "/login"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/diy"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/customMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/diy/page"),
  meta: {
    title: '自定义页面',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'pageDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/customPage'));
      });
    },
    meta: {
      title: '自定义页面',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/mine"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/mineMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/mine/page"),
  meta: {
    title: '个人中心配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'mineDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/minePage'));
      });
    },
    meta: {
      title: '个人中心配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/weChat"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/weChatMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/mine/weChat"),
  meta: {
    title: '推文配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'mineDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/weChat'));
      });
    },
    meta: {
      title: '推文配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/basics"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/basicsMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/basics/page"),
  meta: {
    title: '基础页面配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'basicsDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/basicsPage'));
      });
    },
    meta: {
      title: '基础页面配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/blindAlbumControl"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/blindMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/blindAlbumControl/page"),
  meta: {
    title: '基础页面配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'basicsDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/blindPage'));
      });
    },
    meta: {
      title: '基础页面配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: _settings.roterPre + '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: _settings.roterPre + '/setting/icons',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/iconFrom/index'));
    });
  },
  name: 'icons'
}, {
  path: _settings.roterPre + '/setting/uploadPicture',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/uploadPicture/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/setting/storeProduct',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/goodList/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/setting/broadcastProduct',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/importGoods/goodList.vue'));
    });
  },
  name: 'broadcastProduct'
}, {
  path: _settings.roterPre + '/setting/userList',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/userList/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/order/export',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/exportFile/index.vue'));
    });
  },
  name: 'exportFileList'
}, {
  path: _settings.roterPre + '/admin/widget/image',
  name: "images",
  meta: {
    title: '上传图片'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/uploadPicture/index'));
    });
  }
}, {
  path: _settings.roterPre + '/admin/widget/video',
  name: "video",
  meta: {
    title: '上传视频'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/uploadVideo/index'));
    });
  }
}, {
  path: "".concat(_settings.roterPre, "/recentlyLaunched"),
  component: _layout.default,
  children: [{
    path: ':type',
    meta: {
      title: '近期上线',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/error-page/recentlyLaunched/type'));
      });
    }
  }]
}, {
  path: '*',
  redirect: _settings.roterPre + '/404',
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;