var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Widget",
    { attrs: { index: _vm.index, type: "notice" } },
    [_c("Element", { attrs: { facade: _vm.facade, content: _vm.content } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }