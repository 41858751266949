"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _open3d = require("@/api/open3d");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      status: '1',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        status: '',
        nickname: '',
        phone: ''
      },
      showdetail: null,
      uid: ''
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _open3d.userSpaceApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    }
  }
};
exports.default = _default;