var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "专场组件", is_tab: false } },
    [
      _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "页面边距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.page_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "page_margin", $$v)
                        },
                        expression: "facade.page_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_color", $$v)
                        },
                        expression: "facade.title_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "副标题" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.sub_title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "sub_title_color", $$v)
                        },
                        expression: "facade.sub_title_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "详情颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.detail_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "detail_color", $$v)
                        },
                        expression: "facade.detail_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态条背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000", "no-alpha": "1" },
                      model: {
                        value: _vm.facade.status_bar_bg,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "status_bar_bg", $$v)
                        },
                        expression: "facade.status_bar_bg",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态条高度" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 200 },
                      model: {
                        value: _vm.facade.status_bar_height,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "status_bar_height", $$v)
                        },
                        expression: "facade.status_bar_height",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态条文字" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.status_bar_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "status_bar_color", $$v)
                          },
                          expression: "facade.status_bar_color",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "white" } }, [
                          _vm._v("白色"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "black" } }, [
                          _vm._v("黑色"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "阴影" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.item_shadow,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_shadow", $$v)
                        },
                        expression: "facade.item_shadow",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "阴影大小" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.item_shadow_size,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_shadow_size", $$v)
                        },
                        expression: "facade.item_shadow_size",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "图片圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.top_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "top_radius", $$v)
                        },
                        expression: "facade.top_radius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "图片圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.bottom_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bottom_radius", $$v)
                        },
                        expression: "facade.bottom_radius",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }