var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "帖子" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center flex-wrap" },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "change-type",
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: { curr: _vm.is_active(item) },
                        },
                        [
                          _c("label", [
                            _c("img", { attrs: { src: item.src } }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.content.style,
                                  expression: "content.style",
                                },
                              ],
                              staticClass: "choose-input",
                              attrs: { type: "radio", name: "choose" },
                              domProps: {
                                value: item.value,
                                checked: _vm._q(_vm.content.style, item.value),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.content,
                                    "style",
                                    item.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "name color-gray align-c" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.content.style === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("图片显示方式"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 2
                ? _c(
                    "div",
                    {
                      staticClass: "item-align-center",
                      staticStyle: { "margin-top": "15px" },
                    },
                    _vm._l(_vm.imgStyleData, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "change-type" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "img",
                              class: { curr: _vm.is_imgactive(item) },
                            },
                            [
                              _c("label", [
                                _c("img", { attrs: { src: item.src } }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.content.img_style,
                                      expression: "content.img_style",
                                    },
                                  ],
                                  staticClass: "choose-input",
                                  attrs: { type: "radio", name: "choose" },
                                  domProps: {
                                    value: item.value,
                                    checked: _vm._q(
                                      _vm.content.img_style,
                                      item.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.content,
                                        "img_style",
                                        item.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "name color-gray align-c" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("数据选择"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.content.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "type", $$v)
                        },
                        expression: "content.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("系统自动展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("手动选择展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("系统自动展示条件"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt10" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.content.system_sort,
                            callback: function ($$v) {
                              _vm.$set(_vm.content, "system_sort", $$v)
                            },
                            expression: "content.system_sort",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("按热度排序"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("按最新发布排序"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1 && !_vm.loadMore
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("展示数据")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: { min: 1, max: 50, label: "数量" },
                        model: {
                          value: _vm.content.system_count,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "system_count", $$v)
                          },
                          expression: "content.system_count",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("手动选择帖子"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.data &&
              _vm.content.data.length > 0 &&
              _vm.content.type === 2
                ? _c(
                    "div",
                    { staticClass: "pt10" },
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center mt10",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "item-align-center flex-one" },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-down-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDown(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-up-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUp(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("img", {
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "margin-right": "5px",
                        "margin-top": "2px",
                      },
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fontW500 ft16",
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("选择帖子")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_padding", $$v)
                      },
                      expression: "facade.page_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上下间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_bottom_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_bottom_padding", $$v)
                      },
                      expression: "facade.item_bottom_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.status_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_background", $$v)
                      },
                      expression: "facade.status_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容上下距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_padding_top,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_padding_top", $$v)
                      },
                      expression: "facade.content_padding_top",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容左右距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_padding_left,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_padding_left", $$v)
                      },
                      expression: "facade.content_padding_left",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容上圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_top_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_top_radius", $$v)
                      },
                      expression: "facade.content_top_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容下圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_bottom_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_bottom_radius", $$v)
                      },
                      expression: "facade.content_bottom_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.img_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "img_radius", $$v)
                      },
                      expression: "facade.img_radius",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AppraisalQuestions", {
        ref: "course",
        attrs: { currentData: _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }