"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _live = require("@/api/live");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    hasMoreData: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      live_status: '',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      auctionName: '',
      venueList: [],
      venueLoading: false,
      venue_id: ''
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadAuction();
      }
      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      this.queryParams.live_status = this.live_status;
      this.queryParams.page = 1;
      this.loadAuction();
    },
    loadAuction: function loadAuction(fun) {
      var _this = this;
      (0, _live.liveList)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.resultData = response.data.list;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var m = this.resultData[index];
      var data = {
        image: m.cover_image + '!300',
        name: m.live_title,
        id: m.live_id
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'live',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    }
  }
};
exports.default = _default2;