"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalForm;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _request = _interopRequireDefault(require("@/api/request"));
var _vue = _interopRequireDefault(require("vue"));
var fApi;
var unique = 1;
var uniqueId = function uniqueId() {
  return ++unique;
};
function modalForm(formRequestPromise) {
  var _this = this;
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var h = this.$createElement;
  return new Promise(function (resolve, reject) {
    formRequestPromise.then(function (_ref) {
      var data = _ref.data;
      data.config.submitBtn = false;
      data.config.resetBtn = false;
      if (!data.config.form) data.config.form = {};
      if (!data.config.formData) data.config.formData = {};
      data.config.formData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data.config.formData), config.formData);
      data.config.form.labelWidth = '120px';
      data.config.global = {
        upload: {
          props: {
            onSuccess: function onSuccess(rep, file) {
              if (rep.status === 200) {
                file.url = rep.data.src;
              }
            }
          }
        }
      };
      data = _vue.default.observable(data);
      _this.$msgbox({
        title: data.title,
        customClass: config.class || 'modal-form',
        message: h('div', {
          class: 'common-form-create',
          key: uniqueId()
        }, [h('formCreate', {
          props: {
            rule: data.rule,
            option: data.config
          },
          on: {
            mounted: function mounted($f) {
              fApi = $f;
            }
          }
        })]),
        beforeClose: function beforeClose(action, instance, done) {
          var fn = function fn() {
            setTimeout(function () {
              instance.confirmButtonLoading = false;
            }, 500);
          };
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            fApi.submit(function (formData) {
              _request.default[data.method.toLowerCase()](data.action.slice(5), formData).then(function (res) {
                done();
                _this.$message.success(res.message || '提交成功');
                resolve(res);
              }).catch(function (err) {
                _this.$message.error(err.message || '提交失败');
                reject(err);
              }).finally(function () {
                fn();
              });
            }, function () {
              return fn();
            });
          } else {
            fn();
            done();
          }
        }
      });
    }).catch(function (e) {
      _this.$message.error(e.message);
    });
  });
}