"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/product");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      searchName: '',
      searchGoodsId: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadProduct();
      }
      this.showSelectAuction = true;
    },
    searchGoods: function searchGoods() {
      this.queryParams.keyword = this.searchName;
      this.queryParams.page = 1;
      this.loadProduct();
    },
    loadProduct: function loadProduct(fun) {
      var _this = this;
      (0, _product.productLstApi)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.resultData = response.data.list;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var m = this.resultData[index];
      var data = {
        image: m.image + '!300',
        name: m.store_name,
        id: m.product_id,
        stock: m.stock,
        tags: [],
        is_show: m.is_show
      };
      if (m.price) {
        data.price = "\uFFE5".concat((0, _index.formatPriceNumber)(Number(m.goods_price || m.price), 2));
      } else {
        data.price = "\u54A8\u8BE2";
      }
      if (m.goods_sales) {
        data.sign = "\u5DF2\u552E ".concat(m.goods_sales);
      }
      // if (
      //   m.goods_type != 3 &&
      //   m.goods_shipping_fee_type == 1 &&
      //   (!m.goods_shipping_fee || m.goods_shipping_fee == '0.00')
      // ) {
      //   data.tags.push('包邮');
      // }

      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.size === '1') {
        this.cursorSelect();
      }
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'goods',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadProduct();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadProduct();
    }
  }
};
exports.default = _default2;