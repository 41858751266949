var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "banner",
        style: { "background-color": _vm.facade.background_color },
      },
      [
        _c("CustomBox", {
          attrs: {
            currentData: _vm.content.data,
            content: _vm.content,
            facade: _vm.facade,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }