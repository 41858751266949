var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "choose" }, [
    _c("div", { staticClass: "item-align-center" }, [
      _vm.title
        ? _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-align-center flex-wrap" },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "img",
              class: { curr: _vm.is_active(item) },
              style: { margin: _vm.margin },
            },
            [
              _c("label", [
                _c("img", {
                  style: { width: _vm.width, height: _vm.height },
                  attrs: { src: item.src },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.select,
                      expression: "select",
                    },
                  ],
                  staticClass: "choose-input",
                  attrs: { type: "radio", name: _vm.group },
                  domProps: {
                    value: item.value,
                    checked: _vm._q(_vm.select, item.value),
                  },
                  on: {
                    change: function ($event) {
                      _vm.select = item.value
                    },
                  },
                }),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }