"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
require("core-js/modules/es6.number.constructor");
var _default2 = {
  props: {
    title: {
      type: String,
      default: '你确认要删除数据吗？'
    },
    confirmButtonText: {
      type: String,
      default: "确定"
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    width: {
      type: [String, Number],
      default: '30%'
    },
    top: {
      type: [String, Number],
      default: '40vh'
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      visible: false
    };
  },
  methods: {
    handleDialog: function handleDialog() {
      if (this.disabled) return;
      this.visible = true;
    },
    /**
     * 获取商品信息
     * @return {[type]} [description]
     */
    handleConfirm: function handleConfirm() {
      this.visible = false;
      this.$emit('confirm');
    },
    /**
     * 获取分组信息
     * @return {[type]} [description]
     */
    handleCancel: function handleCancel() {
      this.visible = false;
      this.$emit('cancel');
    }
  },
  render: function render() {
    var h = arguments[0];
    var handleConfirm = this.handleConfirm,
      handleDialog = this.handleDialog,
      handleCancel = this.handleCancel,
      confirmButtonText = this.confirmButtonText,
      cancelButtonText = this.cancelButtonText;
    var dialogData = {
      props: {
        title: this.title,
        visible: this.visible,
        width: this.width,
        center: true,
        top: this.top,
        "modal-append-to-body": false,
        "before-close": handleCancel
      }
    };
    //判断插槽是否存在DOM结构，如果存在则获取后放入upload目录
    var trigger = this.$slots.trigger || this.$slots.default;
    return h("div", {
      "class": "le-popconfirm"
    }, [h("span", {
      "on": {
        "click": handleDialog
      }
    }, [trigger]), h("el-dialog", (0, _babelHelperVueJsxMergeProps.default)([{}, dialogData]), [h("span", {
      "slot": "footer",
      "class": "dialog-footer"
    }, [h("el-button", {
      "on": {
        "click": handleCancel
      }
    }, [cancelButtonText]), h("el-button", {
      "attrs": {
        "type": "primary"
      },
      "on": {
        "click": handleConfirm
      }
    }, [confirmButtonText])])])]);
  }
};
exports.default = _default2;