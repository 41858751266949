"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _banner = _interopRequireDefault(require("./banner"));
var _rubik = _interopRequireDefault(require("./rubik"));
var _search = _interopRequireDefault(require("./search"));
var _title = _interopRequireDefault(require("./title"));
var _video = _interopRequireDefault(require("./video"));
var _nav = _interopRequireDefault(require("./nav"));
var _blank = _interopRequireDefault(require("./blank"));
var _notice = _interopRequireDefault(require("./notice"));
var _auction_meeting = _interopRequireDefault(require("./auction_meeting"));
var _auction_album = _interopRequireDefault(require("./auction_album"));
var _courseOnline = _interopRequireDefault(require("./courseOnline"));
var _auction = _interopRequireDefault(require("./auction"));
var _product = _interopRequireDefault(require("./product"));
var _tag = _interopRequireDefault(require("./tag"));
var _courseOffline = _interopRequireDefault(require("./courseOffline"));
var _memberCard = _interopRequireDefault(require("./memberCard"));
var _appraisalExpert = _interopRequireDefault(require("./appraisalExpert"));
var _appraisalQuestion = _interopRequireDefault(require("./appraisalQuestion"));
var _artiest = _interopRequireDefault(require("./artiest"));
var _bbs = _interopRequireDefault(require("./bbs"));
var _brand = _interopRequireDefault(require("./brand"));
var _exhibit = _interopRequireDefault(require("./exhibit"));
var _live = _interopRequireDefault(require("./live"));
var _ticket = _interopRequireDefault(require("./ticket"));
var _word = _interopRequireDefault(require("./word"));
var _work = _interopRequireDefault(require("./work"));
var _coupon = _interopRequireDefault(require("./coupon"));
var _category = _interopRequireDefault(require("./category"));
var _wechat = _interopRequireDefault(require("./wechat"));
var _mine_nav = _interopRequireDefault(require("./mine_nav"));
var _album_detail = _interopRequireDefault(require("./album_detail"));
var _auction_detail = _interopRequireDefault(require("./auction_detail"));
var _product_detail = _interopRequireDefault(require("./product_detail"));
var _auction_weChat = _interopRequireDefault(require("./auction_weChat"));
var _rubik_weChat = _interopRequireDefault(require("./rubik_weChat"));
var _auction_album_weChat = _interopRequireDefault(require("./auction_album_weChat"));
var _textView = _interopRequireDefault(require("./textView"));
var _nftCollection = _interopRequireDefault(require("./nftCollection"));
var _nftCollectionMarket = _interopRequireDefault(require("./nftCollectionMarket"));
var _nftBlindBox = _interopRequireDefault(require("./nftBlindBox"));
var _blindBox = _interopRequireDefault(require("./blindBox"));
var _nftDetail = _interopRequireDefault(require("./nftDetail"));
var _nftCollectionAlbum = _interopRequireDefault(require("./nftCollectionAlbum"));
var _compose = _interopRequireDefault(require("./compose"));
var _blind = _interopRequireDefault(require("./blind"));
var _blindDetail = _interopRequireDefault(require("./blindDetail"));
var _blindOpen = _interopRequireDefault(require("./blindOpen"));
var _blindOrderList = _interopRequireDefault(require("./blindOrderList"));
var _nftInfo = _interopRequireDefault(require("./nftInfo"));
var _default = {
  banner: _banner.default,
  rubik: _rubik.default,
  search: _search.default,
  title: _title.default,
  video: _video.default,
  nav: _nav.default,
  blank: _blank.default,
  notice: _notice.default,
  auctionMeeting: _auction_meeting.default,
  auctionAlbum: _auction_album.default,
  courseOnline: _courseOnline.default,
  auction: _auction.default,
  product: _product.default,
  tag: _tag.default,
  courseOffline: _courseOffline.default,
  memberCard: _memberCard.default,
  appraisalExpert: _appraisalExpert.default,
  appraisalQuestion: _appraisalQuestion.default,
  artiest: _artiest.default,
  bbs: _bbs.default,
  brand: _brand.default,
  exhibit: _exhibit.default,
  live: _live.default,
  ticket: _ticket.default,
  word: _word.default,
  work: _work.default,
  coupon: _coupon.default,
  category: _category.default,
  wechat: _wechat.default,
  mine_nav: _mine_nav.default,
  albumDetail: _album_detail.default,
  auctionDetail: _auction_detail.default,
  productDetail: _product_detail.default,
  auction_weChat: _auction_weChat.default,
  auction_album_weChat: _auction_album_weChat.default,
  rubik_weChat: _rubik_weChat.default,
  textView: _textView.default,
  nftCollection: _nftCollection.default,
  nftCollectionMarket: _nftCollectionMarket.default,
  nftBlindBox: _nftBlindBox.default,
  blindBox: _blindBox.default,
  nftDetail: _nftDetail.default,
  nftCollectionAlbum: _nftCollectionAlbum.default,
  compose: _compose.default,
  blind: _blind.default,
  blindDetail: _blindDetail.default,
  blindOpen: _blindOpen.default,
  blindOrderList: _blindOrderList.default,
  nftInfo: _nftInfo.default
};
exports.default = _default;