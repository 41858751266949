var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: _vm.sty }, [
    _vm.type == 0
      ? _c(
          "div",
          {
            class: "status-type" + _vm.status + " big" + _vm.big,
            style: _vm.styleData,
          },
          [_vm._v("预展中")]
        )
      : _vm.type == 1
      ? _c(
          "div",
          {
            staticClass: "hui",
            class: "status-type" + _vm.status + " big" + _vm.big,
            style: _vm.styleData,
          },
          [_vm._v("已结拍")]
        )
      : _vm.type == 2
      ? _c(
          "div",
          {
            staticClass: "qiagou",
            class: "status-type" + _vm.status + " big" + _vm.big,
            style: _vm.styleData,
          },
          [_vm._v("可洽购")]
        )
      : _c(
          "div",
          {
            class: "status-type" + _vm.status + " big" + _vm.big,
            style: _vm.styleData,
          },
          [_vm._v("进行中")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }