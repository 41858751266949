var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "720px",
            "close-on-click-modal": false,
            "append-to-body": "",
            title: "选择拍品",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "h400",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "padding-top": "20px" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "拍卖会名称",
                        filterable: "",
                        remote: "",
                        size: "small",
                        clearable: "",
                        "remote-method": _vm.searchVenueAction,
                        loading: _vm.venueLoading,
                      },
                      on: { change: _vm.changeMeetingAction },
                      model: {
                        value: _vm.venue_id,
                        callback: function ($$v) {
                          _vm.venue_id = $$v
                        },
                        expression: "venue_id",
                      },
                    },
                    _vm._l(_vm.venueList, function (m, i) {
                      return _c("el-option", {
                        key: "user" + i,
                        attrs: { value: m.venue_id, label: m.title },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "w200 ml10",
                      attrs: {
                        placeholder: "专场名称",
                        filterable: "",
                        remote: "",
                        size: "small",
                        clearable: "",
                        "remote-method": _vm.searchAlbumAction,
                        loading: _vm.venueLoading,
                      },
                      on: { change: _vm.searchAAction },
                      model: {
                        value: _vm.album_id,
                        callback: function ($$v) {
                          _vm.album_id = $$v
                        },
                        expression: "album_id",
                      },
                    },
                    _vm._l(_vm.albumList, function (m, i) {
                      return _c("el-option", {
                        key: "user" + i,
                        attrs: { value: m.album_id, label: m.album_name },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w200 ml10",
                      attrs: { placeholder: "拍品名称/LOT", size: "small" },
                      on: { change: _vm.searchAAction },
                      model: {
                        value: _vm.auctionName,
                        callback: function ($$v) {
                          _vm.auctionName = $$v
                        },
                        expression: "auctionName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: { change: _vm.searchAAction },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "spec-table mt20" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { height: "30px" },
                          attrs: { width: "35%", align: "center" },
                        },
                        [_vm._v("拍品信息")]
                      ),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "25%", align: "center" } }, [
                        _vm._v("拍卖时间"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("价格"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "10%", align: "center" } }, [
                        _vm._v("拍品状态"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "10%", align: "center" } }, [
                        _vm._v("操作"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i, staticClass: "list ft12" }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c(
                            "div",
                            { staticClass: "display-flex item-align-center" },
                            [
                              m.product.image
                                ? _c("img", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      display: "block",
                                    },
                                    attrs: { src: m.product.image + "!120a" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml10 flex-one ft12" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(m.product_id) +
                                    " | " +
                                    _vm._s(m.product.store_name) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.album_id
                              ? _c("p", [
                                  _vm._v(
                                    "专场：" +
                                      _vm._s(m.album_id) +
                                      " | " +
                                      _vm._s(m.album_name)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !m.album_id
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(m.start_time_format[1]) +
                                      " 至 " +
                                      _vm._s(m.end_time_format[1])
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.now_price != "0.00"
                              ? _c("p", [
                                  _vm._v("当前价：" + _vm._s(m.now_price)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("起拍价：" + _vm._s(m.start_price)),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("加价幅度：" + _vm._s(m.plus_price)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "p",
                              [
                                m.auction_status == 1
                                  ? [_vm._v("进行中")]
                                  : m.auction_status == -1
                                  ? [_vm._v("违约")]
                                  : m.auction_status == 2
                                  ? [_vm._v("预展中")]
                                  : m.auction_status == 3
                                  ? [_vm._v("保留价待确认")]
                                  : m.auction_status == 4
                                  ? [_vm._v("流拍")]
                                  : m.auction_status == 5
                                  ? [_vm._v("已中拍 未付款")]
                                  : m.auction_status == 6
                                  ? [_vm._v("已完成")]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.product_id) ==
                            -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.product_id
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-e mt20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.pageChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr20",
                  attrs: { type: "primary" },
                  on: { click: _vm.cursorSelect },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSelectAuction = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }