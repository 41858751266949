var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "upload-div", staticStyle: { width: "70px" } },
      [
        _c("input", {
          staticClass: "upload-input",
          attrs: { type: "file" },
          on: {
            change: function ($event) {
              return _vm.uploadALiAction($event)
            },
          },
        }),
        _vm._v(" "),
        _c("el-button", [_vm._v("上传")]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.statusText
      ? _c(
          "div",
          { staticClass: "mt20" },
          [
            _c("el-progress", {
              attrs: { percentage: _vm.authProgress, status: "success" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }