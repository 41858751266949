var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "choice" }, [
    _c(
      "ul",
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "choice-radio",
            style: { width: _vm.width },
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.select,
                    expression: "select",
                  },
                ],
                staticClass: "choice-input",
                attrs: { type: "radio", name: _vm.group },
                domProps: {
                  value: item.value,
                  checked: _vm._q(_vm.select, item.value),
                },
                on: {
                  change: function ($event) {
                    _vm.select = item.value
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "choice-item" }, [
                item.icon
                  ? _c("i", { staticClass: "le-icon", class: item.icon })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { style: { paddingLeft: item.icon ? "8px" : "0" } },
                  [_vm._v(_vm._s(item.name))]
                ),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }