var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget" }, [
    _c(
      "div",
      {
        staticClass: "widget-zoom",
        on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseleave },
      },
      [
        _vm.hover ? _c("div", { staticClass: "widget-mask1" }) : _vm._e(),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "widget-label",
            attrs: {
              for: "widget" + _vm.index,
              "data-index": _vm.index,
              "data-type": "widget",
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.select,
                  expression: "select",
                },
              ],
              staticClass: "widget-input",
              attrs: {
                type: "radio",
                name: "widget",
                id: "widget" + _vm.index,
              },
              domProps: {
                value: _vm.index,
                checked: _vm._q(_vm.select, _vm.index),
              },
              on: {
                change: function ($event) {
                  _vm.select = _vm.index
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "widget-mask",
                attrs: { "data-index": _vm.index, "data-type": "widget" },
              },
              [
                _vm.no_control != 2
                  ? _c(
                      "div",
                      {
                        staticClass: "widget-handle",
                        style: { height: _vm.no_control != 1 ? "" : "71px" },
                        attrs: { id: _vm.index == 1 ? "guide003" : "widget" },
                      },
                      [
                        _vm.no_control != 1 && _vm.no_control != 2
                          ? _c(
                              "div",
                              { staticClass: "widget-handle-item" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "删除",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onDelete($event)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.no_control != 1 && _vm.no_control != 2
                          ? _c(
                              "div",
                              {
                                staticClass: "widget-handle-item",
                                class: { disabled: _vm.is_copy },
                                attrs: { disabled: _vm.is_copy },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "复制",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-document-copy",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onCopy($event)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.no_control != 2
                          ? _c(
                              "div",
                              {
                                staticClass: "widget-handle-item",
                                class: { disabled: _vm.is_up },
                                attrs: { disabled: _vm.is_up },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "上移",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-arrow-up",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.moveUp($event)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.no_control != 2
                          ? _c(
                              "div",
                              {
                                staticClass: "widget-handle-item",
                                class: { disabled: _vm.is_down },
                                attrs: { disabled: _vm.is_down },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "下移",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-arrow-down",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.moveDown($event)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content" }, [_vm._t("default")], 2),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }