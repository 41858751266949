var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "图文栏目" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mine-nav-view",
                  staticStyle: { "margin-top": "15px" },
                },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c("div", { key: index, staticClass: "change-type" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        class: { curr: item.value === _vm.content.style },
                      },
                      [
                        _c("label", [
                          _c("img", { attrs: { src: item.src } }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.content.style,
                                expression: "content.style",
                              },
                            ],
                            staticClass: "choose-input",
                            attrs: { type: "radio", name: "choose" },
                            domProps: {
                              value: item.value,
                              checked: _vm._q(_vm.content.style, item.value),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.content,
                                  "style",
                                  item.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "name color-gray align-c ft12" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("导航标题"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("标题"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "不填默认不展示标题" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                        },
                      },
                      model: {
                        value: _vm.content.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "title", $$v)
                        },
                        expression: "content.title",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("右侧文字"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "不填默认不展示右侧文字" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                        },
                      },
                      model: {
                        value: _vm.content.right_desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "right_desc", $$v)
                        },
                        expression: "content.right_desc",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("会员替换"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "会员用户看到的右侧文字(可不填)" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                        },
                      },
                      model: {
                        value: _vm.content.is_member_desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "is_member_desc", $$v)
                        },
                        expression: "content.is_member_desc",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("导航内容和跳转链接"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pt10" },
                _vm._l(_vm.content.data, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "kuang1 flex align-items-s mt10",
                    },
                    [
                      _c("div", { staticClass: " item-align-center h40" }, [
                        index === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "go-down-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.goDown(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "go-up-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUp(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "del-img",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(index)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap1(index)
                            },
                          },
                        },
                        [
                          item.url
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg1(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.url
                            ? _c("img", { attrs: { src: item.url + "!120a" } })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-view ml4" }, [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "h40",
                              staticStyle: { width: "140px" },
                              attrs: {
                                type: "text",
                                placeholder: "菜单文字",
                                maxlength: "10",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: item.title,
                                callback: function ($$v) {
                                  _vm.$set(item, "title", $$v)
                                },
                                expression: "item.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.content.style == 2
                          ? _c(
                              "div",
                              { staticClass: "mt10" },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "140px" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "右侧文字（选填）",
                                    maxlength: "10",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: item.right_title,
                                    callback: function ($$v) {
                                      _vm.$set(item, "right_title", $$v)
                                    },
                                    expression: "item.right_title",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-one" }, [
                        item.link.name1 || item.link.name
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "item-align-center justify-end ml10 cur-pointer h40",
                                on: {
                                  click: function ($event) {
                                    return _vm.setCategory(index)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "ft14" }, [
                                  _vm._v(
                                    _vm._s(item.link.name1 || item.link.name)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "arrow-img",
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "item-align-center justify-end color-link ml10 cur-pointer h40",
                                on: {
                                  click: function ($event) {
                                    return _vm.setCategory(index)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "ft14" }, [
                                  _vm._v("设置链接"),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "arrow-img",
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                        _vm._v(" "),
                        _vm.content.style == 2
                          ? _c(
                              "div",
                              { staticClass: "mt10" },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      title: "",
                                      width: "250",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { width: "225px" },
                                      attrs: {
                                        src: "https://artimg2.artart.cn/image/20211215/87646a6f71b21bb2800ec36ad5e928b0.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "item-align-center justify-end color-link ml10 cur-pointer h40",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "arrow-img",
                                          staticStyle: { width: "16px" },
                                          attrs: {
                                            src: "https://artimg2.artart.cn/image/20211215/55506f1ce90c1920a0bf71db63677dd7.png",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "ft14 ml5" }, [
                                          _vm._v("使用方法"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://artimg2.artart.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAdditem },
                  },
                  [_vm._v("新增一条")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("背景图片"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "bg_image")
                      },
                    },
                  },
                  [
                    _vm.facade.bg_image
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg("bg_image")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.bg_image
                      ? _c("img", {
                          attrs: { src: _vm.facade.bg_image + "!300" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#f7f7f7" },
                    model: {
                      value: _vm.facade.background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background", $$v)
                      },
                      expression: "facade.background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "分割线颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#EEEEEE" },
                    model: {
                      value: _vm.facade.border_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_color", $$v)
                      },
                      expression: "facade.border_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.page_shadow,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_shadow", $$v)
                      },
                      expression: "facade.page_shadow",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "左右边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_margin", $$v)
                      },
                      expression: "facade.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 200 },
                    model: {
                      value: _vm.facade.page_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_radius", $$v)
                      },
                      expression: "facade.page_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.page_shadow_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_shadow_size", $$v)
                      },
                      expression: "facade.page_shadow_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "每行数量" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.line_count,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "line_count", $$v)
                        },
                        expression: "facade.line_count",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("3个")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4 } }, [_vm._v("4个")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 5 } }, [_vm._v("5个")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.alignment,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "alignment", $$v)
                        },
                        expression: "facade.alignment",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("左对齐"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("右对齐"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.facade.line_count === 0
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "菜单间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.item_padding,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "item_padding", $$v)
                          },
                          expression: "facade.item_padding",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.title
                ? [
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.title_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_color", $$v)
                            },
                            expression: "facade.title_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.title_style,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "title_style", $$v)
                              },
                              expression: "facade.title_style",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("常规"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 40 },
                          model: {
                            value: _vm.facade.title_size,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_size", $$v)
                            },
                            expression: "facade.title_size",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.content.right_desc
                ? [
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.title_right_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_right_color", $$v)
                            },
                            expression: "facade.title_right_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.title_right_style,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "title_right_style", $$v)
                              },
                              expression: "facade.title_right_style",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("常规"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 40 },
                          model: {
                            value: _vm.facade.title_right_size,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_right_size", $$v)
                            },
                            expression: "facade.title_right_size",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.text_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_color", $$v)
                      },
                      expression: "facade.text_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.text_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "text_style", $$v)
                        },
                        expression: "facade.text_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 40 },
                    model: {
                      value: _vm.facade.text_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_size", $$v)
                      },
                      expression: "facade.text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.type != 1
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "h30" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "副文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.vice_text_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "vice_text_color", $$v)
                              },
                              expression: "facade.vice_text_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "副文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.vice_text_style,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "vice_text_style", $$v)
                                },
                                expression: "facade.vice_text_style",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "附文字字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 40 },
                            model: {
                              value: _vm.facade.vice_text_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "vice_text_size", $$v)
                              },
                              expression: "facade.vice_text_size",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }