"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    size: {
      type: [String, Number],
      default: 5
    },
    data: {
      type: Array
    }
  },
  data: function data() {
    return {
      active: 0
    };
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    size: function size(value) {
      this.active = 0;
    },
    active: function active(value) {
      this.$emit('active', value);
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    adverArray: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default;