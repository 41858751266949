"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.to-string");
var _detail = _interopRequireDefault(require("./detail.vue"));
function _isNull(obj) {
  if (Object.prototype.toString.call(obj) !== '[object Object]') {
    return 'Type Error';
  }
  return Object.keys(obj).length === 0;
}
var _default2 = {
  components: {
    Detail: _detail.default
  },
  provide: function provide() {
    return {
      self: this
    };
  },
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    },
    original: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    },
    /**
     * [visible description]
     * @type {Object}
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * 执行的上传方法
     * @type {Object}
     */
    limit: {
      type: Number,
      default: 1
    },
    /**
     * 禁用选项
     * @type {Object}
     */
    forbidden: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    original: function original(value) {
      if (value) {
        this.selectData = JSON.parse(JSON.stringify(this.original));
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      selectData: '',
      tipsData: {
        t2: '请选择商品',
        t3: '请选择商品分类',
        t7: '请选择微页面'
      }
    };
  },
  methods: {
    /**
     * 处理点击事件
     * @return {[type]} [description]
     */
    handleClick: function handleClick() {
      this.dialogVisible = true;
    },
    /**
     * 处理关闭事件
     * @return {[type]} [description]
     */
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 处理确认事件
     * @return {[type]} [description]
     */
    handleConfirm: function handleConfirm() {
      if (this.selectData.extend) {
        var data = this.selectData.param;
        var k = 't' + this.selectData.index;
        if (_isNull(data)) {
          this.$message({
            message: this.tipsData[k],
            type: 'warning'
          });
          return;
        }
      }
      this.dialogVisible = false;
      this.$emit('confirm', this.selectData);
    }
  },
  render: function render() {
    var h = arguments[0];
    var dialogVisible = this.dialogVisible,
      handleClick = this.handleClick,
      handleClose = this.handleClose,
      handleConfirm = this.handleConfirm;
    //判断插槽是否存在DOM结构，如果存在则获取后放入upload目录
    var trigger = this.$slots.trigger || this.$slots.default;

    //设置上传样式
    var defaultData = {
      on: {
        click: handleClick
      }
    };
    var dialogData = {
      props: {
        visible: dialogVisible,
        title: '选择跳转链接',
        'before-close': handleClose,
        width: '392px',
        'destroy-on-close': true
      }
    };
    return h("div", {
      "class": "hyperlink-dialog"
    }, [h("span", (0, _babelHelperVueJsxMergeProps.default)([{}, defaultData]), [trigger]), h("el-dialog", (0, _babelHelperVueJsxMergeProps.default)([{}, dialogData]), [h(_detail.default), h("template", {
      "slot": "footer"
    }, [h("el-button", {
      "on": {
        "click": handleClose
      }
    }, ["\u53D6 \u6D88"]), h("el-button", {
      "attrs": {
        "type": "primary"
      },
      "on": {
        "click": handleConfirm
      }
    }, ["\u786E \u5B9A"])])])]);
  }
};
exports.default = _default2;