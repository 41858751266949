"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = {
  basics: {
    title: '通用组件',
    children: [{
      name: 'title',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/mokuai_icon.png',
      title: '标题栏',
      content: {
        style: 1,
        title: '标题文字',
        subtitle: '二级标题',
        is_more: true,
        moretitle: '更多',
        morelink: {},
        title_img: ''
      },
      // 外观
      facade: {
        title_color: '#000000',
        title_font_size: 40,
        subtitle_color: '#999999',
        subtitle_font_size: 24,
        more_color: '#000000',
        more_font_size: 24,
        more_img: 1,
        // 更多箭头图标 1 黑色 2 白色
        background_color: '',
        page_padding: 40
      }
    }, {
      name: 'textView',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/mokuai_icon.png',
      title: '文字',
      content: {
        title: '标题文字',
        subtitle: '内容文字'
      },
      // 外观
      facade: {
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 15,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 15,
        // 内间距
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        radius: 4,
        // 内容圆角
        title_margin: 10,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 14,
        title_text_align: 'left',
        desc_margin: 15,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 12,
        desc_line_height: 20,
        desc_text_align: 'left'
      }
    }, {
      name: 'blank',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/kongge_icon.png',
      title: '分割',
      content: {
        has_line: false
      },
      // 外观
      facade: {
        height: 20,
        background_color: '',
        line_width: 375,
        line_height: 1,
        line_color: '#DDDDDD'
      }
    }
    // {
    //   name: 'rubik_weChat',
    //   icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/image_icon.png',
    //   title: '图片',
    //   content: {
    //     style: 1, // 风格 1  2  3  4  5  6
    //     density: 4, // 魔方密度
    //     data: [
    //       {
    //         url: '',
    //         link: ''
    //       }
    //     ]
    //   },
    //   // 外观
    //   facade: {}
    // }
    ]
  },

  advanced: {
    title: '内容组件',
    children: [{
      name: 'auction_album_weChat',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_album_icon.png',
      title: '专场',
      hasContent: true,
      content: {
        data: []
      },
      facade: {
        img_position: 'center',
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 15,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 15,
        // 内间距
        radius: 4,
        // 内容圆角
        title_margin: 10,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 14,
        title_text_align: 'left',
        desc_margin: 15,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 12,
        desc_line_height: 20,
        desc_text_align: 'left'
      }
    }, {
      name: 'auction_weChat',
      icon: 'https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_icon.png',
      title: '拍品',
      hasContent: true,
      content: {
        data: []
      },
      facade: {
        img_position: 'center',
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 15,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 15,
        // 内间距
        radius: 4,
        // 内容圆角
        title_margin: 10,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 14,
        title_text_align: 'left',
        desc_margin: 15,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 12,
        desc_line_height: 20,
        desc_text_align: 'left'
      }
    }]
  }
};
var _default = panels;
exports.default = _default;