"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UploadFroms',
  components: {
    UploadIndex: _index.default
  },
  props: {
    changeType: {
      type: String,
      default: '1'
    },
    changeCategory: {
      type: String,
      default: '1'
    }
  },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      isMore: ''
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getImage: function getImage(img) {
      this.callback(img);
      this.visible = false;
    }
  }
};
exports.default = _default;