var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.defaultData, function (m, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "auction-meeting",
          class: { mt0: i === 0 },
          style: {
            "background-color": _vm.facade.background_color,
            marginTop: _vm.meetingMarginTop,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title item-align-center",
              style: {
                padding:
                  "0 " +
                  _vm.facade.meeting_title_padding / 2 +
                  "px " +
                  _vm.facade.meeting_title_padding / 2 +
                  "px " +
                  _vm.facade.meeting_title_padding / 2 +
                  "px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "l flex-one",
                  style: {
                    color: _vm.facade.name_color,
                    fontSize: _vm.facade.name_font_size / 2 + "px",
                  },
                },
                [_vm._v("第三届大众艺术品拍卖会书画第三届大众艺术品拍卖会书画")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r ml10",
                  style: {
                    color: _vm.facade.name_color,
                    borderColor: _vm.facade.name_color,
                  },
                },
                [_vm._v("2个专场")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("album", { attrs: { facade: _vm.facade, content: _vm.content } }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }