"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
var _LineChart = _interopRequireDefault(require("./components/LineChart"));
var _RaddarChart = _interopRequireDefault(require("./components/RaddarChart"));
var _PieChart = _interopRequireDefault(require("./components/PieChart"));
var _BarChart = _interopRequireDefault(require("./components/BarChart"));
var _TodoList = _interopRequireDefault(require("./components/TodoList"));
var _BoxCard = _interopRequireDefault(require("./components/BoxCard"));
var _dashboard = require("@/api/dashboard");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
    payer: [100, 120, 98, 130, 150, 140, 180]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
    payer: [150, 90, 98, 130, 150, 140, 180]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
    payer: [150, 90, 98, 130, 150, 140, 180]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
    payer: [150, 90, 98, 130, 150, 140, 180]
  },
  followers: {
    expectedData: [150, 90, 98, 130, 150, 140, 180],
    actualData: [120, 82, 91, 154, 162, 140, 130],
    payer: [130, 140, 141, 142, 145, 150, 160]
  }
};
var _default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    LineChart: _LineChart.default,
    RaddarChart: _RaddarChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    TodoList: _TodoList.default,
    BoxCard: _BoxCard.default
  },
  data: function data() {
    return {
      value1: "",
      value2: "",
      time1: "lately7",
      time2: "lately7",
      time3: "lately7",
      rankingTime1: "lately7",
      rankingTime2: "lately7",
      rankingTime3: "lately7",
      lineChartData: lineChartData.newVisitis,
      isAmount: true,
      timeList: [{
        value: "lately7",
        label: "近7天"
      }, {
        value: "lately30",
        label: "近30天"
      }, {
        value: "month",
        label: "本月"
      }, {
        value: "year",
        label: "本年"
      }],
      timeList1: [{
        value: "lately7",
        label: "近7天"
      }, {
        value: "lately30",
        label: "近30天"
      }, {
        value: "month",
        label: "本月"
      }],
      commodityPaymentList: [],
      visitorRankingList: [],
      productPlusList: [],
      orderCustomer: {}
    };
  },
  activated: function activated() {
    this.getUserMessage();
  },
  mounted: function mounted() {
    this.getUserMessage();
    this.getCurrentData({
      date: this.time3
    }); // 支付订单
    this.getCustomerData(this.time1); // 成交客户
    this.getCustomerRatioData({
      date: this.time2
    }); // 成交客户占比
    this.getRankingData(this.rankingTime1); // 商品支付排行
    this.getVisitorRankingData(this.rankingTime2); // 商品访客排行
    this.getProductPlusData(this.rankingTime3); // 商品加购排行
  },

  methods: {
    chooseAmount: function chooseAmount() {
      if (this.isAmount) {
        return;
      } else {
        this.isAmount = true;
      }
    },
    chooseCustomers: function chooseCustomers() {
      // this.isAmount = true;
      if (this.isAmount) {
        this.isAmount = false;
      } else {
        return;
      }
    },
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    getCurrentData: function getCurrentData(date) {
      this.$refs.lineChart.getOrderData({
        date: this.time3
      });
    },
    getCustomerData: function getCustomerData(date) {
      var _this = this;
      var params = {
        date: date
      };
      (0, _dashboard.customerApi)(params).then(function (res) {
        if (res.status === 200) {
          _this.orderCustomer = res.data;
        }
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getCustomerRatioData: function getCustomerRatioData(params) {
      this.$refs.pieChart.getTurnoverRatio();
    },
    getRankingData: function getRankingData(date) {
      var _this2 = this;
      var params = {
        date: date
      };
      (0, _dashboard.rankingApi)(params).then(function (res) {
        if (res.status === 200) {
          _this2.commodityPaymentList.length = 0;
          res.data.map(function (item) {
            _this2.commodityPaymentList.push({
              name: item.cart_info.product.store_name,
              picSrc: item.cart_info.product.image,
              count: item.total
            });
          });
        }
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 商品访客排行
    getVisitorRankingData: function getVisitorRankingData(date) {
      var _this3 = this;
      var params = {
        date: date
      };
      (0, _dashboard.visitorRankingApi)(params).then(function (res) {
        if (res.status === 200) {
          _this3.visitorRankingList = res.data;
        }
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 商品加购排行
    getProductPlusData: function getProductPlusData(date) {
      var _this4 = this;
      var params = {
        date: date
      };
      (0, _dashboard.ProductPlusApi)(params).then(function (res) {
        if (res.status === 200) {
          _this4.productPlusList = res.data;
        }
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getUserMessage: function getUserMessage() {
      (0, _user.getBaseInfo)().then(function (res) {
        var resData = res.data;
        console.log(resData);
        if (!resData.mer_avatar || !resData.mer_banner || !resData.mer_info || !resData.service_phone || !resData.mer_address) {
          // this.$alert(
          //   "您好，请前往左侧菜单【设置】-【商户基本信息】完善商户基础信息",
          //   {
          //     confirmButtonText: "确定",
          //     callback: action => {
          //       this.$router.push({
          //         name: "ModifyStoreInfo"
          //       });
          //     }
          //   }
          // );
        }
      });
    }
  }
};
exports.default = _default;