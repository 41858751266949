var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "100px" },
            },
            [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  { staticClass: "w400" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "空间名称：" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: { placeholder: "例：未来星球" },
                          model: {
                            value: _vm.ruleForm.space_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "space_name", $$v)
                            },
                            expression: "ruleForm.space_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w400" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "街区名称：" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: { placeholder: "例：街区" },
                          model: {
                            value: _vm.ruleForm.street_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "street_name", $$v)
                            },
                            expression: "ruleForm.street_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街区数量：" } },
                [
                  _c("el-input", {
                    staticClass: "w100",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.street_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "street_num", _vm._n($$v))
                      },
                      expression: "ruleForm.street_num",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "text", size: "mini" },
                      on: { click: _vm.showAddNumAction },
                    },
                    [_vm._v("增加数量")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml10 ft12 color-gray" }, [
                    _vm._v("设置后将不可减少，只能增加，请谨慎设置"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "热度值：" } },
                [
                  _c("span", [_vm._v("浏览量 × ")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: { controls: false, min: 1 },
                    model: {
                      value: _vm.ruleForm.heat.scan,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm.heat, "scan", $$v)
                      },
                      expression: "ruleForm.heat.scan",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("点赞值 × ")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: { controls: false, min: 1 },
                    model: {
                      value: _vm.ruleForm.heat.like,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm.heat, "like", $$v)
                      },
                      expression: "ruleForm.heat.like",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("分享值 × ")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: { controls: false, min: 1 },
                    model: {
                      value: _vm.ruleForm.heat.share,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm.heat, "share", $$v)
                      },
                      expression: "ruleForm.heat.share",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "加载页：" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.load_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "load_type", $$v)
                          },
                          expression: "ruleForm.load_type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("固定页"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("动态数据加载（作品封面、作品名称等）"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex mt10" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "upload-view item-flex-center mr20",
                        staticStyle: { width: "140px", height: "186px" },
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("load_img_vertical")
                          },
                        },
                      },
                      [
                        _vm.ruleForm["load_img_vertical"]
                          ? _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src:
                                  _vm.ruleForm["load_img_vertical"] + "!m640",
                                fit: "cover",
                              },
                            })
                          : _c("i", { staticClass: "el-icon-plus" }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v("竖屏加载页(1242*2688)")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml20" }, [
                    _c(
                      "div",
                      {
                        staticClass: "upload-view item-flex-center mr20",
                        staticStyle: { width: "140px", height: "78px" },
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("load_img_horizontal")
                          },
                        },
                      },
                      [
                        _vm.ruleForm["load_img_horizontal"]
                          ? _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src:
                                  _vm.ruleForm["load_img_horizontal"] + "!m640",
                                fit: "cover",
                              },
                            })
                          : _c("i", { staticClass: "el-icon-plus" }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v("横屏加载页(2688*1242)")]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  { staticClass: "w400" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "进度条颜色：" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.ruleForm.progress_bar_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "progress_bar_color", $$v)
                            },
                            expression: "ruleForm.progress_bar_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w400" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "文字颜色：" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.ruleForm.word_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "word_color", $$v)
                            },
                            expression: "ruleForm.word_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "地块图片：" } }, [
                _c(
                  "div",
                  [
                    _vm._v("\n          1:1，格式png，建议尺寸300×300像素，请"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.downloadTemplate },
                      },
                      [_vm._v("下载模板")]
                    ),
                    _vm._v("后按模板设计\n        "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex mt10" },
                  _vm._l(4, function (m) {
                    return _c(
                      "div",
                      {
                        key: "block" + m,
                        staticClass: "upload-view item-flex-center mr20",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("land_img" + m)
                          },
                        },
                      },
                      [
                        _vm.ruleForm["land_img" + m]
                          ? _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.ruleForm["land_img" + m] + "!m640",
                                fit: "cover",
                              },
                            })
                          : _c("i", { staticClass: "el-icon-plus" }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "地块图标：" } }, [
                _c(
                  "div",
                  [
                    _vm._v("\n          1:1，格式png，建议尺寸300×300像素，请"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.downloadTemplate },
                      },
                      [_vm._v("下载模板")]
                    ),
                    _vm._v("后按模板设计\n        "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex mt10" },
                  _vm._l(4, function (m) {
                    return _c(
                      "div",
                      {
                        key: "block" + m,
                        staticClass: "upload-view item-flex-center mr20",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("land_icon" + m)
                          },
                        },
                      },
                      [
                        _vm.ruleForm["land_icon" + m]
                          ? _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.ruleForm["land_icon" + m] + "!m640",
                                fit: "cover",
                              },
                            })
                          : _c("i", { staticClass: "el-icon-plus" }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "分享页：" } }, [
                _c("div", { staticClass: "flex mt10" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "upload-view item-flex-center mr20",
                        staticStyle: { width: "140px", height: "186px" },
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("share_img")
                          },
                        },
                      },
                      [
                        _vm.ruleForm["share_img"]
                          ? _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.ruleForm["share_img"] + "!m640",
                                fit: "cover",
                              },
                            })
                          : _c("i", { staticClass: "el-icon-plus" }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v("建议尺寸：960*1500")]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveAction },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.showAddNum,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddNum = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "demo-ruleForm", attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新增街区数：" } },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: { placeholder: "请输入数量" },
                    model: {
                      value: _vm.addNum,
                      callback: function ($$v) {
                        _vm.addNum = _vm._n($$v)
                      },
                      expression: "addNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveAddNumAction },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }