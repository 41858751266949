var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    _vm._l(_vm.viewData, function (m, i) {
      return _c(
        "section",
        {
          key: i,
          style: {
            background: _vm.facade.item_background,
            margin: "0 " + _vm.facade.page_margin + "px",
            borderRadius: _vm.facade.radius + "px",
            marginTop: (i > 0 ? _vm.facade.item_margin : 0) + "px",
            overflow: "hidden",
          },
        },
        [
          _c("section", { style: { padding: _vm.imgPadding } }, [
            m.id && _vm.miniAppid
              ? _c(
                  "a",
                  {
                    staticClass: "weapp_image_link",
                    attrs: {
                      "data-miniprogram-appid": _vm.miniAppid,
                      "data-miniprogram-path":
                        "pages/auctionDetail/index?id=" + m.id,
                      "data-miniprogram-nickname": _vm.miniName,
                      href: "",
                      "data-miniprogram-type": "image",
                      "data-miniprogram-servicetype": "2",
                      "wah-hotarea": "click",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "rich_pages wxw-img",
                      staticStyle: { display: "block" },
                      attrs: {
                        "data-ratio": "1",
                        "data-s": "300,640",
                        "data-fail": "0",
                        "data-src": m.image,
                        src: m.image,
                        alt: "",
                      },
                    }),
                  ]
                )
              : _c("img", {
                  staticStyle: { display: "block" },
                  attrs: { src: m.image, alt: "" },
                }),
          ]),
          _vm._v(" "),
          m.name || m.desc
            ? _c(
                "section",
                {
                  style: {
                    padding:
                      "0 " +
                      _vm.facade.item_padding +
                      "px " +
                      _vm.facade.item_padding +
                      "px " +
                      _vm.facade.item_padding +
                      "px",
                  },
                },
                [
                  m.name
                    ? _c(
                        "section",
                        {
                          style: {
                            paddingTop: _vm.facade.title_margin + "px",
                            color: _vm.facade.title_color,
                            fontWeight: _vm.facade.title_weight,
                            fontSize: _vm.facade.title_size + "px",
                            textAlign: _vm.facade.title_text_align,
                          },
                        },
                        [_vm._v(_vm._s(m.name))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  m.desc
                    ? _c(
                        "section",
                        {
                          style: {
                            marginTop: _vm.facade.desc_margin + "px",
                            color: _vm.facade.desc_color,
                            fontWeight: _vm.facade.desc_weight,
                            fontSize: _vm.facade.desc_size + "px",
                            textAlign: _vm.facade.desc_text_align,
                            lineHeight: _vm.facade.desc_line_height + "px",
                            whiteSpace: "pre-wrap",
                          },
                        },
                        [_vm._v(_vm._s(m.desc))]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }