"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import empty from './empty.vue'
var _default = {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    min: {
      type: [String, Number],
      default: 20
    },
    max: {
      type: [String, Number],
      default: 40
    }
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    select: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', Number(value));
      }
    }
  }
};
exports.default = _default;