"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    venueId: {
      type: String,
      default: ""
    },
    changeCount: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      showSearch: true,
      albumList: [],
      quertParams: {
        page: 1,
        limit: 3
      },
      total: 0,
      listLoading: false,
      changeAlbum: [],
      changeAlbumIds: []
    };
  },
  created: function created() {
    this.loadAlbum();
  },
  methods: {
    loadAlbum: function loadAlbum() {
      var _this = this;
      if (this.venueId) {
        this.quertParams.venue_id = this.venueId;
      }
      this.listLoading = true;
      (0, _auction.albumlist)(this.quertParams).then(function (res) {
        _this.albumList = res.data.list;
        _this.total = res.data.count;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    changeOkAlbum: function changeOkAlbum(m) {
      this.changeAlbum.push(m);
      this.changeAlbumIds = this.changeAlbum.map(function (m) {
        return m.id;
      });
      if (this.changeCount == 1) {
        this.$emit("changeOk", this.changeAlbum);
      }
    },
    changeCancelAlbum: function changeCancelAlbum(id) {
      var index = this.changeAlbumIds.indexOf(id);
      if (index !== -1) {
        this.changeAlbum.splice(index, 1);
      }
      this.changeAlbumIds = this.changeAlbum.map(function (m) {
        return m.id;
      });
    },
    isCloseAction: function isCloseAction() {
      this.$emit("isClose");
    }
  }
};
exports.default = _default;