"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var plugins = {
  namespaced: true,
  state: {
    /**
     * 插接列表信息
     * @type {Boolean}
     */
    pluginsList: {}
  },
  mutations: {
    /**
     * 设置插件列表数据
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPlugins: function setPlugins(state, data) {
      state.pluginsList = data;
    }
  },
  getters: {
    /**
     * 判断是否安装
     * @param  {[type]} state) [description]
     * @return {[type]}        [description]
     */
    is_install: function is_install(state) {
      return function (name) {
        return state.todos.find(function (todo) {
          return todo.name === name;
        });
      };
    },
    /**
     * 获取插件配置信息
     * @param  {[type]} state) [description]
     * @return {[type]}        [description]
     */
    manifest: function manifest(state) {
      return function (name) {
        var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        if (state.pluginsList[name]) {
          if (keys) {
            return state.pluginsList[name][keys];
          } else {
            return state.pluginsList[name];
          }
        } else {
          return false;
        }
      };
    }
  },
  actions: {
    /**
     * 读取异步配置信息
     * @param  {[type]} options.commit [description]
     * @return {[type]}                [description]
     */
    getPlugins: function getPlugins(_ref) {
      var commit = _ref.commit;
      var $heshop = this._vm.$heshop;
      $heshop.plugin('get', {
        include: 'empty',
        model: 'empty'
      }).then(function (res) {
        commit('setPlugins', res);
      }).catch(function (err) {
        console.error(err);
      });

      // let $storageKey = 'ndfWxbOhLIIHVZeR';
      // if (localStorage.getItem($storageKey)) {
      //   let data = localStorage.getItem($storageKey);
      //   commit('setPlugins', JSON.parse(data));
      // } else {
      //   $heshop
      //     .plugin('get', { include: 'empty', model: 'empty' })
      //     .then(function(res) {
      //       commit('setPlugins', res);
      //       localStorage.setItem($storageKey, JSON.stringify(res));
      //     })
      //     .catch(function(err) {
      //       console.error(err);
      //     });
      // }
    },

    /**
     * 商品购物
     * @return {[type]} [description]
     */
    onGoods: function onGoods(_ref2) {
      var commit = _ref2.commit;
    },
    /**
     * 完成下单
     * @return {[type]} [description]
     */
    onOrder: function onOrder(_ref3) {
      var commit = _ref3.commit;
    },
    /**
     * 每日签到
     * @return {[type]} [description]
     */
    onSignin: function onSignin(_ref4) {
      var commit = _ref4.commit;
    },
    /**
     * 连续签到
     * @return {[type]} [description]
     */
    onSustain: function onSustain(_ref5) {
      var commit = _ref5.commit;
    },
    /**
     * 分享转发
     * @return {[type]} [description]
     */
    onShare: function onShare(_ref6) {
      var state = _ref6.state,
        commit = _ref6.commit;
      var config = state.pluginsList['task']['share'];
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.plugin('post', {
          include: 'task',
          model: 'task'
        }, {
          number: 1,
          keyword: 'share'
        }).then(function (res) {
          resolve(res);
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    /**
     * 商品浏览
     * @return {[type]} [description]
     */
    onBrowse: function onBrowse(_ref7) {
      var state = _ref7.state,
        commit = _ref7.commit;
      var config = state.pluginsList['task']['share'];
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.plugin('post', {
          include: 'task',
          model: 'task'
        }, {
          number: 1,
          keyword: 'browse'
        }).then(function (res) {
          resolve(res);
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    /**
     * 要求好友
     * @return {[type]} [description]
     */
    onInvite: function onInvite() {},
    /**
     * 完善信息
     * @return {[type]} [description]
     */
    onPerfect: function onPerfect() {},
    /**
     * 绑定手机
     * @return {[type]} [description]
     */
    onBinding: function onBinding() {}
  }
};
var _default = plugins;
exports.default = _default;