var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "covermask", attrs: { "data-type": "forbid" } },
      [_c("span", [_vm._v("释放鼠标将组建添加到此处")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }