var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title:
          _vm.content.style == 1 ? "轮播组件、通栏竖图组件" : "通栏横图组件",
        is_tab: false,
      },
    },
    [
      _vm.content.style == 1
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("choose", {
                  staticClass: "mt20",
                  attrs: {
                    data: _vm.pointerData,
                    title: "指示器样式",
                    margin: "0 10px 0 0",
                    width: "75px",
                    height: "30px",
                  },
                  model: {
                    value: _vm.facade.indicator_style,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "indicator_style", $$v)
                    },
                    expression: "facade.indicator_style",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.indicator_align,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "indicator_align", $$v)
                          },
                          expression: "facade.indicator_align",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "left" } }, [
                          _vm._v("居左"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "center" } }, [
                          _vm._v("居中"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "right" } }, [
                          _vm._v("居右"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "选中颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.indicator_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_color", $$v)
                        },
                        expression: "facade.indicator_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场底色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.item_background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_background", $$v)
                        },
                        expression: "facade.item_background",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.style == 2
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.column_title_font_size,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "column_title_font_size", $$v)
                        },
                        expression: "facade.column_title_font_size",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "上下间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.item_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_margin", $$v)
                        },
                        expression: "facade.item_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场底色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.item_background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_background", $$v)
                        },
                        expression: "facade.item_background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center" },
                  [
                    _c("div", { staticClass: "ft14" }, [
                      _vm._v("专场名自动换行"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "flex-one",
                      staticStyle: {
                        "font-size": "12px",
                        color: "#999",
                        margin: "0 6px",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#009406",
                        "inactive-color": "#cccccc",
                      },
                      model: {
                        value: _vm.facade.name_wrap,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "name_wrap", $$v)
                        },
                        expression: "facade.name_wrap",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }