var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "选项卡" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("选项及内容设置"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "pt10" },
                _vm._l(_vm.content.data, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "kuang item-align-center pt10 pb10",
                      class: { yinying: index === _vm.content.active },
                      on: {
                        mouseenter: function ($event) {
                          return _vm.mouseOver(index)
                        },
                      },
                    },
                    [
                      index === 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-down-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.goDown(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      index > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-up-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.goUp(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "del-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "120px", "margin-left": "5px" },
                        attrs: { type: "text", placeholder: "设置文字" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                          },
                        },
                        model: {
                          value: item.title,
                          callback: function ($$v) {
                            _vm.$set(item, "title", $$v)
                          },
                          expression: "item.title",
                        },
                      }),
                      _vm._v(" "),
                      item.link.dataType
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end ml10",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeActive(index)
                                },
                              },
                            },
                            [
                              item.link.dataType === "category"
                                ? _c("div", { staticClass: "ft14 flex-one" }, [
                                    _c(
                                      "div",
                                      { staticClass: "align-r color-link" },
                                      [_vm._v("设置数据和内容风格")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-r color-gray ft12",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.title) +
                                            "分类下" +
                                            _vm._s(item.link.categoryTypeTxt)
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c("div", { staticClass: "ft14 flex-one" }, [
                                    _c(
                                      "div",
                                      { staticClass: "align-r color-link" },
                                      [_vm._v("设置数据和内容风格")]
                                    ),
                                    _vm._v(" "),
                                    item.link.dataType === "auction"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "align-r color-gray ft12",
                                          },
                                          [
                                            item.link.content.type === 1 &&
                                            item.link.content.system_type === 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "预展+进行+结束的拍品"
                                                  ),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 2
                                              ? _c("span", [
                                                  _vm._v("预展+进行中的拍品"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 3
                                              ? _c("span", [
                                                  _vm._v("预展中的拍品"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 4
                                              ? _c("span", [
                                                  _vm._v("进行中的拍品"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 5
                                              ? _c("span", [
                                                  _vm._v("已结束的拍品"),
                                                ])
                                              : item.link.content.type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "手动选择 " +
                                                      _vm._s(
                                                        item.link.content.data
                                                          .length
                                                      ) +
                                                      "件拍品"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : item.link.dataType === "auctionAlbum"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "align-r color-gray ft12",
                                          },
                                          [
                                            item.link.content.type === 1 &&
                                            item.link.content.system_type === 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "预展+进行+结束的专场"
                                                  ),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 2
                                              ? _c("span", [
                                                  _vm._v("预展+进行中的专场"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 3
                                              ? _c("span", [
                                                  _vm._v("预展中的专场"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 4
                                              ? _c("span", [
                                                  _vm._v("进行中的专场"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 5
                                              ? _c("span", [
                                                  _vm._v("已结束的专场"),
                                                ])
                                              : item.link.content.type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "手动选择 " +
                                                      _vm._s(
                                                        item.link.content.data
                                                          .length
                                                      ) +
                                                      "件专场"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : item.link.dataType === "auctionMeeting"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "align-r color-gray ft12",
                                          },
                                          [
                                            item.link.content.type === 1 &&
                                            item.link.content.system_type === 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "预展+进行+结束的拍卖会"
                                                  ),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 2
                                              ? _c("span", [
                                                  _vm._v("预展+进行中的拍卖会"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 3
                                              ? _c("span", [
                                                  _vm._v("预展中的拍卖会"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 4
                                              ? _c("span", [
                                                  _vm._v("进行中的拍卖会"),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 5
                                              ? _c("span", [
                                                  _vm._v("已结束的拍卖会"),
                                                ])
                                              : item.link.content.type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "手动选择 " +
                                                      _vm._s(
                                                        item.link.content.data
                                                          .length
                                                      ) +
                                                      "件拍卖会"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : item.link.dataType === "product"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "align-r color-gray ft12",
                                          },
                                          [
                                            item.link.content.type === 1 &&
                                            item.link.content.system_type === 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    "销售中商品+已售罄商品"
                                                  ),
                                                ])
                                              : item.link.content.type === 1 &&
                                                item.link.content
                                                  .system_type === 2
                                              ? _c("span", [
                                                  _vm._v("展示销售中商品"),
                                                ])
                                              : item.link.content.type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "手动选择 " +
                                                      _vm._s(
                                                        item.link.content.data
                                                          .length
                                                      ) +
                                                      "件商品"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : item.link.dataType === "nftCollection"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "align-r color-gray ft12",
                                          },
                                          [
                                            item.link.content.style === 1
                                              ? _c("span", [
                                                  _vm._v("数字藏品单列模式"),
                                                ])
                                              : item.link.content.style === 2
                                              ? _c("span", [
                                                  _vm._v("数字藏品日历模式"),
                                                ])
                                              : item.link.content.type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    "手动选择 " +
                                                      _vm._s(
                                                        item.link.content.data
                                                          .length
                                                      ) +
                                                      "件数字藏品"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end color-link ml10",
                              on: {
                                click: function ($event) {
                                  return _vm.setData(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14 flex-one" }, [
                                _c(
                                  "div",
                                  { staticClass: "align-r color-link" },
                                  [_vm._v("设置数据和内容风格")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "align-r color-gray ft12" },
                                  [_vm._v("未设置数据")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://artimg2.artart.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAdditem },
                  },
                  [_vm._v("新增一条")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://artimg2.artart.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAddCategoryitem },
                  },
                  [_vm._v("分类批量创建")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "导航位置" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.nav_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nav_style", $$v)
                        },
                        expression: "facade.nav_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("居左")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("居中")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面跟随" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-one",
                          staticStyle: {
                            "font-size": "12px",
                            color: "#999",
                            margin: "0 6px",
                          },
                        },
                        [_vm._v("页面上滑时导航固定在顶部")]
                      ),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#009406",
                          "inactive-color": "#cccccc",
                        },
                        model: {
                          value: _vm.facade.fixed_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "fixed_top", $$v)
                          },
                          expression: "facade.fixed_top",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("数据无限加载"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-one fontW400 color-gray ft12",
                      staticStyle: { margin: "0 12px" },
                    },
                    [
                      _vm._v(
                        "页面下滑时，无限加载全部数据，此选项在一个页面中只能使用一次"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#009406",
                      "inactive-color": "#cccccc",
                    },
                    model: {
                      value: _vm.content.loadMore,
                      callback: function ($$v) {
                        _vm.$set(_vm.content, "loadMore", $$v)
                      },
                      expression: "content.loadMore",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "导航背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选项高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 300 },
                    model: {
                      value: _vm.facade.nav_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "nav_height", $$v)
                      },
                      expression: "facade.nav_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "左侧边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.page_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_padding", $$v)
                      },
                      expression: "facade.page_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "底部边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.content_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_margin", $$v)
                      },
                      expression: "facade.content_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选项间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.item_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_padding", $$v)
                      },
                      expression: "facade.item_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.text_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_color", $$v)
                      },
                      expression: "facade.text_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.text_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "text_style", $$v)
                        },
                        expression: "facade.text_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 40 },
                    model: {
                      value: _vm.facade.text_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_size", $$v)
                      },
                      expression: "facade.text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选中颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.text_active_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_active_color", $$v)
                      },
                      expression: "facade.text_active_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选中字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 40 },
                    model: {
                      value: _vm.facade.active_text_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "active_text_size", $$v)
                      },
                      expression: "facade.active_text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选中风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.active_text_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "active_text_style", $$v)
                        },
                        expression: "facade.active_text_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上面距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: _vm.facade.nav_height },
                    model: {
                      value: _vm.facade.text_margin_top,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_margin_top", $$v)
                      },
                      expression: "facade.text_margin_top",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字行距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 200 },
                    model: {
                      value: _vm.facade.lineHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lineHeight", $$v)
                      },
                      expression: "facade.lineHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "z-index-drawer",
          attrs: { visible: _vm.drawer, direction: "rtl" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "item-align-center ft16 fontW500 color-black",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("内容设置")]
          ),
          _vm._v(" "),
          _c(
            "el-scrollbar",
            { style: _vm.scrollHeight },
            [
              _vm.drawer
                ? _c(
                    _vm.modules[_vm.content.data[_vm.content.active].link.name]
                      .attribute,
                    {
                      tag: "component",
                      attrs: {
                        "c-data": _vm.content.data[_vm.content.active].link,
                        "load-more": _vm.content.loadMore,
                      },
                    }
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDataType,
            width: "600px",
            title: "请选择展示数据内容",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDataType = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "display-flex flex-wrap" },
            _vm._l(_vm.panels.advanced.children, function (mounted, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "con-item",
                  on: {
                    click: function ($event) {
                      return _vm.changeDataType(mounted)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "con-item-icon item-flex-center" }, [
                    _c("img", { attrs: { src: mounted.icon, alt: "" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "con-item-name align-c ft14" }, [
                    _vm._v(_vm._s(mounted.title)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }