var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageInfo
    ? _c(
        "control",
        { attrs: { title: "页面基础设置", is_tab: false } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "排版" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "item-align-center",
                      staticStyle: { height: "30px" },
                      model: {
                        value: _vm.pageInfo.img_position,
                        callback: function ($$v) {
                          _vm.$set(_vm.pageInfo, "img_position", $$v)
                        },
                        expression: "pageInfo.img_position",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "center" },
                        },
                        [_c("div", [_vm._v("默认")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "right" },
                        },
                        [_c("div", [_vm._v("图片居右")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "left" },
                        },
                        [_c("div", [_vm._v("图片居左")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "主题颜色" } },
                [
                  _c("div", { staticClass: "flex flex-wrap" }, [
                    _c("div", {
                      staticClass: "color",
                      class: { curr: _vm.pageInfo.theme == 1 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(1)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color1",
                      class: { curr: _vm.pageInfo.theme == 2 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(2)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color2",
                      class: { curr: _vm.pageInfo.theme == 3 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(3)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color3",
                      class: { curr: _vm.pageInfo.theme == 4 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(4)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color4",
                      class: { curr: _vm.pageInfo.theme == 5 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(5)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color5",
                      class: { curr: _vm.pageInfo.theme == 6 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(6)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "color color6",
                      class: { curr: _vm.pageInfo.theme == 7 },
                      on: {
                        click: function ($event) {
                          return _vm.setColor(7)
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "page_margin", $$v)
                      },
                      expression: "pageInfo.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "专场间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.item_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "item_margin", $$v)
                      },
                      expression: "pageInfo.item_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "专场背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.pageInfo.item_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "item_background", $$v)
                      },
                      expression: "pageInfo.item_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.item_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "item_padding", $$v)
                      },
                      expression: "pageInfo.item_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "radius", $$v)
                      },
                      expression: "pageInfo.radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.title_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "title_margin", $$v)
                      },
                      expression: "pageInfo.title_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.pageInfo.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "title_color", $$v)
                      },
                      expression: "pageInfo.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.title_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "title_size", $$v)
                      },
                      expression: "pageInfo.title_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.pageInfo.title_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.pageInfo, "title_weight", $$v)
                        },
                        expression: "pageInfo.title_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.pageInfo.title_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.pageInfo, "title_text_align", $$v)
                        },
                        expression: "pageInfo.title_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.desc_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "desc_margin", $$v)
                      },
                      expression: "pageInfo.desc_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.pageInfo.desc_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "desc_color", $$v)
                      },
                      expression: "pageInfo.desc_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.desc_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "desc_size", $$v)
                      },
                      expression: "pageInfo.desc_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.pageInfo.desc_line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "desc_line_height", $$v)
                      },
                      expression: "pageInfo.desc_line_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.pageInfo.desc_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.pageInfo, "desc_weight", $$v)
                        },
                        expression: "pageInfo.desc_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.pageInfo.desc_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.pageInfo, "desc_text_align", $$v)
                        },
                        expression: "pageInfo.desc_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }