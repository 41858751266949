"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _user = require("@/api/user");
require("@/assets/js/canvas-nest.min");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import notice from "@/libs/notice";
var _default = {
  name: "Login",
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 6) {
          callback(new Error("请输入不少于6位数的密码"));
        } else {
          callback();
        }
      }
    };
    return {
      fullWidth: document.body.clientWidth,
      swiperOption: {
        pagination: {
          el: ".pagination"
        },
        autoplay: {
          enabled: true,
          disableOnInteraction: false,
          delay: 3000
        }
      },
      captchatImg: "",
      loginLogo: "",
      swiperList: [],
      loginForm: {
        account: "",
        password: "",
        key: "",
        code: ""
      },
      loginRules: {
        account: [{
          required: true,
          trigger: "blur",
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: "blur",
          validator: validatePassword
        }],
        code: [{
          required: true,
          message: "请输入正确的验证码",
          trigger: "blur"
        }]
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      loginflag: false,
      merchantlist: [],
      stafftoken: ''
    };
  },
  watch: {
    $route: {
      fullWidth: function fullWidth(val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = val;
          this.timer = true;
          var that = this;
          setTimeout(function () {
            // 打印screenWidth变化的值
            that.timer = false;
          }, 400);
        }
      },
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;
    document.onkeydown = function (e) {
      if (_this.$route.path.indexOf("login") !== -1) {
        var key = window.event.keyCode;
        if (key === 13) {
          _this.handleLogin();
        }
      }
    };
    window.addEventListener("resize", this.handleResize);
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getInfo();
    this.$nextTick(function () {
      if (_this2.screenWidth < 768) {
        // document
        //   .getElementsByTagName("canvas")[0]
        //   .removeAttribute("class", "index_bg");
      } else {
        // document.getElementsByTagName("canvas")[0].className = "index_bg";
      }
    });
    this.getCaptcha();
  },
  beforeCreate: function beforeCreate() {
    if (this.fullWidth < 768) {
      // document
      //   .getElementsByTagName("canvas")[0]
      //   .removeAttribute("class", "index_bg");
    } else {
      // document.getElementsByTagName("canvas")[0].className = "index_bg";
    }
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    // document
    //   .getElementsByTagName("canvas")[0]
    //   .removeAttribute("class", "index_bg");
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    close: function close() {
      this.loginflag = false;
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _user.loginConfigApi)().then(function (_ref) {
        var data = _ref.data;
        _this3.swiperList = data.login_banner;
        _this3.loginLogo = data.login_logo;
        _jsCookie.default.set("MerInfo", JSON.stringify(data));
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    getCaptcha: function getCaptcha() {
      var _this4 = this;
      (0, _user.captchaApi)().then(function (_ref3) {
        var data = _ref3.data;
        _this4.captchatImg = data.captcha;
        _this4.loginForm.key = data.key;
      }).catch(function (_ref4) {
        var message = _ref4.message;
        console.log(message);
      });
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd: function showPwd() {
      var _this5 = this;
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(function () {
        _this5.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this6 = this;
      this.$refs["loginForm"].validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          _this6.$store.dispatch("user/login", _this6.loginForm).then(function (res) {
            console.log(res);
            if (res.login_status) {
              _this6.$router.push({
                path: _this6.redirect || "/",
                query: _this6.otherQuery
              });
            } else {
              _this6.stafftoken = res.staff_login_token;
              _this6.merchantlist = res.merchantAdminData;
              _this6.loginflag = true;
            }
            _this6.loading = false;
            // this.$root.notice = notice(res.token);
          }).catch(function (error) {
            console.log(error);
            _this6.loginForm.code = "";
            // this.$message.error(res.message)
            _this6.loading = false;
            _this6.getCaptcha();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    staffLogin: function staffLogin(item) {
      var _this7 = this;
      var json = {
        merchant_admin_id: item.merchant_admin_id,
        staff_login_token: this.stafftoken
      };
      this.$store.dispatch("user/stafflogin", json).then(function (res) {
        console.log(res);
        _this7.$router.push({
          path: _this7.redirect || "/",
          query: _this7.otherQuery
        });
        _this7.loading = false;
        // this.$root.notice = notice(res.token);
      }).catch(function (error) {
        console.log(error);
        // this.$message.error(res.message)
        _this7.loading = false;
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    handleResize: function handleResize(event) {
      this.fullWidth = document.body.clientWidth;
      // if (this.fullWidth < 768) {
      //   document
      //     .getElementsByTagName("canvas")[0]
      //     .removeAttribute("class", "index_bg");
      // } else {
      //   document.getElementsByTagName("canvas")[0].className = "index_bg";
      // }
    }
  }
};
exports.default = _default;