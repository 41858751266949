var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "商品组件", is_tab: false } },
    [
      _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "banner-control",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("分类列表页面设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fontW400 ft14 color-black",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v("分类列数")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.category_column,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "category_column", $$v)
                            },
                            expression: "facade.category_column",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("单列"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("双列"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("三列"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("商品列表页面设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "背景颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#f7f7f7" },
                      model: {
                        value: _vm.facade.background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "background", $$v)
                        },
                        expression: "facade.background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.bg_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景图设置" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.bg_image_mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bg_image_mode", $$v)
                          },
                          expression: "facade.bg_image_mode",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("宽度固定上下居中"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("满屏上下左右截取"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("宽度固定上下平铺循环展示"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("宽度固定高度自适应顶部展示"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fontW400 ft14 color-black",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v("默认模板")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.listShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "listShow", $$v)
                            },
                            expression: "facade.listShow",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("单列"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("双列"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("列表"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fontW400 ft14 color-black",
                      staticStyle: { width: "80px" },
                    },
                    [_vm._v("分类位置")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.category_area,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "category_area", $$v)
                            },
                            expression: "facade.category_area",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("左侧"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("顶部"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center mt20" },
                  [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("展示底部导航"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    }),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#009406",
                        "inactive-color": "#cccccc",
                      },
                      model: {
                        value: _vm.facade.show_footer,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "show_footer", $$v)
                        },
                        expression: "facade.show_footer",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center mt20" },
                  [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("展示销量"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    }),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#009406",
                        "inactive-color": "#cccccc",
                      },
                      model: {
                        value: _vm.facade.show_sale,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "show_sale", $$v)
                        },
                        expression: "facade.show_sale",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "页面边距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.page_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "page_margin", $$v)
                        },
                        expression: "facade.page_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "上圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.top_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "top_radius", $$v)
                        },
                        expression: "facade.top_radius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "下圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.bottom_radius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bottom_radius", $$v)
                        },
                        expression: "facade.bottom_radius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_color", $$v)
                        },
                        expression: "facade.title_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "价格颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.price_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "price_color", $$v)
                        },
                        expression: "facade.price_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "销量颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.sales_volumes_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "sales_volumes_color", $$v)
                        },
                        expression: "facade.sales_volumes_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("图片展示方式"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center flex-wrap" },
                  _vm._l(_vm.imgStyleData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "change-type",
                        staticStyle: {
                          "margin-right": "20px",
                          "margin-top": "15px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            class: {
                              curr: item.value === _vm.facade.img_style,
                            },
                          },
                          [
                            _c("label", [
                              _c("img", { attrs: { src: item.src } }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.facade.img_style,
                                    expression: "facade.img_style",
                                  },
                                ],
                                staticClass: "choose-input",
                                attrs: { type: "radio", name: "choose" },
                                domProps: {
                                  value: item.value,
                                  checked: _vm._q(
                                    _vm.facade.img_style,
                                    item.value
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.facade,
                                      "img_style",
                                      item.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "name color-gray align-c ft12" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }