var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(2, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index == 0 },
                style:
                  "width: 100%;margin-top:" +
                  _vm.facade.item_margin / 2 +
                  "px;border-top-right-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-top-left-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-bottom-left-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;border-bottom-right-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;background: " +
                  _vm.facade.status_background +
                  " url(" +
                  _vm.facade.status_bg_image +
                  ") repeat left center;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "time",
                    style:
                      "color: " +
                      _vm.facade.status_btn_color +
                      ";font-size: " +
                      _vm.facade.status_font_size / 2 +
                      "px;background: " +
                      _vm.facade.status_btn_bg_color +
                      ";",
                  },
                  [_vm._v("敬请期待 4.12 09:00 开售")]
                ),
                _vm._v(" "),
                _vm._m(0, true),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text color-black",
                    style:
                      "color: " +
                      _vm.facade.title_color +
                      ";font-size: " +
                      _vm.facade.title_font_size / 2 +
                      "px;font-weight: " +
                      _vm.facade.title_font_weight +
                      ";",
                  },
                  [_vm._v("刺杀罪恶之文化侵蚀")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tags flex align-items-c flex-wrap",
                    style: "font-size: " + _vm.facade.btn_font_size / 2 + "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "num",
                        style:
                          "color: " +
                          _vm.facade.stock_color +
                          ";font-size: " +
                          _vm.facade.stock_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("限量500份")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bottom flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "l-price",
                      style: "color: " + _vm.facade.price_color + ";",
                    },
                    [
                      _vm._v("￥"),
                      _c(
                        "span",
                        {
                          style:
                            "font-size: " +
                            _vm.facade.price_font_size / 2 +
                            "px;",
                        },
                        [_vm._v("100")]
                      ),
                    ]
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: " +
              (_vm.isComponent ? 30 : 0) +
              "px " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(3, function (item, index) {
            return _c("div", { key: index, staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  class: { mt0: index == 0 },
                  style:
                    "margin-top:" +
                    _vm.facade.item_margin / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.status_bg_image +
                    ") repeat left center;background-size: 100%;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src:
                            index == 0
                              ? "https://artimg2.artart.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                              : "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                          fit: _vm.facade.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-r" }, [
                    _c(
                      "div",
                      {
                        staticClass: "name line1",
                        style:
                          "color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("坠落·光在前方照亮的地方")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "count",
                        style:
                          "color: " +
                          _vm.facade.stock_color +
                          ";font-size: " +
                          _vm.facade.stock_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("限量500份")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price",
                        style: "color: " + _vm.facade.price_color + ";",
                      },
                      [
                        _vm._v("￥"),
                        _c(
                          "span",
                          {
                            style:
                              "font-size: " +
                              _vm.facade.price_font_size / 2 +
                              "px;",
                          },
                          [_vm._v("100")]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }