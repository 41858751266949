var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", [
        _c(
          "div",
          { staticClass: "list" },
          _vm._l(_vm.tableData.data, function (m, i) {
            return _c(
              "div",
              { key: "list" + i, staticClass: "item" },
              [
                m.is_buy
                  ? [
                      _c("div", { staticClass: "has" }, [_vm._v("已购")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "has-bg" }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "img" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: m.exhibition_cover + "!m640",
                        alt: "",
                        fit: "cover",
                      },
                    }),
                    _vm._v(" "),
                    !m.is_buy
                      ? _c("div", { staticClass: "pre" }, [_vm._v("预览")])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "name-view flex align-items-c" }, [
                  _c("div", { staticClass: "name line1" }, [
                    _vm._v(_vm._s(m.exhibition_title)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price" }, [
                    _vm._v("￥" + _vm._s(m.price)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _vm._v("作者：" + _vm._s(m.author)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "buy flex align-items-c" },
                  [
                    _c("div", { staticClass: "l flex-one" }, [
                      _vm._v("展位：" + _vm._s(m.config.booths) + "个"),
                    ]),
                    _vm._v(" "),
                    !m.is_buy
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.payAction(m)
                              },
                            },
                          },
                          [_vm._v("购买")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block mt20 flex" },
          [
            _c("el-pagination", {
              staticStyle: { "text-align": "center" },
              attrs: {
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支付",
            visible: _vm.showPay,
            "close-on-click-modal": false,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPay = $event
            },
          },
        },
        [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              "购买 " +
                _vm._s(_vm.payTitle) +
                " " +
                _vm._s(_vm.formatPriceNumber(_vm.payPrice, 2)) +
                " 元"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "erweima item-flex-center mt10" }, [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.payImg },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20", staticStyle: { "text-align": "center" } },
            [_vm._v("请使用微信扫一扫支付")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20 mb20" },
            [
              _c("el-button", { on: { click: _vm.closePayAction } }, [
                _vm._v("取消支付"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.buyEndAction } },
                [_vm._v("已支付完成")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }