var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index === 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.image, fit: "cover" },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text color-black" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2 && _vm.content.img_style != 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "two-list",
                class: { mt0: index < 2, mr0: index % 2 !== 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  marginRight: _vm.facade.item_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding) /
                      4 +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    style: {
                      width:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding) /
                          4 +
                        "px",
                      height:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding) /
                          4 +
                        "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [_vm._v(_vm._s(item.name))]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 3
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: {
              padding: "0 " + _vm.facade.page_padding / 2 + "px",
              justifyContent: "flex-start",
            },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "three-list",
                class: { mt0: index < 3, mr0: index % 3 === 2 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  marginRight: _vm.facade.item_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding -
                      _vm.facade.item_padding) /
                      6 +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    style: {
                      width:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding -
                          _vm.facade.item_padding) /
                          6 +
                        "px",
                      height:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding -
                          _vm.facade.item_padding) /
                          6 +
                        "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.image, fit: "cover" },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [_vm._v(_vm._s(item.name))]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 4
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "list-horizontal",
                class: { mt0: index < 1 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "list-h-left",
                    style: {
                      width: _vm.facade.img_width / 2 + "px",
                      height: _vm.facade.img_height / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "list-h-right" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 5
      ? _c("div", { staticClass: "swiper-list-box" }, [
          _c(
            "div",
            {
              staticClass: "swiper-content",
              style: { margin: "0 " + _vm.facade.page_padding / 2 + "px" },
            },
            _vm._l(_vm.list, function (item, index) {
              return index < 2
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "swiper-list",
                      style: {
                        marginRight: _vm.facade.item_padding / 2 + "px",
                        borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                        borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                        borderBottomRightRadius:
                          _vm.facade.status_radius / 2 + "px",
                        borderBottomLeftRadius:
                          _vm.facade.status_radius / 2 + "px",
                        background: _vm.facade.status_background,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "swiper-list-img",
                          style: {
                            width: _vm.facade.img_width / 2 + "px",
                            height: _vm.facade.img_height / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.content.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                              on: { error: _vm.error },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot item-flex-center",
                                  style: {
                                    width: _vm.facade.img_width / 2 + "px",
                                    height: _vm.facade.img_height / 2 + "px",
                                  },
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text",
                          style: {
                            width: _vm.facade.img_width / 2 - 20 + "px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 7
      ? _c("div", { staticClass: "swiper-list-box" }, [
          _c(
            "div",
            {
              staticClass: "swiper-content",
              style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
            },
            _vm._l(_vm.list, function (item, index) {
              return index < 4 && index % 2 === 0
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "swiper-list",
                      style: {
                        marginRight: _vm.facade.item_padding / 2 + "px",
                        borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                        borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                        borderBottomRightRadius:
                          _vm.facade.status_radius / 2 + "px",
                        borderBottomLeftRadius:
                          _vm.facade.status_radius / 2 + "px",
                        background: _vm.facade.status_background,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "swiper-list-img",
                          style: {
                            width: _vm.facade.img_width / 2 + "px",
                            height: _vm.facade.img_height / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.content.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                              on: { error: _vm.error },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text",
                          style: {
                            width: _vm.facade.img_width / 2 - 20 + "px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "swiper-content",
              style: {
                marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                padding: "0 " + _vm.facade.page_padding / 2 + "px",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return index < 4 && index % 2 !== 0
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "swiper-list",
                      style: {
                        marginRight: _vm.facade.item_padding / 2 + "px",
                        borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                        borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                        borderBottomRightRadius:
                          _vm.facade.status_radius / 2 + "px",
                        borderBottomLeftRadius:
                          _vm.facade.status_radius / 2 + "px",
                        background: _vm.facade.status_background,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "swiper-list-img",
                          style: {
                            width: _vm.facade.img_width / 2 + "px",
                            height: _vm.facade.img_height / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.content.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                              on: { error: _vm.error },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text",
                          style: {
                            width: _vm.facade.img_width / 2 - 20 + "px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm._m(1),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2 && _vm.content.img_style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          [
            _c(
              "waterfall",
              {
                attrs: {
                  col: 2,
                  gutterWidth: _vm.facade.item_padding / 2,
                  lazyDistance: 200,
                  data: _vm.list,
                },
              },
              [
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      style: {
                        marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                        borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                        borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                        borderBottomRightRadius:
                          _vm.facade.status_radius / 2 + "px",
                        borderBottomLeftRadius:
                          _vm.facade.status_radius / 2 + "px",
                        width:
                          (750 -
                            _vm.facade.page_padding -
                            _vm.facade.page_padding -
                            _vm.facade.item_padding) /
                            4 +
                          "px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "two-list",
                          style: {
                            width:
                              (750 -
                                _vm.facade.page_padding -
                                _vm.facade.page_padding -
                                _vm.facade.item_padding) /
                                4 +
                              "px",
                            background: _vm.facade.status_background,
                            marginLeft:
                              index % 2 == 0
                                ? ""
                                : _vm.facade.item_padding / 4 + "px",
                          },
                        },
                        [
                          _c("div", {}, [
                            _c("img", { attrs: { src: item.image, alt: "" } }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.name) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dot-box" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot active" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dot-box" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot active" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }