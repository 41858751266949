"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _modules = _interopRequireDefault(require("./modules"));
var _system = require("@/api/system");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AuctionAlbum: _modules.default.auctionAlbum.widget,
    Auction: _modules.default.auction.widget,
    AlbumDetail: _modules.default.albumDetail.widget,
    AuctionDetail: _modules.default.auctionDetail.widget,
    Product: _modules.default.product.widget,
    ProductDetail: _modules.default.productDetail.widget,
    NftCollection: _modules.default.nftCollection.widget,
    NftDetail: _modules.default.nftDetail.widget,
    Compose: _modules.default.compose.widget,
    Blind: _modules.default.blind.widget,
    BlindDetail: _modules.default.blindDetail.widget,
    BlindOpen: _modules.default.blindOpen.widget,
    BlindOrderList: _modules.default.blindOrderList.widget
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || "",
      type: this.$route.query.type || "album_component"
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit("finish/setDragIndex", value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit("finish/setDragPosition", value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit("finish/setPagesData", value);
      }
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  watch: {
    $route: function $route(val) {
      if (val.name === "basicsDiy" && val.query.type) {
        this.type = val.query.type;
        this.pages = [];
        this.$store.commit("finish/setPageInfo", {
          show: false
        });
        this.loadBlindConfig();
        // this.loadPageInfo();
      }
    }
  },
  mounted: function mounted() {
    this.loadBlindConfig();
    // this.loadPageInfo();
  },

  methods: {
    pageInfoNew: function pageInfoNew(index) {
      return Object.assign({}, this.pageInfo, this.pageInfo.type_setting[this.pages[index].content.style]);
    },
    loadBlindConfig: function loadBlindConfig() {
      var _this = this;
      // this.loadPageInfo();
      (0, _product.blindboxSeriesDetailApi)(this.$route.query.id).then(function (res) {
        if (!res.data.config_data) {
          _this.loadPageInfo();
        } else {
          if (_this.type === "blind_page") {
            _this.$store.commit("finish/setPageInfo", res.data.config_data);
          } else if (_this.type === "blind_detail_page") {
            if (res.data.detail_config_data) {
              _this.pages = res.data.detail_config_data.content;
              _this.$store.commit("finish/setPageInfo", res.data.detail_config_data.config_data);
            } else {
              _this.loadPageInfo();
            }
            // this.$store.commit("finish/setPageInfo", res.data.config_data);
          } else if (_this.type === 'blind_detail_pop') {
            if (res.data.pop_config_data) {
              var pop_config_data = JSON.parse(res.data.pop_config_data);
              _this.pages = pop_config_data.content;
              _this.$store.commit("finish/setPageInfo", pop_config_data.config_data);
            } else {
              _this.loadPageInfo();
            }
          } else if (_this.type === 'blind_open') {
            if (res.data.open_box_config_data) {
              var open_box_config_data = JSON.parse(res.data.open_box_config_data);
              _this.pages = open_box_config_data.content;
              _this.$store.commit("finish/setPageInfo", open_box_config_data.config_data);
            } else {
              _this.loadPageInfo();
            }
          } else {
            _this.loadPageInfo();
          }
        }
      }).catch(function () {
        _this.loadPageInfo();
      });
    },
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this2 = this;
      (0, _system.diypageDetail)({
        type: this.type
      }).then(function (res) {
        // if (this.type == "blind_page") {
        //   this.pages = [];
        //   this.$store.commit("finish/setPageInfo", {
        //     show: true,
        //     title: "盲盒首页",
        //     background: "#1e1e1e",
        //     bg_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653119826546204424.png",
        //     bg_image_mode: 5,

        //     audio_play_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/165312470761077166.png",
        //     audio_pause_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653124701489100967.png",
        //     contact_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022517/image/1652786408545638390.png",
        //     coupon_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022517/image/1652786408303444165.png",

        //     free_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131184902269421.png",
        //     open_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131183805954611.png",
        //     my_box_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653131184584370017.png",

        //     point_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653111771731254210.png",
        //     refause_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022521/image/1653109349705506624.png",
        //     tricks_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022525/image/1653468724774995218.png",
        //     price_bg_image:
        //       "https://artimg2.artart.cn/merchant/333425/2022525/image/1653468725307137699.png",
        //     left_image:
        //       "https://artimg2.artart.cn/merchant/23/202265/image/1654414132124368310.png",
        //     right_image:
        //       "https://artimg2.artart.cn/merchant/23/202265/image/1654414131488666436.png",
        //     item_width: 190,
        //     item_color: "#E300FF",
        //   });
        // }
        console.log(_this2.type);
        if (_this2.type == "blind_detail_page") {
          _this2.pages = [{
            name: "blindDetail",
            title: "盲盒图片",
            content: {
              type: 'playimage'
            },
            facade: {
              marginTop: 200
            }
          }, {
            name: "blindDetail",
            title: "价格",
            content: {
              type: 'bindPrice'
            },
            facade: {
              marginTop: 0,
              borderColor: '#b0daee',
              color: '#b0daee'
            }
          }, {
            name: "blindDetail",
            title: "减免价格",
            content: {
              type: 'reducePrice'
            },
            facade: {
              marginTop: 0,
              color: '#ffffff',
              bgimage: 'https://artimg2.artart.cn/merchant/333425/2022525/image/1653468725307137699.png'
            }
          }, {
            name: "blindDetail",
            title: "概率",
            content: {
              type: 'consignee'
            },
            facade: {
              marginTop: 80,
              topTitleColor: '#ffffff',
              nameColor: '#8767d1',
              nameActiveColor: '#ffffff',
              signColor: 'rgba(255, 255, 255, 0.5)'
            }
          }, {
            name: "blindDetail",
            title: "列表标题",
            content: {
              type: 'listTitle'
            },
            facade: {
              marginTop: 0,
              color: '#ffffff'
            }
          }, {
            name: "blindDetail",
            title: "商品详情",
            content: {
              type: "productmeta"
            },
            facade: {
              marginTop: 32,
              metaColor: "#000000",
              priceColor: "#ff6600",
              borderRadius: 16,
              resultRadius: 40,
              marginLeft: 30,
              listBgimage: '',
              listBgcolor: '#ffffff'
            }
          }, {
            name: "blindDetail",
            title: "弹幕",
            content: {
              type: 'playTip'
            },
            facade: {
              color: '#ffffff',
              background: '#000000',
              borderRadius: 16
            }
          }];
          _this2.$store.commit("finish/setPageInfo", {
            show: true,
            title: "盲盒详情页",
            background: "#5a3d9f",
            bg_image: "https://artimg2.artart.cn/merchant/23/202265/image/1654417831120284583.png",
            bg_image_mode: 4,
            share_image: 'https://artimg2.artart.cn/merchant/23/2022616/image/1655358292502449408.png',
            open_image: 'https://artimg2.artart.cn/merchant/333425/2022521/image/1653131183805954611.png',
            mode_image: 'https://artimg2.artart.cn/merchant/23/202266/image/1654497205464530092.png',
            bottom_background: '#000000',
            txtColor: '#ffffff'
          });
        } else if (_this2.type == "blind_detail_pop") {
          _this2.pages = [{
            name: "blindDetail",
            title: "价格弹窗",
            content: {
              type: 'pricepop'
            },
            facade: {
              borderColor: '#fff',
              borderRadius: 25,
              bgColor: '#ffffff',
              bgimage: 'https://artimg2.artart.cn/merchant/23/202265/image/1654397893107375098.png',
              popborderColor: '#63FF4C',
              nameSize: 36,
              nameColor: '#ffffff',
              priceSize: 28,
              priceColor: '#ffffff',
              cutbgImage: 'https://artimg2.artart.cn/merchant/23/202265/image/1654400615904561340.png',
              cutColor: '#ffffff',
              oneBgimage: '',
              oneBgcolor: '#ffb900',
              twoBgimage: '',
              twoBgcolor: '#ff2e2e',
              threeBgimage: '',
              threeBgcolor: '#d963ff',
              fourBgimage: '',
              fourBgcolor: '#00a0ff'
            }
          }, {
            name: "blindDetail",
            title: "海报背景",
            content: {
              type: 'posterbg'
            },
            facade: {
              bgimage: 'https://artimg2.artart.cn/merchant/23/202267/image/1654566910312593387.png'
            }
          }, {
            name: "blindDetail",
            title: "气泡弹窗",
            content: {
              type: 'bubblepop'
            },
            facade: {
              bgimage: '',
              popwidth: 180,
              bgcolor: '#ffffff',
              imgwidth: 176
            }
          }];
        } else if (_this2.type == "blind_order_list") {
          _this2.pages = [{
            name: "blindOrderList",
            title: "订单列表",
            content: {
              type: 'orderTop'
            },
            facade: {
              selectColor: '#ea2e9e',
              color: '#fff'
            }
          }, {
            name: "blindOrderList",
            title: "订单详情",
            content: {
              type: 'orderList'
            },
            facade: {
              borderColor: '#ea2e9e',
              borderRadius: 8,
              listBgcolor: '#1e1e1e',
              listBgimage: '',
              listGoodsBgcolor: '#333333',
              listGoodsBgimage: '',
              listGoodsRadius: 8,
              timeColor: '#ea2e9e',
              blindNameColor: '#999999',
              productNameColor: '#999999',
              buttonBgColor: '#ea2e9e',
              buttonColor: '#ffffff',
              buttonBgimage: '',
              buttonRadiu: ''
            }
          }];
          _this2.$store.commit("finish/setPageInfo", {
            show: true,
            title: "盲盒订单列表",
            background: "#000000",
            bg_image: "https://artimg2.artart.cn/merchant/23/202266/image/1654487273940841710.png"
          });
        } else if (_this2.type == "blind_open") {
          _this2.pages = [{
            name: 'blindOpen',
            title: '盲盒开启',
            content: {
              type: 'open'
            },
            facade: {
              xuan_image: 'https://artimg2.artart.cn/merchant/23/202265/image/1654426530594120557.png',
              open_title_image: 'https://artimg2.artart.cn/merchant/23/202265/image/1654426521930422497.png',
              open_button_background: '#4D54D8',
              open_button_border_background: 'rgba(255, 255, 255, 0.6)',
              open_button_title_color: '#ffffff',
              open_button_radius: 50,
              xuan_open_button_background: '#F73CA3',
              xuan_open_button_border_background: 'rgba(255, 255, 255, 0.6)',
              xuan_open_button_title_color: '#ffffff',
              xuan_open_button_radius: 50,
              price_border_color: '#6BFFC5',
              price_color: '#ffffff',
              price_bg_color: '',
              price_bg_image: '',
              goods_color: '#ffffff',
              goods_shadow_color: '#002cff',
              go_color: '#ffffff'
            }
          }, {
            name: 'blindOpen',
            title: '盲盒待开启',
            content: {
              type: 'waitopen'
            },
            facade: {
              wait_open_title_image: 'https://artimg2.artart.cn/merchant/23/2022617/image/1655452152647271895.png',
              zan_open_button_background: '#F73CA3',
              zan_open_button_border_background: 'rgba(255, 255, 255, 0.6)',
              zan_open_button_title_color: '#ffffff',
              zan_open_button_radius: 50
            }
          }, {
            name: 'blindOpen',
            title: '炫耀海报',
            content: {
              type: 'posterbg'
            },
            facade: {
              bgimage: 'https://artimg2.artart.cn/merchant/333425/2022719/image/1658245019914342292.png'
            }
          }];
          _this2.$store.commit("finish/setPageInfo", {
            show: true,
            title: "开盒",
            background: "#000000",
            bg_image: "https://artimg2.artart.cn/merchant/23/202266/image/1654487266978235643.png",
            bg_image_mode: 4
          });
        }
        if (_this2.type === "nft_component" && res.data.config_data.type_setting && !res.data.config_data.type_setting[3]) {
          res.data.config_data.type_setting[3] = {
            status_btn_bg_color: "#000000",
            status_btn_color: "#00eeff",
            status_font_size: 24,
            sub_font_size: 24,
            sub_color: "#999999"
          };
        }
        if (_this2.type === "nft_component" && res.data.content && !res.data.content[2]) {
          res.data.content.push({
            name: "nftCollection",
            icon: "https://art2.oss-cn-shanghai.aliyuncs.com/icon/auction_album_icon.png",
            title: "NFT藏品",
            hasContent: true,
            content: {
              style: 3,
              data: []
            }
          });
        }
        console.log(res.data.content);
        if (res.data.content) {
          _this2.pages = res.data.content;
        }
        if (res.data.config_data) {
          _this2.$store.commit("finish/setPageInfo", res.data.config_data);
        }
      }).catch(function (message) {
        _this2.$message.error(message);
      });
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === "dragover" && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === "widget") {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = "top";
            } else {
              this.position = "down";
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = "down";
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch("finish/pushPagesComs");
    },
    draggableStart: function draggableStart() {
      this.$store.commit("finish/setLock", true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit("finish/setLock", false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};
exports.default = _default;