"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "GoodList",
  data: function data() {
    return {
      templateRadio: 0,
      idKey: "product_id",
      merCateList: [],
      roterPre: _settings.roterPre,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 5,
        mer_cate_id: "",
        type: "1",
        is_gift_bag: "",
        cate_id: "",
        store_name: "",
        keyword: ""
      },
      checked: [],
      multipleSelection: [],
      multipleSelectionAll: window.form_create_helper.get(this.$route.query.field) || [],
      nextPageFlag: false,
      singleChoice: 0,
      singleSelection: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.singleChoice = sessionStorage.getItem("singleChoice");
    console.log(this.singleChoice);
    this.getList();
    this.getCategorySelect();
    if (this.singleChoice != 1) {
      var checked = window.form_create_helper.get(this.$route.query.field).map(function (item) {
        return {
          product_id: item.id,
          image: item.src
        };
      }) || [];
      this.multipleSelectionAll = checked;
    }
    window.addEventListener("unload", function (e) {
      return _this.unloadHandler(e);
    });
  },
  destroyed: function destroyed() {
    sessionStorage.setItem("singleChoice", 0);
  },
  methods: {
    getTemplateRow: function getTemplateRow(row) {
      this.singleSelection = {
        src: row.image,
        id: row.product_id
      };
    },
    unloadHandler: function unloadHandler() {
      if (this.singleChoice != 1) {
        if (this.multipleSelectionAll.length > 0) {
          if (this.$route.query.field) {
            form_create_helper.set(this.$route.query.field, this.multipleSelectionAll.map(function (item) {
              return {
                id: item.product_id,
                src: item.image
              };
            }));
            form_create_helper.close(this.$route.query.field);
          }
        } else {
          this.$message.warning("请先选择商品");
        }
      } else {
        if (this.singleSelection && this.singleSelection.src && this.singleSelection.id) {
          if (this.$route.query.field) {
            form_create_helper.set(this.$route.query.field, this.singleSelection);
            form_create_helper.close(this.$route.query.field);
          }
        } else {
          this.$message.warning("请先选择商品");
        }
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this2 = this;
      this.multipleSelection = val;
      setTimeout(function () {
        _this2.changePageCoreRecordData();
      }, 50);
    },
    // 设置选中的方法
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.tableData.data.length; i++) {
        if (selectAllIds.indexOf(this.tableData.data[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.tableData.data[i], true);
        }
      }
    },
    // 记忆选择核心方法
    changePageCoreRecordData: function changePageCoreRecordData() {
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var that = this;
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.multipleSelection;
        return;
      }
      // 总选择里面的key集合
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      var selectIds = [];
      // 获取当前页选中的id
      this.multipleSelection.forEach(function (row) {
        selectIds.push(row[idKey]);
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (selectAllIds.indexOf(row[idKey]) < 0) {
          that.multipleSelectionAll.push(row);
        }
      });
      var noSelectIds = [];
      // 得到当前页没有选中的id
      this.tableData.data.forEach(function (row) {
        if (selectIds.indexOf(row[idKey]) < 0) {
          noSelectIds.push(row[idKey]);
        }
      });
      noSelectIds.forEach(function (id) {
        if (selectAllIds.indexOf(id) >= 0) {
          for (var i = 0; i < that.multipleSelectionAll.length; i++) {
            if (that.multipleSelectionAll[i][idKey] == id) {
              // 如果总选择中有未被选中的，那么就删除这条
              that.multipleSelectionAll.splice(i, 1);
              break;
            }
          }
        }
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this3.merCateList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.$nextTick(function () {
          this.setSelectRow(); //调用跨页选中方法
        });

        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.changePageCoreRecordData();
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.changePageCoreRecordData();
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
exports.default = _default;