var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "picture", staticClass: "photo-selet" },
    [
      _c("popup", { on: { confirm: _vm.handleConfirm } }, [
        _c("i", { staticClass: "le-icon le-icon-add" }),
        _vm._v(" "),
        _vm.paddingStyle !== "16px"
          ? _c("p", { attrs: { type: "text" } }, [
              _vm._v("添加" + _vm._s(_vm.title)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }