"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _modules = _interopRequireDefault(require("./modules"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Title: _modules.default.title.element,
    Rubik: _modules.default.rubik_weChat.element,
    Blank: _modules.default.blank.element,
    AuctionWeChat: _modules.default.auction_weChat.element,
    AuctionAlbumWeChat: _modules.default.auction_album_weChat.element,
    textView: _modules.default.textView.element
  },
  props: {
    pages: Array
  },
  data: function data() {
    return {
      show: true,
      showS: false,
      content: '',
      miniAppid: '',
      miniName: ''
    };
  },
  created: function created() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      _this.miniAppid = res.data.miniProgram && res.data.miniProgram.appid ? res.data.miniProgram.appid : '';
      _this.miniName = res.data.miniProgram && res.data.miniProgram.name ? res.data.miniProgram.name : '';
    }).catch(function (_ref) {
      var message = _ref.message;
    });
    this.show = true;
  },
  methods: {
    closeAction: function closeAction(done) {
      this.$emit('closeAction');
      if (done) {
        done();
      }
    },
    clickCloseAction: function clickCloseAction() {
      this.$emit('closeAction');
    },
    copyAction: function copyAction() {
      var selection = window.getSelection();
      selection.removeAllRanges();
      var range = document.createRange();
      range.selectNodeContents(document.getElementById('is_copy')); // 需要选中的dom节点
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      this.$message.success('复制成功');

      // const Url2 = document.getElementById('is_copy');
      // Url2.select();
      // document.execCommand('copy');

      // const s = this.$refs.is_copy_el.outerHTML;
      // const str = s.replace(/\<div/g, '<section');
      // const str1 = str.replace(/\<\/div\>/g, '</section>');
      // this.content = str1;
      // this.showS = true;
    },
    getUeAction: function getUeAction(ue) {
      // ue.blur();
      // ue.execCommand('selectall');
      // ue.execCommand('copy');
      // document.execCommand('SelectAll');
      // document.execCommand('copy');
    }
  }
};
exports.default = _default;