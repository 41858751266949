var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "advertising" }, [
    _vm.size == 1
      ? _c(
          "div",
          { staticClass: "advertising-item-1" },
          [
            _vm.adverArray[0] && _vm.adverArray[0].url
              ? _c("el-image", {
                  attrs: { fit: "cover", src: _vm.adverArray[0].url },
                })
              : _c("div", { staticClass: "advertising-item-tips" }, [
                  _c("span", [_vm._v("尺寸不限")]),
                ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 2
      ? _c("div", { staticClass: "advertising-item-2" }, [
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 0, checked: _vm._q(_vm.active, 0) },
              on: {
                change: function ($event) {
                  _vm.active = 0
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[0] && _vm.adverArray[0].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[0].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽375像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 1, checked: _vm._q(_vm.active, 1) },
              on: {
                change: function ($event) {
                  _vm.active = 1
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[1] && _vm.adverArray[1].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[1].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽375像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 3
      ? _c("div", { staticClass: "advertising-item-3" }, [
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 0, checked: _vm._q(_vm.active, 0) },
              on: {
                change: function ($event) {
                  _vm.active = 0
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[0] && _vm.adverArray[0].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[0].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽250像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 1, checked: _vm._q(_vm.active, 1) },
              on: {
                change: function ($event) {
                  _vm.active = 1
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[1].url && _vm.adverArray[1].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[1].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽250像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 2, checked: _vm._q(_vm.active, 2) },
              on: {
                change: function ($event) {
                  _vm.active = 2
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[2] && _vm.adverArray[2].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[2].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽250像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 4
      ? _c("div", { staticClass: "advertising-item-4" }, [
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 0, checked: _vm._q(_vm.active, 0) },
              on: {
                change: function ($event) {
                  _vm.active = 0
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "162px",
                  top: 0,
                  left: 0,
                },
              },
              [
                _vm.adverArray[0] && _vm.adverArray[0].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[0].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X375像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 1, checked: _vm._q(_vm.active, 1) },
              on: {
                change: function ($event) {
                  _vm.active = 1
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "81px",
                  top: 0,
                  left: "161px",
                },
              },
              [
                _vm.adverArray[1].url && _vm.adverArray[1].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[1].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X188像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 2, checked: _vm._q(_vm.active, 2) },
              on: {
                change: function ($event) {
                  _vm.active = 2
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "82px",
                  top: "80px",
                  left: "161px",
                },
              },
              [
                _vm.adverArray[2] && _vm.adverArray[2].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[2].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X188像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 5
      ? _c("div", { staticClass: "advertising-item-5" }, [
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 0, checked: _vm._q(_vm.active, 0) },
              on: {
                change: function ($event) {
                  _vm.active = 0
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[0] && _vm.adverArray[0].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[0].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽188像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 1, checked: _vm._q(_vm.active, 1) },
              on: {
                change: function ($event) {
                  _vm.active = 1
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[1].url && _vm.adverArray[1].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[1].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽188像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 2, checked: _vm._q(_vm.active, 2) },
              on: {
                change: function ($event) {
                  _vm.active = 2
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[2] && _vm.adverArray[2].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[2].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽188像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 3, checked: _vm._q(_vm.active, 3) },
              on: {
                change: function ($event) {
                  _vm.active = 3
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advertising-item-image" },
              [
                _vm.adverArray[3] && _vm.adverArray[3].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[3].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("宽188像素高度不限")]),
                    ]),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 6
      ? _c("div", { staticClass: "advertising-item-4" }, [
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 0, checked: _vm._q(_vm.active, 0) },
              on: {
                change: function ($event) {
                  _vm.active = 0
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "81px",
                  top: 0,
                  left: 0,
                },
              },
              [
                _vm.adverArray[0] && _vm.adverArray[0].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[0].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X375像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 1, checked: _vm._q(_vm.active, 1) },
              on: {
                change: function ($event) {
                  _vm.active = 1
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "82px",
                  top: "80px",
                  left: 0,
                },
              },
              [
                _vm.adverArray[1].url && _vm.adverArray[1].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[1].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X375像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 2, checked: _vm._q(_vm.active, 2) },
              on: {
                change: function ($event) {
                  _vm.active = 2
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "81px",
                  top: 0,
                  left: "161px",
                },
              },
              [
                _vm.adverArray[2] && _vm.adverArray[2].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[2].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X188像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "advertising-item-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "advertising-item-radio",
              attrs: { type: "radio", name: "advertising" },
              domProps: { value: 3, checked: _vm._q(_vm.active, 3) },
              on: {
                change: function ($event) {
                  _vm.active = 3
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "advertising-item-image",
                style: {
                  width: "162px",
                  height: "82px",
                  top: "80px",
                  left: "161px",
                },
              },
              [
                _vm.adverArray[3] && _vm.adverArray[3].url
                  ? _c("el-image", {
                      attrs: { fit: "cover", src: _vm.adverArray[3].url },
                    })
                  : _c("div", { staticClass: "advertising-item-tips" }, [
                      _c("span", [_vm._v("375X188像素或同比例")]),
                    ]),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }