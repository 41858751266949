"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MP;
function MP() {
  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    script.onload = script.onreadystatechange = function () {
      if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
        resolve();
      }
      script.onload = script.onreadystatechange = null;
    };
    script.type = 'text/javascript';
    script.id = "map_area";
    script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=O6TBZ-UKH3O-N4DWD-SXBW5-Q6GHJ-ETFUO';
    script.onerror = reject;
    document.body.appendChild(script);
  });
}