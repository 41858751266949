var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { width: "720", title: "选择问答专家" },
          on: { "on-ok": _vm.cursorSelect },
          model: {
            value: _vm.showSelectAuction,
            callback: function ($$v) {
              _vm.showSelectAuction = $$v
            },
            expression: "showSelectAuction",
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("Input", {
                staticClass: "w200",
                attrs: { placeholder: "行家名称" },
                on: { "on-enter": _vm.search },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v
                  },
                  expression: "username",
                },
              }),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticClass: "ml20",
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.search($event)
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h400 mt20",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "table",
                { staticClass: "spec-table" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("td", { attrs: { width: "50%", align: "center" } }, [
                        _vm._v("专家信息"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("擅长领域/门类"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("行家状态"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("操作"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c(
                            "div",
                            { staticClass: "display-flex item-align-center" },
                            [
                              m.avatar
                                ? _c("img", {
                                    staticStyle: {
                                      width: "90px",
                                      height: "90px",
                                      display: "block",
                                      "flex-shrink": "0",
                                    },
                                    attrs: { src: m.avatar },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml10" }, [
                                _c("p", [_vm._v("UID：" + _vm._s(m.uid))]),
                                _vm._v(" "),
                                _c("p", { staticClass: "mt5" }, [
                                  _vm._v(_vm._s(m.nickname)),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "mt5" }, [
                                  _vm._v(_vm._s(m.phone)),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "领域：" + _vm._s(m.good_at_category_name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("门类：" + _vm._s(m.good_at_description)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.is_cancelled == 1
                              ? _c("div", [_vm._v("身份取消")])
                              : m.review_status == 1
                              ? _c("div", [_vm._v("正常")])
                              : m.review_status == 0
                              ? _c("div", [_vm._v("待审核")])
                              : _c("div", [_vm._v("已拒绝")]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.uid) == -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.uid
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("Page", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  total: _vm.total,
                  "page-size": _vm.queryParams.size,
                  "show-sizer": "",
                  "show-total": "",
                  "show-elevator": "",
                  current: _vm.queryParams.page,
                },
                on: {
                  "on-page-size-change": _vm.changePageSize,
                  "on-change": _vm.changePage,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }