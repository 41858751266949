var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.showAlbumListAction } },
            [_vm._v("\n      选择专场\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.select,
              expression: "select",
            },
          ],
          staticClass: "album-info mt10",
        },
        [
          _c(
            "div",
            {
              staticClass: "ft14 fWeight500",
              staticStyle: { "margin-top": "-10px" },
            },
            [
              _vm.changeAlbum.live_id
                ? [_vm._v("\n        直播 |\n      ")]
                : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.changeAlbum.name) + "\n    "),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ft12",
              staticStyle: { "line-height": "20px", height: "20px" },
            },
            [
              _vm._v(
                "\n      预展：" +
                  _vm._s(
                    _vm.$parseTime(
                      _vm.changeAlbum.scheduled_show_time * 1000,
                      "{y}-{m}-{d} {h}:{i}"
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ft12",
              staticStyle: { "line-height": "20px", height: "20px" },
            },
            [
              _vm._v(
                "\n      开拍：" +
                  _vm._s(
                    _vm.$parseTime(
                      _vm.changeAlbum.stime * 1000,
                      "{y}-{m}-{d} {h}:{i}"
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.changeAlbum.album_type === 1 &&
          _vm.changeAlbum.end_type === 2 &&
          _vm.changeAlbum.time_status !== 3
            ? _c(
                "div",
                {
                  staticClass: "ft12",
                  staticStyle: { "line-height": "20px", height: "20px" },
                },
                [
                  _vm._v(
                    "\n      结拍：" +
                      _vm._s(
                        _vm.$parseTime(
                          _vm.changeAlbum.first_end_time * 1000,
                          "{y}-{m}-{d} {h}:{i}"
                        )
                      ) +
                      " 开始\n    "
                  ),
                ]
              )
            : _vm.changeAlbum.album_type === 1
            ? _c(
                "div",
                {
                  staticClass: "ft12",
                  staticStyle: { "line-height": "20px", height: "20px" },
                },
                [
                  _vm._v(
                    "\n      结拍：" +
                      _vm._s(
                        _vm.$parseTime(
                          _vm.changeAlbum.etime * 1000,
                          "{y}-{m}-{d} {h}:{i}"
                        )
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm.changeAlbum.etime
            ? _c(
                "div",
                {
                  staticClass: "ft12",
                  staticStyle: { "line-height": "20px", height: "20px" },
                },
                [
                  _vm._v(
                    "\n      结拍：" +
                      _vm._s(
                        _vm.$parseTime(
                          _vm.changeAlbum.etime * 1000,
                          "{y}-{m}-{d} {h}:{i}"
                        )
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.changeAlbum.live_id
            ? _c(
                "div",
                {
                  staticClass: "ft12",
                  staticStyle: { "line-height": "20px", height: "20px" },
                },
                [
                  _vm._v(
                    "\n      直播：" +
                      _vm._s(
                        _vm.$parseTime(
                          _vm.changeAlbum.live.live_time * 1000,
                          "{y}-{m}-{d} {h}:{i}"
                        )
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.goSearch
        ? _c("searchAlbum", {
            attrs: { "venue-id": _vm.venueId, "change-count": "1" },
            on: { changeOk: _vm.changeAlbumOk },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }