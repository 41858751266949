"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0,
      groupName: '',
      type: ''
    };
  },
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.load();
      }
      this.showSelectAuction = true;
    },
    search: function search() {
      this.queryParams.feed_type = this.type;
      this.queryParams.group_name = this.groupName;
      this.queryParams.page = 1;
      this.load();
    },
    load: function load(fun) {
      var _this = this;
      var params = this.queryParams;
      var msg = this.$message.loading({
        render: function render(h) {
          return h('span', ['加载中...']);
        },
        duration: 0
      });
      this.$http.get('/group_feed_list', {
        params: params
      }).then(function (res) {
        msg();
        if (res.code === 200) {
          _this.total = res.data.total;
          res.data.data.forEach(function (m) {
            var newM = m;
            newM.goods_spec_format = [];
          });
          _this.resultData = res.data.data;
          _this.$nextTick(function () {
            // 赋值后马上更新
            if (fun) fun();
          });
        }
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        id: info.id,
        image: '',
        avatorimg: info.avatar + (info.avatar.indexOf('https://img.artart.cn') != -1 ? '!120a' : ''),
        avator: info.uname,
        time: info.update_time,
        name: info.title || info.no_html
      };
      if (info.feed_type == 1 || info.feed_type == 3) {
        data.image = info.video_content.coverImage;
      } else if (info.feed_type == 2) {
        data.image = info.img_list[0] + '!120a';
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'bbs',
        data: this.selectedAuction
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.load();
    }
  }
};
exports.default = _default2;