var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: _vm.title, is_tab: false } },
    [
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.facade.type_setting[_vm.content.style],
              "label-width": "80px",
              size: "mini",
              "label-position": "left",
            },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _vm.content.style != 5
              ? _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内容上间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value:
                          _vm.facade.type_setting[_vm.content.style].top_margin,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.facade.type_setting[_vm.content.style],
                            "top_margin",
                            $$v
                          )
                        },
                        expression:
                          "facade.type_setting[content.style].top_margin",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.content.style == 2 || _vm.content.style == 5
              ? _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内容左间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value:
                          _vm.facade.type_setting[_vm.content.style]
                            .left_margin,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.facade.type_setting[_vm.content.style],
                            "left_margin",
                            $$v
                          )
                        },
                        expression:
                          "facade.type_setting[content.style].left_margin",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字边距" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].text_margin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "text_margin",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].text_margin",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "商品底色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .item_background,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "item_background",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].item_background",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "阴影" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].item_shadow,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "item_shadow",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].item_shadow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "阴影大小" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .item_shadow_size,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "item_shadow_size",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].item_shadow_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.content.style === 5 || _vm.content.style === 4
              ? _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("图片尺寸"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "item-align-center" },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { type: "text", placeholder: "宽" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value:
                                _vm.facade.type_setting[_vm.content.style]
                                  .img_width,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade.type_setting[_vm.content.style],
                                  "img_width",
                                  $$v
                                )
                              },
                              expression:
                                "facade.type_setting[content.style].img_width",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                          2
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { margin: "0 6px" },
                          attrs: {
                            src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { type: "text", placeholder: "宽" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value:
                                _vm.facade.type_setting[_vm.content.style]
                                  .img_height,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade.type_setting[_vm.content.style],
                                  "img_height",
                                  $$v
                                )
                              },
                              expression:
                                "facade.type_setting[content.style].img_height",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标题字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].title_size,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "title_size",
                        $$v
                      )
                    },
                    expression: "facade.type_setting[content.style].title_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标题样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style].title_weight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "title_weight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].title_weight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "价格字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].price_size,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "price_size",
                        $$v
                      )
                    },
                    expression: "facade.type_setting[content.style].price_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "价格样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style].price_weight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "price_weight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].price_weight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "销量字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .sales_volumes_size,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "sales_volumes_size",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].sales_volumes_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "销量样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style]
                          .sales_volumes_weight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "sales_volumes_weight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].sales_volumes_weight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }