var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.is_close
    ? _c(
        "div",
        { staticClass: "guide", on: { click: _vm.toStep } },
        [
          _vm._l(_vm.masks, function (item, index) {
            return [
              _vm.step === index
                ? _c("div", { key: index }, [
                    _c("div", {
                      staticClass: "guide-mask",
                      style: {
                        top: _vm.position.top,
                        left: _vm.position.left,
                        width: item.width,
                        height: item.height,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "guide-animation",
                        style: {
                          top: _vm.position.top,
                          left: _vm.position.left,
                        },
                      },
                      [_c("img", { attrs: { src: item.src } })]
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "guide-step" }, [
            _c("img", {
              attrs: { src: _vm.guideNext },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toStep($event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toStop($event)
                  },
                },
              },
              [_vm._v("跳过引导")]
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }