var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "商品轮播图", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("区域尺寸")]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "item-align-center" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { type: "text", placeholder: "宽" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.facade.view_width,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.facade, "view_width", $$v)
                                    },
                                    expression: "facade.view_width",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("px"),
                                  ]),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticStyle: { margin: "0 6px" },
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { type: "text", placeholder: "宽" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.facade.view_height,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.facade, "view_height", $$v)
                                    },
                                    expression: "facade.view_height",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("px"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("图片尺寸")]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "item-align-center" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { type: "text", placeholder: "宽" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.facade.img_width,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.facade, "img_width", $$v)
                                    },
                                    expression: "facade.img_width",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("px"),
                                  ]),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticStyle: { margin: "0 6px" },
                                attrs: {
                                  src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { type: "text", placeholder: "宽" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.facade.img_height,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.facade, "img_height", $$v)
                                    },
                                    expression: "facade.img_height",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("px"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "图上圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.img_top_radius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "img_top_radius", $$v)
                              },
                              expression: "facade.img_top_radius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "图下圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.img_bottom_radius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "img_bottom_radius", $$v)
                              },
                              expression: "facade.img_bottom_radius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("choose", {
                        staticClass: "mt20",
                        attrs: {
                          data: _vm.pointerData,
                          title: "指示器类型",
                          margin: "0 10px 0 0",
                          width: "75px",
                          height: "30px",
                        },
                        model: {
                          value: _vm.facade.indicator_style,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "indicator_style", $$v)
                          },
                          expression: "facade.indicator_style",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.indicator_align,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "indicator_align", $$v)
                                },
                                expression: "facade.indicator_align",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "center" } }, [
                                _vm._v("中间"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "left" } }, [
                                _vm._v("左"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "right" } }, [
                                _vm._v("右"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "选中颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.indicator_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "indicator_color", $$v)
                              },
                              expression: "facade.indicator_color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "price"
        ? [
            _c(
              "control",
              { attrs: { title: "价格区域", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "区域高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 300 },
                          model: {
                            value: _vm.facade.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "height", $$v)
                            },
                            expression: "facade.height",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "当前价字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.priceFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "priceFontSize", $$v)
                            },
                            expression: "facade.priceFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "分享颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "原价颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.oldPriceColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "oldPriceColor", $$v)
                            },
                            expression: "facade.oldPriceColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "当前价颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.priceColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "priceColor", $$v)
                            },
                            expression: "facade.priceColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "商品名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "desc"
        ? [
            _c(
              "control",
              { attrs: { title: "一句话介绍", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("只展示一行")]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "flex-one",
                            staticStyle: { "font-size": "12px", color: "#999" },
                          }),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.fold,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fold", $$v)
                              },
                              expression: "facade.fold",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "control",
              { attrs: { title: "优惠券", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black mr10" },
                          [_vm._v("优惠券图标")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("couponImg")
                              },
                            },
                          },
                          [
                            _vm.facade.couponImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("couponImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.couponImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.couponImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "领取颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.receiveColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "receiveColor", $$v)
                              },
                              expression: "facade.receiveColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black mr10" },
                          [_vm._v("领取图标")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("receiveImg")
                              },
                            },
                          },
                          [
                            _vm.facade.receiveImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("receiveImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.receiveImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.receiveImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "control",
              { attrs: { title: "选择规格", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.otherColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "otherColor", $$v)
                            },
                            expression: "facade.otherColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "分隔线颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.borColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borColor", $$v)
                            },
                            expression: "facade.borColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 50, max: 300 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center mt20" },
                      [
                        _c(
                          "div",
                          { staticClass: "fontW400 ft14 color-black" },
                          [_vm._v("隐藏库存")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "flex-one",
                          staticStyle: { "font-size": "12px", color: "#999" },
                        }),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#009406",
                            "inactive-color": "#cccccc",
                          },
                          model: {
                            value: _vm.facade.hide_stock,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hide_stock", $$v)
                            },
                            expression: "facade.hide_stock",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center mt20" },
                      [
                        _c(
                          "div",
                          { staticClass: "fontW400 ft14 color-black" },
                          [_vm._v("隐藏销量")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "flex-one",
                          staticStyle: { "font-size": "12px", color: "#999" },
                        }),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#009406",
                            "inactive-color": "#cccccc",
                          },
                          model: {
                            value: _vm.facade.hide_sale,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hide_sale", $$v)
                            },
                            expression: "facade.hide_sale",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "control",
              { attrs: { title: "商品详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("选项卡"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.tabColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabColor", $$v)
                            },
                            expression: "facade.tabColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "高亮颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabCurrColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabCurrColor", $$v)
                            },
                            expression: "facade.tabCurrColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.tabFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabFontSize", $$v)
                            },
                            expression: "facade.tabFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.tabFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "tabFontWeight", $$v)
                              },
                              expression: "facade.tabFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.tabLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabLineHeight", $$v)
                            },
                            expression: "facade.tabLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("属性介绍"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.attrColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrColor", $$v)
                            },
                            expression: "facade.attrColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.attrOtherColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrOtherColor", $$v)
                            },
                            expression: "facade.attrOtherColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "分隔线颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.attrBorColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrBorColor", $$v)
                            },
                            expression: "facade.attrBorColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.attrFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrFontSize", $$v)
                            },
                            expression: "facade.attrFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.attrFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "attrFontWeight", $$v)
                              },
                              expression: "facade.attrFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.attrLineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrLineHeight", $$v)
                            },
                            expression: "facade.attrLineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("详情介绍"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "control",
              { attrs: { title: "相关推荐", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ft14" }, [
                  _vm._v("请在左侧拍品组件里设置"),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }