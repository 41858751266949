var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container " },
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "all-height item-flex-center",
            staticStyle: { "text-align": "center" },
          },
          [
            _c("img", {
              attrs: {
                src: "https://artimg2.artart.cn/image/20220512/431d21ff8b2f0e5089c57caa9f749a59.png",
                alt: "",
              },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }