var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-image",
    {
      staticStyle: { width: "100%", height: "100%" },
      attrs: { src: _vm.src, fit: "cover", lazy: _vm.lazy },
      on: { error: _vm.error },
    },
    [
      _c(
        "div",
        { staticClass: "image-slot", attrs: { slot: "error" }, slot: "error" },
        [_c("i", { staticClass: "image-icon le-icon le-icon-morentupian" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }