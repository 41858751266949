var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "r-title" }, [_vm._v("参拍记录")]),
    _vm._v(" "),
    _c("div", { staticClass: "echart-box" }, [
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart1", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("浏览类目占比")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart2", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("参拍类目占比")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart3", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("成交类目占比")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "count-box" }, [
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.bidCount))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("出价(次)")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "count-list",
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.goOrderAction },
        },
        [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(
              _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionOrderCount))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("中拍(件)")]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(
              _vm.formatPriceNumber(_vm.countjson.auctionOrderSumTotalPrice)
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("成交额")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionAveragePrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("平均单价")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionOrderSumPayPrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("实际支付")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "20px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "order-table",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              size: "mini",
              "highlight-current-row": "",
              "cell-class-name": _vm.addTdClass,
            },
            on: { "sort-change": _vm.changeSortAction },
          },
          [
            _c("el-table-column", {
              key: "7",
              attrs: { label: "图片", "min-width": "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("img", {
                        staticStyle: {
                          width: "40px",
                          height: "40px",
                          display: "block",
                        },
                        attrs: {
                          src: scope.row.product.image + "!120a",
                          alt: "",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "8",
              attrs: { label: "LOT", "min-width": "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(_vm._s(scope.row.auction.lot) + "\n          "),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "9",
              attrs: { label: "拍品名称", "min-width": "140", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(scope.row.product.store_name) + "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "10",
              attrs: { label: "拍品状态", "min-width": "140", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(scope.row.auction.auction_status_txt) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "12",
              attrs: { label: "出价金额", "min-width": "80", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.bid_price) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "13",
              attrs: { label: "出价时间", "min-width": "140", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.create_time) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              key: "14",
              attrs: { label: "当前价格", "min-width": "90", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(scope.row.auction.now_price) + "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [6, 20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }