"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./admin"));
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default
  },
  data: function data() {
    return {
      currentRole: 'adminDashboard'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['roles'])),
  created: function created() {
    // if (!this.roles.includes('admin2')) {
    //   this.currentRole = 'editorDashboard'
    // }
  }
};
exports.default = _default;