"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelDetailApi = cancelDetailApi;
exports.createLiveApi = createLiveApi;
exports.delBannedApi = delBannedApi;
exports.delLiveApi = delLiveApi;
exports.delLiveVideoRecordApi = delLiveVideoRecordApi;
exports.editLiveApi = editLiveApi;
exports.editLiveVirtualNumApi = editLiveVirtualNumApi;
exports.endLiveApi = endLiveApi;
exports.getLivePushUrl = getLivePushUrl;
exports.getStatusFilterApi = getStatusFilterApi;
exports.liveCreateApi = liveCreateApi;
exports.liveDeleteApi = liveDeleteApi;
exports.liveDetailApi = liveDetailApi;
exports.liveList = liveList;
exports.liveListApi = liveListApi;
exports.liveStatuseApi = liveStatuseApi;
exports.liveUpdateApi = liveUpdateApi;
exports.setBannedApi = setBannedApi;
exports.setLiveOpenChatApi = setLiveOpenChatApi;
exports.userLikeListApi = userLikeListApi;
exports.userOnlineListApi = userOnlineListApi;
exports.userVisitListApi = userVisitListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 直播间 -- 列表
 */
function liveList(data) {
  return _request.default.get('live/room/list', data);
}

/**
 * @description 直播间 -- 状态数量
 */
function getStatusFilterApi(data) {
  return _request.default.get('live/room/getStatusFilter', data);
}

/**
 * @description 直播间 -- 修改虚拟观看人数
 */
function editLiveVirtualNumApi(id, data) {
  return _request.default.post("live/room/editLiveVirtualNum/".concat(id), data);
}

/**
 * @description 直播间 -- 获取推流地址
 */
function getLivePushUrl(id) {
  return _request.default.get("live/room/getLivePushUrl/".concat(id));
}

/**
 * @description 直播间 -- 创建直播间
 */
function createLiveApi(id, data) {
  return _request.default.post("live/room/create", data);
}

/**
 * @description 直播间 -- 创建直播间
 */
function liveDetailApi(id, data) {
  return _request.default.get("live/room/info/".concat(id), data);
}

/**
 * @description 直播间 -- 创建直播间
 */
function cancelDetailApi(id) {
  return _request.default.post("live/room/cancel/".concat(id));
}

/**
 * @description 直播间 -- 修改直播间
 */
function editLiveApi(id, data) {
  return _request.default.post("live/room/edit/".concat(id), data);
}

/**
 * @description 直播间 -- 删除直播间
 */
function delLiveApi(id) {
  return _request.default.post("live/room/del/".concat(id));
}
// /**
//  * @description 直播间 -- 修改直播间
//  */
// export function delLiveApi(id, data) {
//   return request.post(`live/room/edit/${id}`, data);
// }

/**
 * @description 直播间 -- 获取推流地址
 */
function endLiveApi(data) {
  return _request.default.post("live/room/endLive", data);
}

/**
 * @description 直播间 -- 获取推流地址
 */
function delLiveVideoRecordApi(data) {
  return _request.default.post("live/room/delLiveVideoRecord", data);
}

/**
 * @description 直播间 -- 直播间关注列表
 */
function userLikeListApi(data) {
  return _request.default.get("live/room/userLikeList", data);
}

/**
 * @description 直播间 -- 直播间在线列表
 */
function userOnlineListApi(data) {
  return _request.default.get("live/room/userOnlineList", data);
}

/**
 * @description 直播间 -- 直播间访问列表
 */
function userVisitListApi(data) {
  return _request.default.get("live/room/userVisitList", data);
}

/**
 * @description 直播间 -- 禁言
 */
function setBannedApi(data) {
  return _request.default.post("live/room/setBanned", data);
}

/**
 * @description 直播间 -- 取消禁言
 */
function delBannedApi(data) {
  return _request.default.post("live/room/delBanned", data);
}

/**
 * @description 直播间 -- 全局禁言
 */
function setLiveOpenChatApi(data) {
  return _request.default.post("live/room/setLiveOpenChat", data);
}

/**
 * @description 直播分类 -- 列表
 */
function liveListApi() {
  return _request.default.get('live/category/lst');
}
/**
 * @description 直播分类 -- 新增表单
 */
function liveCreateApi() {
  return _request.default.get('live/category/create/form');
}
/**
 * @description 直播分类 -- 编辑表单
 */
function liveUpdateApi(id) {
  return _request.default.get("live/category/update/form/".concat(id));
}
/**
 * @description 直播分类 -- 删除
 */
function liveDeleteApi(id) {
  return _request.default.delete("live/category/delete/".concat(id));
}
/**
 * @description 直播分类 -- 修改开启状态
 */
function liveStatuseApi(id, status) {
  return _request.default.post("live/category/status/".concat(id), {
    status: status
  });
}