var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-flex-center",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.facade.imgStatic ? "no-d3-wrap" : "d3-wrap",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "rotatef" },
                          [
                            _c("el-image", {
                              staticClass: "img",
                              style: {
                                borderWidth:
                                  (_vm.facade.borderWidth
                                    ? _vm.facade.borderWidth
                                    : 0) /
                                    2 +
                                  "px",
                              },
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                fit: "contain",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "name",
                    style:
                      "color: " +
                      _vm.facade.color +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";",
                  },
                  [_vm._v("刺杀罪恶之文化侵蚀")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "tag"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tags item-flex-center flex-wrap",
                    style: "font-size: " + _vm.facade.btn_font_size / 2 + "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        style:
                          "color: " +
                          _vm.facade.main_btn_color +
                          ";background: " +
                          _vm.facade.main_btn_bg_color +
                          ";border-radius: " +
                          _vm.facade.btn_radius / 2 +
                          "px",
                      },
                      [_vm._v("红色文律")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tag",
                        style:
                          "color: " +
                          _vm.facade.sub_btn_color +
                          ";background: " +
                          _vm.facade.sub_btn_bg_color +
                          ";border-radius: " +
                          _vm.facade.btn_radius / 2 +
                          "px",
                      },
                      [_vm._v("文化复兴")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "num",
                        style:
                          "color: " +
                          _vm.facade.stock_color +
                          ";font-size: " +
                          _vm.facade.stock_font_size / 2 +
                          "px;",
                      },
                      [_vm._v("限量500份")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;",
                  },
                  [
                    _c("div", { staticClass: "item flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l",
                          style: "color: " + _vm.facade.color + ";",
                        },
                        [_vm._v("创作者")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r",
                          style: "color: " + _vm.facade.otherColor + ";",
                        },
                        [_vm._v("艺天下")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l",
                          style: "color: " + _vm.facade.color + ";",
                        },
                        [_vm._v("发行方")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "r",
                          style: "color: " + _vm.facade.otherColor + ";",
                        },
                        [_vm._v("艺天下开放平台")]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "coupon flex align-items-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex align-items-c flex-one" }, [
                      _c("img", {
                        attrs: { src: _vm.facade.couponImg, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10" }, [_vm._v("满500减30")]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "ml20",
                        attrs: { src: _vm.facade.couponImg, alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml10" }, [_vm._v("9折券")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", [_vm._v("领券")]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: _vm.facade.receiveImg, alt: "" },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满14周岁的中国大陆用户购买。"
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type == "nftCollectionList"
        ? [
            _c(
              "div",
              {
                staticClass: "content-box",
                style:
                  "padding:  0 " +
                  _vm.facade.page_margin / 2 +
                  "px;background: " +
                  _vm.facade.background_color +
                  ";font-weight: " +
                  _vm.facade.font_weight,
              },
              _vm._l(3, function (item, index) {
                return _c("div", { key: index, staticClass: "list flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "r-view flex",
                      class: { mt0: index == 0 },
                      style:
                        "margin-top:" +
                        _vm.facade.item_margin / 2 +
                        "px;border-top-right-radius: " +
                        _vm.facade.img_radius / 2 +
                        "px;border-top-left-radius: " +
                        _vm.facade.img_radius / 2 +
                        "px;border-bottom-left-radius: " +
                        _vm.facade.status_radius / 2 +
                        "px;border-bottom-right-radius: " +
                        _vm.facade.status_radius / 2 +
                        "px;background: " +
                        _vm.facade.status_background +
                        " url(" +
                        _vm.facade.status_bg_image +
                        ") repeat left center;background-size: 100%;",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "img" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src:
                                index == 0
                                  ? "https://artimg2.artart.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                  : "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                              fit:
                                _vm.facade.img_style === 2
                                  ? "contain"
                                  : "cover",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "img-r" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style:
                              "color: " +
                              _vm.facade.title_color +
                              ";font-size: " +
                              _vm.facade.title_font_size / 2 +
                              "px;font-weight: " +
                              _vm.facade.title_font_weight +
                              ";",
                          },
                          [_vm._v("坠落·光在前方照亮的地方")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "count",
                            style:
                              "color: " +
                              _vm.facade.stock_color +
                              ";font-size: " +
                              _vm.facade.stock_font_size / 2 +
                              "px;",
                          },
                          [_vm._v("限量500份")]
                        ),
                      ]),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]
        : _vm.content.type === "notice"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满14周岁的中国大陆用户购买。数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用1。"
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "playimage"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-flex-center",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("el-image", {
                      staticClass: "img",
                      style: {
                        borderWidth:
                          (_vm.facade.borderWidth
                            ? _vm.facade.borderWidth
                            : 0) /
                            2 +
                          "px",
                      },
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                        fit: "contain",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm.content.type === "playinfo"
        ? [
            _c(
              "div",
              {
                staticClass: "img-desc common-bor",
                style:
                  "margin: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px 0 " +
                  _vm.pageInfo.marginLeft / 2 +
                  "px;padding: " +
                  _vm.pageInfo.item_padding / 2 +
                  "px;background: " +
                  _vm.pageInfo.item_background +
                  ";border-color: " +
                  _vm.pageInfo.border +
                  ";border-radius: " +
                  _vm.pageInfo.borderRadius / 2 +
                  "px;",
              },
              [
                _vm.facade.title_image
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "100%" },
                          attrs: { src: _vm.facade.title_image, alt: "" },
                        }),
                      ]
                    )
                  : _vm.facade.title
                  ? _c(
                      "div",
                      {
                        staticClass: "common-title",
                        staticStyle: { padding: "0" },
                        style:
                          "color: " +
                          _vm.pageInfo.titleColor +
                          ";font-size: " +
                          _vm.pageInfo.titleFontSize / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(_vm.facade.title))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "work-list",
                    class: !_vm.facade.title ? "m0" : "",
                    style:
                      "line-height: " +
                      _vm.facade.lineHeight / 2 +
                      "px;font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;color: " +
                      _vm.facade.color +
                      ";",
                  },
                  [_c("div", [_vm._v("玩法说明：玩法说明")])]
                ),
              ]
            ),
          ]
        : _vm.content.type == "playtag"
        ? [
            _c(
              "div",
              {
                staticClass: "tagbox",
                style:
                  "margin-top:" +
                  _vm.facade.marginTop / 2 +
                  "px;margin-left:" +
                  _vm.pageInfo.marginLeft / 2 +
                  "px",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tag",
                    style:
                      "color: " +
                      _vm.facade.color +
                      ";font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;margin-right:" +
                      _vm.facade.marginRight / 2 +
                      "px;background-color: " +
                      _vm.facade.bgcolor +
                      ";border-radius: " +
                      _vm.facade.borderRadius / 2 +
                      "px;border-color: " +
                      _vm.facade.borderColor +
                      ";",
                  },
                  [_vm._v("未选中玩法")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tag",
                    style:
                      "color: " +
                      _vm.facade.selectColor +
                      ";font-weight: " +
                      _vm.facade.fontWeight +
                      ";font-size: " +
                      _vm.facade.fontSize / 2 +
                      "px;background-color: " +
                      _vm.facade.selectBgcolor +
                      ";border-radius: " +
                      _vm.facade.borderRadius / 2 +
                      "px;border-color: " +
                      _vm.facade.selectBgcolor +
                      ";",
                  },
                  [_vm._v("选中玩法")]
                ),
              ]
            ),
          ]
        : _vm.content.type == "composemeta"
        ? [
            _c(
              "div",
              {
                staticClass: "compose-box",
                style: "margin-top:" + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "compose-title",
                    style: "color:" + _vm.facade.metaColor,
                  },
                  [_vm._v(":::: 合成材料 ::::")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "compose-list",
                    style:
                      "margin-left:" +
                      _vm.pageInfo.marginLeft / 2 +
                      "px; margin-right:" +
                      _vm.pageInfo.marginLeft / 2 +
                      "px;border-radius: " +
                      _vm.facade.borderRadius / 2 +
                      "px;background: " +
                      _vm.pageInfo.item_background +
                      ";",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "compose-right" }, [
                      _c(
                        "div",
                        {
                          staticClass: "compose-title",
                          style: "color:" + _vm.facade.metaColor,
                        },
                        [_vm._v("商品标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-have",
                          style: "color:" + _vm.facade.haveColor,
                        },
                        [_vm._v("当前拥有0")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-need",
                          style: "color:" + _vm.facade.needColor,
                        },
                        [_vm._v("需要*1")]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "compose-addimage" }, [
                  _c("img", { attrs: { src: _vm.facade.addImage, alt: "" } }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "compose-list",
                    style:
                      "margin-left:" +
                      _vm.pageInfo.marginLeft / 2 +
                      "px; margin-right:" +
                      _vm.pageInfo.marginLeft / 2 +
                      "px;border-radius: " +
                      _vm.facade.borderRadius / 2 +
                      "px;background: " +
                      _vm.pageInfo.item_background +
                      ";",
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "compose-right" }, [
                      _c(
                        "div",
                        {
                          staticClass: "compose-title",
                          style: "color:" + _vm.facade.metaColor,
                        },
                        [_vm._v("商品标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-have",
                          style: "color:" + _vm.facade.haveColor,
                        },
                        [_vm._v("当前拥有0")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "compose-need",
                          style: "color:" + _vm.facade.needColor,
                        },
                        [_vm._v("需要*1")]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "compose-resultimage" }, [
                  _c("img", {
                    attrs: { src: _vm.facade.resultImage, alt: "" },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "compose-result",
                    style:
                      "margin-left:" +
                      _vm.pageInfo.marginLeft / 2 +
                      "px; margin-right:" +
                      _vm.pageInfo.marginLeft / 2 +
                      "px;border-radius: " +
                      _vm.facade.resultRadius / 2 +
                      "px;background: " +
                      _vm.pageInfo.item_background +
                      ";",
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "compose-result-bottom",
                        style:
                          "background: " + _vm.pageInfo.item_background + ";",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "compose-result-title",
                            style: "color:" + _vm.facade.metaColor,
                          },
                          [_vm._v("合成nft名称")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "compose-result-have",
                            style: "color:" + _vm.facade.needColor,
                          },
                          [_vm._v("已合成0")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "compose-left" }, [
      _c("img", {
        attrs: {
          src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "compose-left" }, [
      _c("img", {
        attrs: {
          src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "compose-result-image" }, [
      _c("img", {
        attrs: {
          src: "https://artimg2.artart.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }