"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        success: 'success',
        pending: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      statisticsData: {
        article_count: 1024,
        pageviews_count: 1024
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'avatar', 'roles']))
};
exports.default = _default;