var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navigation",
      style: {
        "background-color": _vm.facade.background,
        backgroundImage: _vm.facade.bg_image
          ? "url(" + _vm.facade.bg_image + ")"
          : "",
        backgroundSize: "100% 100%",
        margin: "0 " + _vm.facade.page_margin / 2 + "px",
        paddingBottom: _vm.content.style == 1 ? "16px" : "0",
        borderRadius: _vm.facade.page_radius / 2 + "px",
        boxShadow: _vm.facade.page_shadow
          ? "0 0 " +
            _vm.facade.page_shadow_size / 2 +
            "px " +
            _vm.facade.page_shadow
          : "",
      },
    },
    [
      _vm.content.title
        ? _c(
            "div",
            { staticClass: "nav-title flex align-items-c justify-between" },
            [
              _c(
                "div",
                {
                  style: {
                    fontSize: _vm.facade.title_size / 2 + "px",
                    fontWeight: _vm.facade.title_style,
                    color: _vm.facade.title_color,
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.content.title) + "\n    ")]
              ),
              _vm._v(" "),
              _vm.content.right_desc
                ? _c(
                    "div",
                    {
                      staticClass: "flex align-items-c",
                      style: {
                        fontSize: _vm.facade.title_right_size / 2 + "px",
                        fontWeight: _vm.facade.title_right_style,
                        color: _vm.facade.title_right_color,
                      },
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.content.right_desc))]),
                      _vm._v(" "),
                      _c("img", {
                        style: {
                          width: _vm.facade.title_right_size / 2 + "px",
                          height: _vm.facade.title_right_size / 2 + "px",
                        },
                        attrs: {
                          src: "https://artimg2.artart.cn/image/20211214/d13a23794f53952192d6c7defd2a18cc.png",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _vm.content.style == 2
            ? _vm._l(_vm.navigationData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "navline_" + index,
                    staticClass: "nav-line item-align-center",
                    class: { "no-border": index === 0 },
                    style: _vm.facade.border_color
                      ? "border-color: " + _vm.facade.border_color
                      : "",
                  },
                  [
                    item.url
                      ? _c("img", {
                          style: {
                            width:
                              parseInt(_vm.facade.text_size / 2 + 2, 10) + "px",
                            height:
                              parseInt(_vm.facade.text_size / 2 + 2, 10) + "px",
                            marginRight: "5px",
                          },
                          attrs: { src: item.url, alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        style: {
                          color: _vm.facade.text_color,
                          "font-weight": _vm.facade.text_style,
                          "font-size":
                            parseInt(_vm.facade.text_size / 2, 10) + "px",
                        },
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex-one align-r",
                        style: {
                          color: _vm.facade.vice_text_color,
                          fontSize: _vm.facade.vice_text_size / 2 + "px",
                          fontWeight: _vm.facade.vice_text_style,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.right_title
                              ? item.right_title.replace(/\%s/g, "1,000")
                              : ""
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      style: {
                        width: _vm.facade.vice_text_size / 2 + "px",
                        height: _vm.facade.vice_text_size / 2 + "px",
                      },
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211214/d13a23794f53952192d6c7defd2a18cc.png",
                      },
                    }),
                  ]
                )
              })
            : _vm._l(
                _vm.facade.line_count == 0
                  ? 1
                  : parseInt(
                      _vm.navigationData.length / _vm.facade.line_count,
                      10
                    ) +
                      (_vm.navigationData.length % _vm.facade.line_count === 0
                        ? 0
                        : 1),
                function (m, i) {
                  return _c(
                    "div",
                    { key: "line" + i, staticClass: "item-align-center" },
                    _vm._l(
                      _vm.facade.line_count == 0
                        ? _vm.navigationData.length
                        : _vm.facade.line_count,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "line" + i + index,
                            staticClass: "item",
                            class: { "flex-one": _vm.facade.line_count != 0 },
                            staticStyle: { "flex-shrink": "0" },
                            style: {
                              marginLeft:
                                _vm.facade.line_count != 0
                                  ? "0"
                                  : _vm.facade.item_padding / 2 + "px",
                            },
                          },
                          [
                            _vm.navigationData[
                              i * _vm.facade.line_count + index
                            ]
                              ? [
                                  _vm.content.style == 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "img flex",
                                          class: {
                                            "justify-left":
                                              _vm.facade.alignment == "left",
                                            "justify-center":
                                              _vm.facade.alignment == "center",
                                            "justify-end":
                                              _vm.facade.alignment == "right",
                                          },
                                          style: { paddingTop: "16px" },
                                        },
                                        [
                                          _vm.navigationData[
                                            i * _vm.facade.line_count + index
                                          ].url
                                            ? _c("img", {
                                                style: {
                                                  width: "24px",
                                                  height: "24px",
                                                },
                                                attrs: {
                                                  src: _vm.navigationData[
                                                    i * _vm.facade.line_count +
                                                      index
                                                  ].url,
                                                  alt: "",
                                                },
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "img-view item-flex-center",
                                                  style: {
                                                    width: "24px",
                                                    height: "24px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "num",
                                          style: {
                                            color: _vm.facade.vice_text_color,
                                            fontSize:
                                              _vm.facade.vice_text_size / 2 +
                                              "px",
                                            fontWeight:
                                              _vm.facade.vice_text_style,
                                            textAlign: _vm.facade.alignment,
                                          },
                                        },
                                        [_vm._v("142")]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "txt",
                                      style: {
                                        color: _vm.facade.text_color,
                                        "font-weight": _vm.facade.text_style,
                                        "font-size":
                                          parseInt(
                                            _vm.facade.text_size / 2,
                                            10
                                          ) + "px",
                                        lineHeight: "18px",
                                        marginTop:
                                          _vm.content.style == 1 ? "8px" : "0",
                                        textAlign: _vm.facade.alignment,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.navigationData[
                                            i * _vm.facade.line_count + index
                                          ].title
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }
                    ),
                    0
                  )
                }
              ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }