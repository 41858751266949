import { render, staticRenderFns } from "./widget.vue?vue&type=template&id=2caca433&scoped=true&"
import script from "./widget.vue?vue&type=script&lang=js&"
export * from "./widget.vue?vue&type=script&lang=js&"
import style0 from "./widget.vue?vue&type=style&index=0&id=2caca433&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2caca433",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_5Yxh/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2caca433')) {
      api.createRecord('2caca433', component.options)
    } else {
      api.reload('2caca433', component.options)
    }
    module.hot.accept("./widget.vue?vue&type=template&id=2caca433&scoped=true&", function () {
      api.rerender('2caca433', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/customPage/widget.vue"
export default component.exports