"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _user = require("@/api/user");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "cauctionrecord",
  props: {
    uid: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 6
      },
      countjson: {
        productCount: 0,
        dealProductCount: 0,
        dealOrderTotalPrice: 0,
        dealAveragePrice: 0,
        refundProductCount: 0
      },
      listLoading: false,
      formatPriceNumber: _index.formatPriceNumber
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.uid) {
      (0, _user.userUpAuctionAllstatics)(this.uid).then(function (res) {
        _this.countjson = res.data;
      });
      this.tableFrom.uid = this.uid;
      this.getList();
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      (0, _user.userUpAuctionList)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  },
  beforeDestroy: function beforeDestroy() {}
};
exports.default = _default;