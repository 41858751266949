var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "专场选择",
        "append-to-body": true,
        visible: _vm.showSearch,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showSearch = $event
        },
        close: _vm.isCloseAction,
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex align-items-c" },
        [
          _c("span", [_vm._v("专场名称：")]),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "w200",
            attrs: { placeholder: "输入关键字搜索" },
          }),
          _vm._v(" "),
          _c("el-button", { staticClass: "ml20", attrs: { type: "primary" } }, [
            _vm._v("\n      筛选\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20 ft12",
          attrs: {
            data: _vm.albumList,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "专场封面", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "70px" },
                      attrs: { src: scope.row.image + "!120a" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.time_status === 1
                      ? _c("div", [_vm._v("进行中")])
                      : scope.row.time_status === 2
                      ? _c("div", [_vm._v("预展中")])
                      : scope.row.time_status === 3
                      ? _c("div", [_vm._v("已结束")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.is_show === 0
                      ? _c("div", [_vm._v("已隐藏")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "专场名称", "min-width": "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.name))]),
                    _vm._v(" "),
                    _c("div", [_vm._v("ID:" + _vm._s(scope.row.id))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "拍卖时间", "min-width": "195" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "预展：" +
                          _vm._s(
                            _vm.$parseTime(
                              scope.row.scheduled_show_time * 1000,
                              "{y}-{m}-{d} {h}:{i}"
                            )
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "开拍：" +
                          _vm._s(
                            _vm.$parseTime(
                              scope.row.stime * 1000,
                              "{y}-{m}-{d} {h}:{i}"
                            )
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    scope.row.album_type === 1 &&
                    scope.row.end_type === 2 &&
                    scope.row.time_status !== 3
                      ? _c("div", [
                          _vm._v(
                            "结拍：" +
                              _vm._s(
                                _vm.$parseTime(
                                  scope.row.first_end_time * 1000,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              ) +
                              " 开始"
                          ),
                        ])
                      : scope.row.album_type === 1
                      ? _c("div", [
                          _vm._v(
                            "结拍：" +
                              _vm._s(
                                _vm.$parseTime(
                                  scope.row.etime * 1000,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                          ),
                        ])
                      : scope.row.etime
                      ? _c("div", [
                          _vm._v(
                            "结拍：" +
                              _vm._s(
                                _vm.$parseTime(
                                  scope.row.etime * 1000,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "140", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _vm.changeAlbumIds.indexOf(scope.row.id) === -1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeOkAlbum(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择专场")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeAlbumIds.indexOf(scope.row.id) === -1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeCancelAlbum(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("取消选择")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }