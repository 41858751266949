"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _choice = _interopRequireDefault(require("@/components/customPage/choice.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    choice: _choice.default,
    choose: _choose.default
  },
  props: {
    value: ''
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    pageInfo: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    setColor: function setColor(key) {
      if (this.pageInfo.theme !== key) {
        this.pageInfo.theme = key;
        switch (key) {
          case 1:
            this.pageInfo.item_background = '#FFFFFF';
            this.pageInfo.title_color = '#333333';
            this.pageInfo.desc_color = '#999999';
            break;
          case 2:
            this.pageInfo.item_background = '#F5F5F5';
            this.pageInfo.title_color = '#000000';
            this.pageInfo.desc_color = '#999999';
            break;
          case 3:
            this.pageInfo.item_background = '#FB6270';
            this.pageInfo.title_color = '#FFFFFF';
            this.pageInfo.desc_color = '#FFB4BB';
            break;
          case 4:
            this.pageInfo.item_background = '#518862';
            this.pageInfo.title_color = '#FFFFFF';
            this.pageInfo.desc_color = '#A7C3B0';
            break;
          case 5:
            this.pageInfo.item_background = '#839EC8';
            this.pageInfo.title_color = '#FFFFFF';
            this.pageInfo.desc_color = '#C9D7EF';
            break;
          case 6:
            this.pageInfo.item_background = '#CCA373';
            this.pageInfo.title_color = '#FFFFFF';
            this.pageInfo.desc_color = '#F9E4CA';
            break;
          case 7:
            this.pageInfo.item_background = '#333333';
            this.pageInfo.title_color = '#FFFFFF';
            this.pageInfo.desc_color = '#999999';
            break;
        }
      }
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.pageInfo[key1][key] = '';
      } else {
        this.pageInfo[key] = '';
      }
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this.pageInfo[key1][key] = img[0];
        } else {
          _this.pageInfo[key] = img[0];
        }
      }, tit);
    }
  }
};
exports.default = _default;