var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hyperlink-select" },
    [
      _c(
        "popup",
        {
          attrs: { forbidden: _vm.forbidden, original: _vm.selectData },
          on: { confirm: _vm.handleConfirm },
        },
        [
          _vm.selectData.name
            ? _c(
                "div",
                { staticClass: "classify-select-tag", on: { click: _vm.stop } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text", disabled: _vm.isHome } },
                    [_vm._v("修改")]
                  ),
                ],
                1
              )
            : _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }