"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_5Yxh/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cityList = cityList;
exports.templateCreateApi = templateCreateApi;
exports.templateDeleteApi = templateDeleteApi;
exports.templateDetailApi = templateDetailApi;
exports.templateListApi = templateListApi;
exports.templateUpdateApi = templateUpdateApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 运费模板 -- 城市
 */
function cityList(data) {
  return _request.default.get('system/city/lst', data);
}
/**
 * @description 运费模板 -- 列表
 */
function templateListApi(data) {
  return _request.default.get('store/shipping/lst', data);
}
/**
 * @description 运费模板 -- 新增
 */
function templateCreateApi(data) {
  return _request.default.post('store/shipping/create', data);
}
/**
 * @description 运费模板 -- 编辑
 */
function templateUpdateApi(id, data) {
  return _request.default.post("store/shipping/update/".concat(id), data);
}
/**
 * @description 运费模板 -- 详情
 */
function templateDetailApi(id) {
  return _request.default.get("/store/shipping/detail/".concat(id));
}
/**
 * @description 运费模板 -- 删除
 */
function templateDeleteApi(id) {
  return _request.default.delete("store/shipping/delete/".concat(id));
}