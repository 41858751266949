import { render, staticRenderFns } from "./userdetail.vue?vue&type=template&id=5d95b243&scoped=true&"
import script from "./userdetail.vue?vue&type=script&lang=js&"
export * from "./userdetail.vue?vue&type=script&lang=js&"
import style0 from "./userdetail.vue?vue&type=style&index=0&id=5d95b243&scoped=true&lang=scss&"
import style1 from "./userdetail.vue?vue&type=style&index=1&id=5d95b243&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d95b243",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_5Yxh/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d95b243')) {
      api.createRecord('5d95b243', component.options)
    } else {
      api.reload('5d95b243', component.options)
    }
    module.hot.accept("./userdetail.vue?vue&type=template&id=5d95b243&scoped=true&", function () {
      api.rerender('5d95b243', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/list/userdetail.vue"
export default component.exports