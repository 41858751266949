var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "info flex align-items-c",
        style: {
          "background-color": _vm.facade.background,
          backgroundImage: _vm.facade.bg_image
            ? "url(" + _vm.facade.bg_image + ")"
            : "",
          backgroundSize: "100% 100%",
          margin: "0 " + _vm.facade.page_margin / 2 + "px",
          borderRadius: _vm.facade.page_radius / 2 + "px",
          boxShadow: _vm.facade.page_shadow
            ? "0 0 " +
              _vm.facade.page_shadow_size / 2 +
              "px " +
              _vm.facade.page_shadow
            : "",
          height: _vm.facade.item_height / 2 + "px",
          paddingLeft: _vm.facade.item_padding / 2 + "px",
          fontWeight: _vm.facade.title_style,
          fontSize: _vm.facade.title_size / 2 + "px",
          color: _vm.facade.title_color,
        },
      },
      [
        _vm.facade.left_image
          ? _c(
              "div",
              {
                staticClass: "l-img icon",
                style: "padding-right: " + _vm.facade.item_padding / 2 + "px",
              },
              [
                _c("img", {
                  attrs: { src: _vm.facade.left_image + "!120a", alt: "" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "txt line1 flex-one",
            style: "padding-right: " + _vm.facade.item_padding / 2 + "px",
          },
          [_vm._v("区块链地址：AI45JKL325LJ32K5J23334J235332J")]
        ),
        _vm._v(" "),
        _vm.facade.copy_image
          ? _c(
              "div",
              {
                staticClass: "l-img icon",
                style: "padding-right: " + _vm.facade.item_padding / 2 + "px",
              },
              [
                _c("img", {
                  attrs: { src: _vm.facade.copy_image + "!120a", alt: "" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.facade.ewm_image
          ? _c(
              "div",
              {
                staticClass: "l-img icon",
                style: "padding-right: " + _vm.facade.item_padding / 2 + "px",
              },
              [
                _c("img", {
                  attrs: { src: _vm.facade.ewm_image + "!120a", alt: "" },
                }),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }