var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.facade.type === 1
      ? _c(
          "div",
          {
            staticClass: "wechat",
            style: {
              margin: "0 " + _vm.facade.page_padding / 2 + "px",
              borderRadius: _vm.facade.page_radius / 2 + "px",
              background: _vm.facade.background_color,
              border: "1px solid " + _vm.facade.border_color,
              paddingBottom: _vm.facade.title_margin_top / 2 + "px",
            },
          },
          [
            _vm.content.show_title
              ? _c(
                  "div",
                  {
                    staticClass: "title item-align-center",
                    style: {
                      color: _vm.facade.title_color,
                      marginLeft: _vm.facade.title_margin_left / 2 + "px",
                      marginTop: _vm.facade.title_margin_top / 2 + "px",
                      fontSize: _vm.facade.title_size / 2 + "px",
                      fontWeight: _vm.facade.title_style,
                    },
                  },
                  [
                    _vm.content.title_icon
                      ? _c("img", {
                          style: {
                            marginRight: "4px",
                            width: (_vm.facade.title_size + 12) / 2 + "px",
                            height: (_vm.facade.title_size + 12) / 2 + "px",
                          },
                          attrs: { src: _vm.content.title_icon, alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.content.title_txt))]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "content display-flex",
                staticStyle: { "align-items": "flex-start" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "logo",
                    style: {
                      marginLeft: _vm.facade.img_margin_left / 2 + "px",
                      marginTop: _vm.facade.img_margin_top / 2 + "px",
                    },
                  },
                  [
                    _vm.content.logo
                      ? _c("img", {
                          style: {
                            width: _vm.facade.img_width / 2 + "px",
                            height: _vm.facade.img_height / 2 + "px",
                            "border-radius": _vm.facade.img_radius / 2 + "px",
                          },
                          attrs: { src: _vm.content.logo, alt: "" },
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "no-img item-flex-center",
                            style: {
                              width: _vm.facade.img_width / 2 + "px",
                              height: _vm.facade.img_height / 2 + "px",
                              "border-radius": _vm.facade.img_radius / 2 + "px",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    style: {
                      marginLeft: _vm.facade.name_margin_left / 2 + "px",
                      marginTop: _vm.facade.name_margin_top / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          fontSize: _vm.facade.name_size / 2 + "px",
                          color: _vm.facade.name_color,
                          fontWeight: _vm.facade.name_style,
                        },
                      },
                      [_vm._v(_vm._s(_vm.content.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        style: {
                          fontSize: _vm.facade.desc_size / 2 + "px",
                          color: _vm.facade.desc_color,
                          fontWeight: _vm.facade.desc_style,
                          marginTop: _vm.facade.desc_margin_top / 2 + "px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.content.desc))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.content.button_txt
                  ? _c(
                      "div",
                      {
                        staticClass: "btn",
                        style: {
                          marginLeft: _vm.facade.button_margin_left / 2 + "px",
                          marginTop: _vm.facade.button_margin_top / 2 + "px",
                          background: _vm.facade.button_background_color,
                          color: _vm.facade.button_color,
                          fontSize: _vm.facade.button_size / 2 + "px",
                          fontWeight: _vm.facade.button_desc_style,
                          borderRadius: _vm.facade.button_radius / 2 + "px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.content.button_txt))]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm.facade.type === 2
      ? _c("div", { staticClass: "wechat1" }, [
          _c(
            "div",
            {
              staticClass: "wechat-view item-align-center",
              class: {
                "is-left": _vm.facade.position === 1,
                "is-right": _vm.facade.position === 2,
              },
              style: {
                background: _vm.facade.bottom_background,
                border: "1px solid " + _vm.facade.bottom_border,
                color: _vm.facade.bottom_color,
                fontSize: _vm.facade.bottom_size / 2 + "px",
                fontWeight: _vm.facade.bottom_style,
                borderRadius: _vm.facade.bottom_radius / 2 + "px",
              },
            },
            [
              _vm.content.title_icon2
                ? _c("img", {
                    style: {
                      width: (_vm.facade.bottom_size + 8) / 2 + "px",
                      height: (_vm.facade.bottom_size + 8) / 2 + "px",
                      marginRight: "2px",
                    },
                    attrs: { src: _vm.content.title_icon2 },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.content.title_txt))]),
              _vm._v(" "),
              _vm.content.title_icon_right
                ? _c("img", {
                    style: {
                      width: (_vm.facade.bottom_size + 8) / 2 + "px",
                      height: (_vm.facade.bottom_size + 8) / 2 + "px",
                      marginLeft: "2px",
                    },
                    attrs: { src: _vm.content.title_icon_right },
                  })
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }