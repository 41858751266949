var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基础设置", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "90px",
            size: "small",
            "label-position": "left",
          },
        },
        [
          _vm.pageInfo.headerSetting
            ? _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题栏颜色" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.pageInfo.headerSetting.header_color,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.pageInfo.headerSetting,
                            "header_color",
                            $$v
                          )
                        },
                        expression: "pageInfo.headerSetting.header_color",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "black" } }, [
                        _vm._v("黑色"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "white" } }, [
                        _vm._v("白色"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "mt20",
              staticStyle: { color: "#000" },
              attrs: { label: "背景颜色" },
            },
            [
              _c("colour", {
                attrs: { colour: "#f7f7f7" },
                model: {
                  value: _vm.pageInfo.background,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo, "background", $$v)
                  },
                  expression: "pageInfo.background",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
              _vm._v("背景图片"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-img-view item-flex-center",
                on: {
                  click: function ($event) {
                    return _vm.modalPicTap(1, "bg_image")
                  },
                },
              },
              [
                _vm.pageInfo.bg_image
                  ? _c(
                      "div",
                      {
                        staticClass: "del-upload-img",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.delImg("bg_image")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pageInfo.bg_image
                  ? _c("img", {
                      attrs: { src: _vm.pageInfo.bg_image + "!300" },
                    })
                  : _c("img", {
                      staticClass: "upload-icon",
                      attrs: {
                        src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                        alt: "",
                      },
                    }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "背景图设置" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.pageInfo.bg_image_mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.pageInfo, "bg_image_mode", $$v)
                    },
                    expression: "pageInfo.bg_image_mode",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("宽度固定上下居中"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("满屏上下左右截取"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("宽度固定上下平铺循环展示"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("宽度固定高度自适应顶部展示"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("用户区域："),
            ]),
          ]),
          _vm._v(" "),
          _vm.pageInfo.userInfoSetting
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "左右距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.pageInfo.userInfoSetting.left_padding,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageInfo.userInfoSetting,
                              "left_padding",
                              $$v
                            )
                          },
                          expression: "pageInfo.userInfoSetting.left_padding",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距上距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.pageInfo.userInfoSetting.top_padding,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageInfo.userInfoSetting,
                              "top_padding",
                              $$v
                            )
                          },
                          expression: "pageInfo.userInfoSetting.top_padding",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("头像尺寸")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "pl10" }, [
                      _c(
                        "div",
                        { staticClass: "item-align-center" },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              model: {
                                value:
                                  _vm.pageInfo.userInfoSetting.avatar_width,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.userInfoSetting,
                                    "avatar_width",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.userInfoSetting.avatar_width",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { margin: "0 6px" },
                            attrs: {
                              src: "https://artimg2.artart.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "高" },
                              model: {
                                value:
                                  _vm.pageInfo.userInfoSetting.avatar_height,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.userInfoSetting,
                                    "avatar_height",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.userInfoSetting.avatar_height",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "用户名距左" } },
                    [
                      _c("slider", {
                        attrs: { min: 12, max: 50 },
                        model: {
                          value: _vm.pageInfo.userInfoSetting.uname_margin_left,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageInfo.userInfoSetting,
                              "uname_margin_left",
                              $$v
                            )
                          },
                          expression:
                            "pageInfo.userInfoSetting.uname_margin_left",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "用户名字号" } },
                    [
                      _c("slider", {
                        attrs: { min: 12, max: 50 },
                        model: {
                          value: _vm.pageInfo.userInfoSetting.uname_size,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageInfo.userInfoSetting,
                              "uname_size",
                              $$v
                            )
                          },
                          expression: "pageInfo.userInfoSetting.uname_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "用户名颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#333333" },
                        model: {
                          value: _vm.pageInfo.userInfoSetting.uname_color,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageInfo.userInfoSetting,
                              "uname_color",
                              $$v
                            )
                          },
                          expression: "pageInfo.userInfoSetting.uname_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "用户名风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.pageInfo.userInfoSetting.uname_style,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.userInfoSetting,
                                "uname_style",
                                $$v
                              )
                            },
                            expression: "pageInfo.userInfoSetting.uname_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("修改图标")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml10 upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(
                              1,
                              "settingImg",
                              "userInfoSetting"
                            )
                          },
                        },
                      },
                      [
                        _vm.pageInfo.userInfoSetting.settingImg
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(
                                      "settingImg",
                                      "userInfoSetting"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pageInfo.userInfoSetting.settingImg
                          ? _c("img", {
                              attrs: {
                                src: _vm.pageInfo.userInfoSetting.settingImg,
                              },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("未认证图标")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml10 upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(
                              1,
                              "notCertified",
                              "userInfoSetting"
                            )
                          },
                        },
                      },
                      [
                        _vm.pageInfo.userInfoSetting.notCertified
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(
                                      "notCertified",
                                      "userInfoSetting"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pageInfo.userInfoSetting.notCertified
                          ? _c("img", {
                              attrs: {
                                src: _vm.pageInfo.userInfoSetting.notCertified,
                              },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("已认证图标")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml10 upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(
                              1,
                              "certified",
                              "userInfoSetting"
                            )
                          },
                        },
                      },
                      [
                        _vm.pageInfo.userInfoSetting.certified
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(
                                      "certified",
                                      "userInfoSetting"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://artimg2.artart.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pageInfo.userInfoSetting.certified
                          ? _c("img", {
                              attrs: {
                                src: _vm.pageInfo.userInfoSetting.certified,
                              },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://artimg2.artart.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "展示等级" } }, [
                    _c(
                      "div",
                      { staticClass: "item-align-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            staticStyle: {
                              "font-size": "12px",
                              color: "#999",
                              margin: "0 6px",
                            },
                          },
                          [_vm._v("打开时展示当前用户等级、成长值")]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#009406",
                            "inactive-color": "#cccccc",
                          },
                          model: {
                            value: _vm.pageInfo.userInfoSetting.open_level,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.userInfoSetting,
                                "open_level",
                                $$v
                              )
                            },
                            expression: "pageInfo.userInfoSetting.open_level",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.pageInfo.userInfoSetting.open_level
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "进度条距上" },
                            },
                            [
                              _c("slider", {
                                attrs: { min: 0, max: 50 },
                                model: {
                                  value:
                                    _vm.pageInfo.userInfoSetting
                                      .progress_margin_top,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.userInfoSetting,
                                      "progress_margin_top",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.userInfoSetting.progress_margin_top",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "进度条颜色" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#333333" },
                                model: {
                                  value:
                                    _vm.pageInfo.userInfoSetting.progress_color,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.userInfoSetting,
                                      "progress_color",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.userInfoSetting.progress_color",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "成长值距上" },
                            },
                            [
                              _c("slider", {
                                attrs: { min: 0, max: 50 },
                                model: {
                                  value:
                                    _vm.pageInfo.userInfoSetting
                                      .progress_txt_margin_top,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.userInfoSetting,
                                      "progress_txt_margin_top",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.userInfoSetting.progress_txt_margin_top",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "成长值颜色" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#333333" },
                                model: {
                                  value:
                                    _vm.pageInfo.userInfoSetting
                                      .progress_txt_color,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.userInfoSetting,
                                      "progress_txt_color",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.userInfoSetting.progress_txt_color",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "成长值字号" },
                            },
                            [
                              _c("slider", {
                                attrs: { min: 12, max: 50 },
                                model: {
                                  value:
                                    _vm.pageInfo.userInfoSetting
                                      .progress_txt_size,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.userInfoSetting,
                                      "progress_txt_size",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.userInfoSetting.progress_txt_size",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "成长值风格" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "le-radio-group",
                                  model: {
                                    value:
                                      _vm.pageInfo.userInfoSetting
                                        .progress_txt_style,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageInfo.userInfoSetting,
                                        "progress_txt_style",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "pageInfo.userInfoSetting.progress_txt_style",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 400 } }, [
                                    _vm._v("常规"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 500 } }, [
                                    _vm._v("加粗"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 300 } }, [
                                    _vm._v("加细"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "关闭实名认证" } },
                    [
                      _c(
                        "div",
                        { staticClass: "item-align-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-one",
                              staticStyle: {
                                "font-size": "12px",
                                color: "#999",
                                margin: "0 6px",
                              },
                            },
                            [_vm._v("关闭后修改资料页面隐藏实名认证入口")]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value:
                                _vm.pageInfo.userInfoSetting
                                  .close_real_name_auth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageInfo.userInfoSetting,
                                  "close_real_name_auth",
                                  $$v
                                )
                              },
                              expression:
                                "pageInfo.userInfoSetting.close_real_name_auth",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "关闭充值" } },
                    [
                      _c(
                        "div",
                        { staticClass: "item-align-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-one",
                              staticStyle: {
                                "font-size": "12px",
                                color: "#999",
                                margin: "0 6px",
                              },
                            },
                            [_vm._v("关闭后钱包页面隐藏充值入口")]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value:
                                _vm.pageInfo.userInfoSetting.close_recharge,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageInfo.userInfoSetting,
                                  "close_recharge",
                                  $$v
                                )
                              },
                              expression:
                                "pageInfo.userInfoSetting.close_recharge",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }