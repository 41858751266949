var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.style == 1
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column1",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.page_margin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item",
                    class: { m0: i == 0 },
                    style: {
                      "background-color": _vm.facade.item_background,
                      marginTop: _vm.facade.top_margin / 2 + "px",
                      borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                      borderTopRightRadius: _vm.facade.top_radius / 2 + "px",
                      borderBottomLeftRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      borderBottomRightRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      boxShadow: _vm.facade.item_shadow
                        ? "0 0 " +
                          _vm.facade.item_shadow_size / 2 +
                          "px " +
                          _vm.facade.item_shadow
                        : "",
                    },
                  },
                  [
                    _c("div", { staticClass: "img" }, [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: m.image },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc flex-one",
                        style: {
                          padding: "10px " + _vm.facade.text_margin / 2 + "px",
                          color: _vm.facade.sub_title_color,
                        },
                      },
                      [
                        _c("div", { staticClass: "bottom mt5" }, [
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              style: {
                                fontSize: _vm.facade.title_size / 2 + "px",
                                color: _vm.facade.title_color,
                                fontWeight: _vm.facade.title_weight,
                              },
                            },
                            [_vm._v(_vm._s(m.name))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex align-items-c justify-b mt5" },
                          [
                            _c("div", { staticClass: "price" }, [
                              _c(
                                "span",
                                {
                                  style: {
                                    fontSize: _vm.facade.price_size / 2 + "px",
                                    color: _vm.facade.price_color,
                                    fontWeight: _vm.facade.price_weight,
                                  },
                                },
                                [_vm._v(_vm._s(m.price))]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.facade.show_sale
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "sale",
                                    style: {
                                      fontSize:
                                        _vm.facade.sales_volumes_size / 2 +
                                        "px",
                                      color: _vm.facade.sales_volumes_color,
                                      fontWeight:
                                        _vm.facade.sales_volumes_weight,
                                    },
                                  },
                                  [_vm._v("销量 54")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 2
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column auction-column2 flex",
                style: {
                  paddingLeft: _vm.facade.page_margin / 2 + "px",
                  paddingRight:
                    (_vm.facade.left_margin + _vm.facade.page_margin) / 2 +
                    "px",
                  paddingTop: _vm.isComponent ? "30px" : "",
                  paddingBottom: _vm.isComponent ? "30px" : "",
                },
              },
              _vm._l(2, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "auctionC" + i,
                    style: {
                      flex: 1,
                      paddingLeft: _vm.facade.left_margin / 2 + "px",
                    },
                  },
                  _vm._l(2, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "auctionD" + index,
                        staticClass: "item",
                        class: { m0: index == 0 },
                        style: {
                          backgroundColor: _vm.facade.item_background,
                          marginTop: _vm.facade.top_margin / 2 + "px",
                          borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                          borderTopRightRadius:
                            _vm.facade.top_radius / 2 + "px",
                          borderBottomLeftRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          borderBottomRightRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          boxShadow: _vm.facade.item_shadow
                            ? "0 0 " +
                              _vm.facade.item_shadow_size / 2 +
                              "px " +
                              _vm.facade.item_shadow
                            : "",
                        },
                      },
                      [
                        _vm.facade.img_style == 1
                          ? _c("div", { staticClass: "img" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "100%",
                                  display: "block",
                                },
                                style: _vm.listTwoImgStyle,
                                attrs: {
                                  src: _vm.defaultData[i * 2 + index].image,
                                },
                              }),
                            ])
                          : _c(
                              "div",
                              { staticClass: "img" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    display: "block",
                                  },
                                  style: _vm.listTwoImgStyle,
                                  attrs: {
                                    src: _vm.defaultData[i * 2 + index].image,
                                    fit:
                                      _vm.facade.img_style == 3
                                        ? "cover"
                                        : "contain",
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            style: {
                              padding:
                                "10px " + _vm.facade.text_margin / 2 + "px",
                              color: _vm.facade.sub_title_color,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name mt5 line2",
                                style: {
                                  fontSize: _vm.facade.title_size / 2 + "px",
                                  color: _vm.facade.title_color,
                                  fontWeight: _vm.facade.title_weight,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.defaultData[i * 2 + index].name)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bottom flex align-items-c justify-b mt5",
                              },
                              [
                                _c("div", { staticClass: "price" }, [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        fontSize:
                                          _vm.facade.price_size / 2 + "px",
                                        color: _vm.facade.price_color,
                                        fontWeight: _vm.facade.price_weight,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.defaultData[i * 2 + index].price
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.facade.show_sale
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "bid_num",
                                        style: {
                                          fontSize:
                                            _vm.facade.sales_volumes_size / 2 +
                                            "px",
                                          color: _vm.facade.sales_volumes_color,
                                          fontWeight:
                                            _vm.facade.sales_volumes_weight,
                                        },
                                      },
                                      [_vm._v("销量 54")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 3
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column auction-column2 flex flex-wrap",
                style: {
                  paddingLeft: _vm.facade.page_margin / 2 + "px",
                  paddingRight:
                    (_vm.facade.left_margin + _vm.facade.page_margin) / 2 +
                    "px",
                  paddingTop: _vm.isComponent ? "30px" : "",
                  paddingBottom: _vm.isComponent ? "30px" : "",
                },
              },
              _vm._l(_vm.defaultData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "auctionD" + index,
                    style: {
                      paddingLeft: _vm.facade.left_margin / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        class: { m0: index < 3 },
                        style: {
                          backgroundColor: _vm.facade.item_background,
                          marginTop: _vm.facade.top_margin / 2 + "px",
                          borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                          borderTopRightRadius:
                            _vm.facade.top_radius / 2 + "px",
                          borderBottomLeftRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          borderBottomRightRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          boxShadow: _vm.facade.item_shadow
                            ? "0 0 " +
                              _vm.facade.item_shadow_size / 2 +
                              "px " +
                              _vm.facade.item_shadow
                            : "",
                          width: _vm.listThreeWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "img" },
                          [
                            _c("el-image", {
                              staticStyle: { display: "block" },
                              style: {
                                width: _vm.listThreeWidth,
                                height: _vm.listThreeWidth,
                              },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.facade.img_style == 3
                                    ? "cover"
                                    : "contain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            style: {
                              padding:
                                "10px " + _vm.facade.text_margin / 2 + "px",
                              color: _vm.facade.sub_title_color,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name line2",
                                style: {
                                  fontSize: _vm.facade.title_size / 2 + "px",
                                  color: _vm.facade.title_color,
                                  fontWeight: _vm.facade.title_weight,
                                  marginTop: 0,
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bottom flex align-items-c justify-b",
                              },
                              [
                                _c("div", { staticClass: "price" }, [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        fontSize:
                                          _vm.facade.price_size / 2 + "px",
                                        color: _vm.facade.price_color,
                                        fontWeight: _vm.facade.price_weight,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.price))]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 4
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column-line",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.page_margin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item flex",
                    class: { m0: i == 0 },
                    style: {
                      "background-color": _vm.facade.item_background,
                      marginTop: _vm.facade.top_margin / 2 + "px",
                      borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                      borderTopRightRadius: _vm.facade.top_radius / 2 + "px",
                      borderBottomLeftRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      borderBottomRightRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      boxShadow: _vm.facade.item_shadow
                        ? "0 0 " +
                          _vm.facade.item_shadow_size / 2 +
                          "px " +
                          _vm.facade.item_shadow
                        : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.img_width / 2 + "px",
                          height: _vm.facade.img_height / 2 + "px",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: m.image,
                            fit:
                              _vm.facade.img_style === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc flex-one",
                        style: {
                          padding: _vm.facade.text_margin / 2 + "px 10px ",
                          color: _vm.facade.sub_title_color,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            style: {
                              fontSize: _vm.facade.title_size / 2 + "px",
                              color: _vm.facade.title_color,
                              fontWeight: _vm.facade.title_weight,
                            },
                          },
                          [_vm._v(_vm._s(m.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "bottom flex align-items-c justify-b",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "price" },
                              [
                                [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        fontSize:
                                          _vm.facade.price_size / 2 + "px",
                                        color: _vm.facade.price_color,
                                        fontWeight: _vm.facade.price_weight,
                                      },
                                    },
                                    [_vm._v(_vm._s(m.price))]
                                  ),
                                ],
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.facade.show_sale
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bid_num",
                                    style: {
                                      fontSize:
                                        _vm.facade.sales_volumes_size / 2 +
                                        "px",
                                      color: _vm.facade.sales_volumes_color,
                                      fontWeight:
                                        _vm.facade.sales_volumes_weight,
                                    },
                                  },
                                  [_vm._v("销量 54")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 5
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column flex",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.page_margin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item",
                    style: {
                      "background-color": _vm.facade.item_background,
                      marginLeft: _vm.facade.left_margin / 2 + "px",
                      borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                      borderTopRightRadius: _vm.facade.top_radius / 2 + "px",
                      borderBottomLeftRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      borderBottomRightRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      boxShadow: _vm.facade.item_shadow
                        ? "0 0 " +
                          _vm.facade.item_shadow_size / 2 +
                          "px " +
                          _vm.facade.item_shadow
                        : "",
                      width: _vm.facade.img_width / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.img_width / 2 + "px",
                          height: _vm.facade.img_height / 2 + "px",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: m.image,
                            fit:
                              _vm.facade.img_style === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        style: {
                          padding: "10px " + _vm.facade.text_margin / 2 + "px",
                          color: _vm.facade.sub_title_color,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            style: {
                              fontSize: _vm.facade.title_size / 2 + "px",
                              color: _vm.facade.title_color,
                              fontWeight: _vm.facade.title_weight,
                            },
                          },
                          [_vm._v(_vm._s(m.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "bottom flex align-items-c justify-b",
                          },
                          [
                            _c("div", { staticClass: "price" }, [
                              _c(
                                "span",
                                {
                                  style: {
                                    fontSize: _vm.facade.price_size / 2 + "px",
                                    color: _vm.facade.price_color,
                                    fontWeight: _vm.facade.price_weight,
                                  },
                                },
                                [_vm._v(_vm._s(m.price))]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.facade.show_sale
                              ? _c("div", { staticClass: "bid_num" }, [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        fontSize:
                                          _vm.facade.sales_volumes_size / 2 +
                                          "px",
                                        color: _vm.facade.sales_volumes_color,
                                        fontWeight:
                                          _vm.facade.sales_volumes_weight,
                                      },
                                    },
                                    [_vm._v("销量 54")]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }